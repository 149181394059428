import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="_privacyPolicyPage_f2rzd_9">
        <div
          className="_heroSection_10juh_9"
          style={{
            backgroundImage: 'url("/assets/privacyPolicy-cf3d6bfb.png")',
            backgroundPosition: "center center",
          }}
        >
          <div className="_heroSectionGradient_f2rzd_13">
            <div className="content _heroSectionContentContainer_f2rzd_26">
              <div className="_leftContainer_10juh_316 ">
                <div className="_title_10juh_65 _title_f2rzd_16">
                  Privacy Policy{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div custom-id="lightBgNav" className="content">
          <div className="_cardContainer_f2rzd_30">
            <div className="_content_f2rzd_48">
              <div className="_section_f2rzd_59">
                <div className="_description_f2rzd_87">
                  We, Omaza Innovations LLP, respect your data privacy rights
                  and are committed to protecting the personal information
                  collected on our website, https://www.omaza.com ("Website").
                  <br />
                  <br />
                  This Privacy Policy outlines how we collect, use, and protect
                  your personal information. For the sake of brevity, any
                  personal information and personally identifiable information
                  (such as name, company name, email ID, phone number, IP
                  address, etc.) provided by you or collected by us through your
                  use of the Website will be referred to as "Personal
                  Information".
                </div>
                <div className="_description_f2rzd_87"></div>
              </div>
              <div className="_section_f2rzd_59">
                <div className="_sectionTitle_f2rzd_80">
                  Please read this Privacy Policy carefully
                </div>
                <div className="_description_f2rzd_87">
                  For residents of the European Union (EU), this Privacy Policy
                  has been updated to comply with the EU General Data Protection
                  Regulation (GDPR). As per GDPR provisions, we are considered
                  the Controllers of the Personal Information collected via your
                  use of the Website.
                </div>
                <div className="_description_f2rzd_87">
                  For non-EU residents, by continuing to use or access the
                  Website, you agree to this Privacy Policy. If you do not agree
                  with this Privacy Policy, you should not use or access the
                  Website. The Privacy Policy differs depending on whether you
                  are using the Website or our services, and these distinctions
                  will be noted within this document.
                </div>
                <br></br>
                <div className="_section_f2rzd_59">
                  <div className="_sectionTitle_f2rzd_80">
                    Information collection and use
                  </div>
                  <div className="_description_f2rzd_87">
                    We collect different types of information for various
                    purposes to provide and improve our service to you{" "}
                  </div>
                  <br></br>
                  <div className="_section_f2rzd_59">
                    <div className="_sectionTitle_f2rzd_80">
                      Types of Data Collected
                    </div>

                    <div className="_description_f2rzd_87">
                      1. Email
                      <br></br>
                      2. First Name and last name
                      <br></br>
                      3. Name of organization
                      <br></br>
                      4. Phone number
                      <br></br>
                      5. Address, State, Province, ZIP/Postal Code, City
                      <br></br>
                      6. Cookies, and Usage Data{" "}
                    </div>
                    <div className="_description_f2rzd_87">
                      When you share your resume with us we receive the
                      information contained in such resume.{" "}
                    </div>
                    <br></br>
                    <div className="_section_f2rzd_59">
                      <div className="_sectionTitle_f2rzd_80">Usage Data</div>
                      <div className="_description_f2rzd_87">
                        We may also collect information on how the Website is
                        accessed and used ("Usage Data"). This Usage Data may
                        include information such as your computer’s Internet
                        Protocol address (IP address), browser type, browser
                        version, the pages of our Website that you visit, the
                        time and date of your visit, the time spent on those
                        pages, unique device identifiers, and other diagnostic
                        data. We use third-party tools (such as Google
                        Analytics) that use cookies to collect this information,
                        primarily to enhance the user experience.
                      </div>
                      <div className="_description_f2rzd_87">
                        This Website uses Google Analytics, a web analytics
                        service provided by Google Inc. ("Google"). Google
                        Analytics uses cookies and text files that are stored on
                        your computer to analyze your use of the Website. The
                        information generated by the cookies about your use of
                        this Website is usually transferred to a Google server
                        in the US and stored there. In case of activation of IP
                        anonymization on this Website, your IP address will be
                        truncated within Member States of the EU or other
                        parties to the Agreement on the European Economic Area.
                        Only in exceptional cases is the full IP address
                        transmitted to a Google server in the US and shortened
                        there.
                        <br />
                        <br />
                        You can refuse the use of cookies by selecting the
                        appropriate settings on your browser software. You can
                        also prevent the data generated by the cookie and
                        related to your use of the Website from being collected
                        and processed by Google by clicking the following link
                        (https://tools.google.com/dlpage/gaoptout), choosing the
                        available browser plug-in, and installing it
                        <br></br>
                        <br></br>
                      </div>
                      <br></br>
                    </div>
                    <div className="_section_f2rzd_59">
                      <div className="_sectionTitle_f2rzd_80">
                        Accuracy Of Information
                      </div>
                      <div className="_description_f2rzd_87">
                        Please ensure that any Personal Information you share
                        with us is accurate and up-to-date. If you are sharing
                        any Personal Information on behalf of a third person,
                        you should ensure that you are authorized to do so.
                      </div>
                    </div>
                    <br></br>
                    <div className="_section_f2rzd_59">
                      <div className="_sectionTitle_f2rzd_80">
                        Changes To This Privacy Policy
                      </div>
                      <div className="_description_f2rzd_87">
                        We may update our Privacy Policy from time to time. We
                        will notify you of any changes by posting the new
                        Privacy Policy on this page. You are advised to review
                        this Privacy Policy periodically for any changes.
                        Changes to this Privacy Policy are effective when they
                        are posted on this page. We will notify you via email
                        and/or a prominent notice on our Website regarding any
                        material changes in the Privacy Policy.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
