import React, { useEffect, useState } from "react";
import { homeConfig } from "../../assets/data/home";
import { Avatar, Collapse, Divider, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import angular from "../../assets/img/technologies/angular-0dc5748e.svg";
import android from "../../assets/img/technologies/android-98034f02.svg";
import apple from "../../assets/img/technologies/apple-4f8192ac.svg";
import aws from "../../assets/img/technologies/aws-0f8a2368.svg";
import azure from "../../assets/img/technologies/azure-2f3204cc.svg";
import dotNet from "../../assets/img/technologies/dotNet-dc65faf2.svg";
import flutter from "../../assets/img/technologies/flutter-ac2cb8c6.svg";
import gCloud from "../../assets/img/technologies/gCloud-e7a2e80c.svg";
import goLang from "../../assets/img/technologies/goLang-d33e3399.svg";
import v from "../../assets/img/technologies/v-7e61e0b6.svg";
import tenMore from "../../assets/img/technologies/tenMore-d36c570f.svg";
import react from "../../assets/img/technologies/react-ed2c6993.svg";
import nodeJs from "../../assets/img/technologies/nodeJs-6dd74999.svg";
import java from "../../assets/img/technologies/java-e71126db.svg";
import homePage from "../../assets/img/home/pexels-fauxels-3184296.jpg";

const { Panel } = Collapse;
const Home = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServiceData = async () => {
      setLoading(true);
      // Simulate API call or async data fetching
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate delay
      setData(homeConfig);
      setLoading(false);
    };

    fetchServiceData();
  }, [homeConfig]);

  const [activeIndex, setActiveIndex] = useState(0);
  const [activeTrend, setActiveTrend] = useState(0);

  const handleTabClick = (index) => {
    setActiveIndex(index);
  };
  const handleTabTrend = (index) => {
    setActiveTrend(index);
  };

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <Spin size="large" className="custom-spin" />
        </div>
      ) : (
        <>
          <div className="_homePage_tmpov_9" id="homePage">
            <div
              className="_heroSection_10juh_9"
              style={{
                height: "100vh",
                backgroundPosition: "center center",
                backgroundImage: `url(${homePage})`,
              }}
            >
              {/* <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the opacity as needed
                  zIndex: 1,
                }}
              /> */}

              {data && (
                <>
                  <div className="_heroSectionGradient_tmpov_9">
                    <div className="content _heroSectionContentContainer_tmpov_12">
                      <div className="_leftContainer_10juh_316 ">
                        <div className="_homeTitle_10juh_21">
                          <div>{data?.heroSection?.title}</div>
                        </div>
                        <p className="_description_10juh_109">
                          {data?.heroSection?.description}
                        </p>
                        <div className="_buttonsContainer_10juh_139">
                          <button
                            onClick={() => navigate("/contact-us")}
                            className="_buttonRoot_1e920_9 _primaryButton_1e920_32 _contactUsButton_10juh_200 _contactUsBtnClass_tmpov_32"
                          >
                            Book Free Consultation
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            {data && (
              <div className="_container_1tg7o_9 " id="headSection">
                <div className="content" custom-id="darkBgNav">
                  <div className="_titleContainer_1tg7o_20">
                    <div className="_titleContainerHeader_1tg7o_86">
                      {data?.services?.title}
                    </div>
                    <div className="_titleContainerDescription_1tg7o_110">
                      {data?.services?.description}
                    </div>
                  </div>
                  <div
                    className="_cardBox_1tg7o_124 "
                    style={{ paddingBottom: "2rem" }}
                  >
                    {data?.services?.serviceCards?.map((item, index) => (
                      <div className="_cardContainer_1tg7o_146 " key={index}>
                        <Avatar
                          icon={item?.icon}
                          alt="Card Header"
                          style={{
                            fontSize: "3rem",
                            width: "4rem",
                            height: "4rem",
                          }}
                        />
                        <div className="_cardContent_1tg7o_196 ">
                          <div className="_cardTitle_1tg7o_201">
                            {item?.header}
                          </div>
                          <div className="_cardDescription_1tg7o_213">
                            {item?.description}
                          </div>
                        </div>
                        <div className="_detailsContainer_1tg7o_178">
                          <a
                            className="_detailsLink_1tg7o_221 cursor-pointer text-white"
                            onClick={() => navigate(item?.link)}
                          >
                            {item?.linkText}
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className="_bottomSection_1tg7o_241 _homePageBottomSection_1tg7o_682 _ourServicesBtnContainer_tmpov_45"
                  custom-id="darkBgNav"
                />
              </div>
            )}
            {data && (
              <div className="" custom-id="lightBgNav">
                <div className="content _caseStudyContainer_1ny87_9 _caseStudyContainer_tmpov_50">
                  <div className="_titleContainer_1ny87_23">
                    <div className="_title_1ny87_23">
                      {data?.ourWork?.title}
                    </div>
                    <div className="_description_1ny87_66">
                      {data?.ourWork?.description}{" "}
                    </div>
                  </div>
                  <div className="_cardContainer_1ny87_74">
                    {data?.ourWork?.workCards?.map((item, index) => (
                      <div className="_cardContainer_a4cvn_9  " key={index}>
                        <div className="_imageContainer_a4cvn_29">
                          <img
                            src={item?.banner}
                            className="_image_a4cvn_29"
                            alt={`Card banner${item?.banner}`}
                          />
                        </div>
                        <div className="_contentWrapper_a4cvn_40">
                          <div className="_contentContainer_a4cvn_49">
                            <div className="_title_a4cvn_55">
                              {item?.header}
                            </div>
                            <div className="_subtext_a4cvn_65">
                              {item?.description}
                            </div>
                            <div className="_pillContainer_a4cvn_80">
                              {item?.tags?.map((tag) => (
                                <div className="_pills_a4cvn_92">{tag}</div>
                              ))}
                            </div>
                          </div>
                          <a
                            className="_readmore_a4cvn_109"
                            href={`portfolio?portfolio=${item?.link}`}
                          >
                            READ MORE
                            <span className="_hideData_a4cvn_119">...</span>
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            <div custom-id="darkBgNav">
              <div className="_mainContainer_yw79z_9">
                <div className="content _contentContainer_yw79z_12">
                  <div className="_heading_yw79z_25">Technologies</div>
                  <div className="_subtext_yw79z_63">
                    We leverage a wide range of cutting-edge technologies,
                    utilizing the latest and most renowned tech stacks and
                    platforms.
                  </div>
                  <div className="_logos_yw79z_86">
                    <div className="_circleContainer_4ww3l_9  undefined">
                      <img
                        src={angular}
                        className="_iconContainer_4ww3l_37"
                        alt="Circle"
                      />
                    </div>
                    <div className="_circleContainer_4ww3l_9  undefined">
                      <img
                        src={react}
                        className="_iconContainer_4ww3l_37"
                        alt="Circle"
                      />
                    </div>
                    <div className="_toolTipOuterContainer_yw79z_95">
                      <div className="_toolTipInnerContainer_yw79z_98">
                        <div className="_official_yw79z_122">
                          Advance Partner!
                        </div>
                        <div className="_arrow_yw79z_138" />
                      </div>
                      <div className="_circleContainer_4ww3l_9  _aws_yw79z_146">
                        <img
                          src={aws}
                          className="_iconContainer_4ww3l_37"
                          alt="Circle"
                        />
                      </div>
                    </div>
                    <div className="_circleContainer_4ww3l_9  undefined">
                      <img
                        src={v}
                        className="_iconContainer_4ww3l_37"
                        alt="Circle"
                      />
                    </div>
                    <div className="_circleContainer_4ww3l_9  undefined">
                      <img
                        src={java}
                        className="_iconContainer_4ww3l_37"
                        alt="Circle"
                      />
                    </div>
                    <div className="_circleContainer_4ww3l_9  undefined">
                      <img
                        src={dotNet}
                        className="_iconContainer_4ww3l_37"
                        alt="Circle"
                      />
                    </div>
                    <div className="_circleContainer_4ww3l_9  undefined">
                      <img
                        src={nodeJs}
                        className="_iconContainer_4ww3l_37"
                        alt="Circle"
                      />
                    </div>
                    <div className="_circleContainer_4ww3l_9  undefined">
                      <img
                        src={goLang}
                        className="_iconContainer_4ww3l_37"
                        alt="Circle"
                      />
                    </div>
                    <div className="_circleContainer_4ww3l_9  undefined">
                      <img
                        src={gCloud}
                        className="_iconContainer_4ww3l_37"
                        alt="Circle"
                      />
                    </div>
                    <div className="_circleContainer_4ww3l_9  undefined">
                      <img
                        src={azure}
                        className="_iconContainer_4ww3l_37"
                        alt="Circle"
                      />
                    </div>
                    <div className="_circleContainer_4ww3l_9  undefined">
                      <img
                        src={flutter}
                        className="_iconContainer_4ww3l_37"
                        alt="Circle"
                      />
                    </div>
                    <div className="_circleContainer_4ww3l_9  undefined">
                      <img
                        src={android}
                        className="_iconContainer_4ww3l_37"
                        alt="Circle"
                      />
                    </div>
                    <div className="_circleContainer_4ww3l_9  undefined">
                      <img
                        src={apple}
                        className="_iconContainer_4ww3l_37"
                        alt="Circle"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <main id="maincontent" className="page-main lowercase-headers">
            <div className="home-page " data-track-source="Home Page">
              <div className="pimcore_area_services pimcore_area_content ">
                <section
                  className="a-services waypoint waypoint-reveal"
                  data-track-source="Editable: HP Services"
                >
                  <div className="container">
                    <h2 className="a-section__title h2 step-up 1 ">
                      <span
                        id="Services"
                        className="not-hover-link"
                        name="Services"
                      >
                        Explore Our Offering
                      </span>
                    </h2>
                    <div className="d-flex a-services__tabs step-in">
                      <div className="a-services__tabs-container services-pager step-in-left col-md-4 order-md-0">
                        <ul className="a-services__tabs-container-ul">
                          {data?.servicesData.map((service, index) => (
                            <li key={index}>
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleTabClick(index);
                                }}
                                className={`a-services__tabs-label ${
                                  activeIndex === index ? "active" : ""
                                }`}
                              >
                                <p>{service.title}</p>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="a-services__content-block step-in-right col-md-7 bootstrap-p-0 p-3">
                        <div className="d-flex flex-column h-100">
                          <p className="a-services__name">
                            {data?.servicesData[activeIndex].content.name}
                          </p>
                          <div className="a-services__description">
                            <p>
                              {
                                data?.servicesData[activeIndex].content
                                  .description
                              }
                            </p>
                          </div>
                          <div
                            className="a-services__bullets  bootstrap-flex-row bootstrap-flex-wrap justify-content-between"
                            style={{
                              display: "grid !important",
                              gridTemplateColumns: "repeat(3,200px)",
                            }}
                          >
                            {data?.servicesData[
                              activeIndex
                            ].content.bullets.map((bullet, i) => (
                              <div className="a-services__bullet" key={i}>
                                <div className="a-services__bullet-a js-track-click">
                                  {bullet.label}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              {data && (
                <div className="_container_1tg7o_9 " id="headSection">
                  <div className="content" custom-id="darkBgNav">
                    <div className="_titleContainer_1tg7o_20">
                      <div className="_titleContainerHeader_1tg7o_86">
                        {data?.industries?.title}
                      </div>
                      <div className="_titleContainerDescription_1tg7o_110">
                        {data?.industries?.description}
                      </div>
                    </div>
                    <div
                      className="_cardBox_1tg7o_124 "
                      style={{ paddingBottom: "2rem" }}
                    >
                      {data?.industries?.serviceCards?.map((item, index) => (
                        <div className="_cardContainer_1tg7o_146 " key={index}>
                          <Avatar
                            icon={item?.icon}
                            alt="Card Header"
                            style={{
                              fontSize: "3rem",
                              width: "4rem",
                              height: "4rem",
                            }}
                          />
                          <div className="_cardContent_1tg7o_196 ">
                            <div className="_cardTitle_1tg7o_201">
                              {item?.header}
                            </div>
                            <div className="_cardDescription_1tg7o_213">
                              {item?.description}
                            </div>
                          </div>
                          <div className="_detailsContainer_1tg7o_178">
                            <a
                              className="_detailsLink_1tg7o_221 cursor-pointer text-white"
                              onClick={() => navigate(item?.link)}
                            >
                              {item?.linkText}
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className="_bottomSection_1tg7o_241 _homePageBottomSection_1tg7o_682 _ourServicesBtnContainer_tmpov_45"
                    custom-id="darkBgNav"
                  />
                </div>
              )}
              <div className="pimcore_area_image-text pimcore_area_content ">
                <section
                  className="a-image-text waypoint waypoint-reveal"
                  data-track-source="Editable: Image Text"
                >
                  <div className="container">
                    <div className="a-image-text__wrapper step-in right">
                      <div className="a-image-text__bg">
                        <div className="a-image-text__image step-in-right">
                          <img
                            alt="ScienceSoft"
                            src={data?.CareOf?.imageUrl}
                            className="lazy entered loaded"
                            data-ll-status="loaded"
                          />
                        </div>
                        <div className="a-image-text__content d-flex flex-column justify-content-between step-in-left">
                          <h2 className="a-section__title h2  1 ">
                            {data?.CareOf?.title}
                          </h2>
                          <div className="a-image-text__description">
                            <p>{data?.CareOf?.description}:</p>

                            <ul>
                              {data?.CareOf?.points?.map((item) => (
                                <li>{item}</li>
                              ))}
                              :
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="pimcore_area_tabs-with-icons pimcore_area_content ">
                <section
                  className="a-tabs-icons waypoint waypoint-reveal"
                  style={{ backgroundColor: "#fafafa" }}
                  data-track-source="Editable: Tabs with icons"
                >
                  <div className="container">
                    <h2 className="a-section__title h2 step-up 1 ">
                      {data?.techTrends?.title}
                    </h2>
                    <p className="a-section__description step-up">
                      {data?.techTrends?.description}
                    </p>
                    <div className="a-tabs-icons__tabs step-up">
                      <div className="a-tabs-icons__tabs-container">
                        <ul className="a-tabs-icons__tabs-container-ul">
                          {data?.techTrends?.tabs?.map((item, index) => (
                            <li
                              key={index}
                              onClick={() => {
                                handleTabTrend(index);
                              }}
                            >
                              <a
                                data-slide-index={index}
                                // className="a-tabs-icons__tabs-label active"
                                className={`a-tabs-icons__tabs-label ${
                                  activeTrend === index ? "active" : ""
                                }`}
                              >
                                <div
                                  className="a-tabs-icons__wrapper a-tabs-icons__wrapper--1 lazy entered loaded"
                                  style={{
                                    backgroundImage:
                                      'url("/bundles/app/img/tabs_icon-sprites.webp")',
                                  }}
                                />
                                <p className="d-flex flex-column justify-content-center a-tabs-icons__tabs-title">
                                  {item?.title}
                                </p>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="row a-tabs-icons__content-block">
                        <div className="col-md-10 offset-md-1">
                          <div
                            className="bx-wrapper"
                            style={{ maxWidth: 1133 }}
                          >
                            <div className="bx-viewport" aria-live="polite">
                              <div className="a-tabs-icons__content-container bx-slider-init">
                                <div className="a-tabs-icons__content bx-clone">
                                  <p className="a-tabs-icons__description">
                                    {
                                      data?.techTrends?.tabs[activeTrend]
                                        ?.description
                                    }
                                  </p>
                                  <p className="a-tabs-icons__bullets-title">
                                    Services we offer:
                                  </p>
                                  <div className="a-tabs-icons__bullets">
                                    {data?.techTrends?.tabs[
                                      activeTrend
                                    ]?.services?.map((service) => (
                                      <div className="a-tabs-icons__bullet">
                                        <div className="a-tabs-icons__bullets-a">
                                          {service}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="bx-controls" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="pimcore_area_solution-types pimcore_area_content ">
                <section
                  className="a-solution-types waypoint waypoint-reveal"
                  data-track-source="Editable: Solution Types"
                >
                  <div className="container">
                    <h2 className="a-section__title h2 step-up 1 ">
                      <span
                        id="Solutions"
                        className="not-hover-link"
                        name="Solutions"
                      >
                        Solutions We Deliver
                      </span>
                    </h2>
                    <p className="a-section__description step-up">
                      We IT-enable all kinds of B2B, B2C interactions and
                      internal operations.
                    </p>
                    <div className="a-solution-types__list step-up">
                      {data?.solutionTypes?.map((solution, index) => (
                        <div
                          key={index}
                          data-piwik-event={solution.event}
                          className="a-solution-types__link a-card step-up"
                          // href={solution.url}
                          // target="_self"
                        >
                          {solution.title}
                          <i className="icon-font icon-s-arrow" />
                        </div>
                      ))}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </main>
          <div
            className="pimcore_area_wysiwyg pimcore_area_content "
            style={{ marginTop: "1rem" }}
          >
            <div
              className="container waypoint waypoint-reveal"
              data-track-source="Editable: Wysiwyg"
            >
              <section className="a-wysiwyg fade-in">
                <h2>FAQ on Software Development</h2>
              </section>
            </div>
          </div>
          <div
            className="pimcore_area_collapsible-list pimcore_area_content my-5"
           
          >
            <div
              className="container cl-wrapper"
              data-track-source="Editable: Collapsible List"
            >
              <div className="">
                <div className="cl-items mb-32 max-1023:mb-24 ">
                  {data?.faqs.map((faq, index) => (
                    <Collapse
                      accordion
                      style={{ marginTop: "5px" }}
                      className="bg-white"
                    >
                      <Panel header={faq.question} key={index}>
                        <div className="cl-item-content-text">
                          <p>{faq.answer}</p>
                        </div>
                      </Panel>
                    </Collapse>
                  ))}
                </div>
              </div>
            </div>
          </div>

        </>
      )}
    </>
  );
};

export default Home;
