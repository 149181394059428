import React from "react";
import { Form, Input, Button, Select } from "antd";

const { Option } = Select;

const ServiceContact = () => {
  const onFinish = (values) => {
    console.log("Form data:", values);
    // Handle form submission logic here
  };

  return (
    <div className="_wrapper_frxpx_9" id="contact-us" custom-id="lightBgNav">
      <div className="content _container_frxpx_12">
        <div
          className="_leftSection_frxpx_20"
          style={{
            backgroundImage: 'url("/assets/backgroundContactUs-bc8ab051.png")',
          }}
        >
          <div className="_titleContainer_frxpx_59" style={{ padding: 15 }}>
            <p className="_title_frxpx_59" style={{ color: "black" }}>
              Need help with your Business?
            </p>
            <p className="_description_frxpx_85" style={{ color: "black" }}>
              Let's discuss what we can build, and scale together. We would love
              to hear from you and will be in touch shortly.
            </p>
          </div>
        </div>
        <div className="_rightSection_frxpx_108">
          <Form
            name="contact-form"
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{
              prefix: "+91", // Default value for phone prefix
            }}
          >
            <div className="_inputWrapper_n0m7c_9 _colSpan1_frxpx_187">
              <label className="_label_n0m7c_18">
                Full name <span className="_required_n0m7c_15">*</span>
              </label>
              <Form.Item
                name="fullName"
                rules={[
                  {
                    required: true,
                    message: "Please enter your full name!",
                  },
                ]}
              >
                <Input
                  size="large"
                  className="_input_n0m7c_9"
                  placeholder="Enter your name"
                />
              </Form.Item>
            </div>
            <div className="_phoneWrapper_wgo6k_9 _colSpan1_frxpx_187">
              <label className="_label_wgo6k_18">
                Mobile number
                <span className="_required_wgo6k_15"> *</span>
              </label>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter your phone number!",
                  },
                ]}
              >
                <Input
                  size="large"
                  className="_phoneInput_wgo6k_58"
                  placeholder="Enter your phone"
                />
              </Form.Item>
            </div>
            <div className="_inputWrapper_n0m7c_9 _colSpan1_frxpx_187">
              <label className="_label_n0m7c_18">
                Email address <span className="_required_n0m7c_15">*</span>
              </label>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email address!",
                    type: "email",
                  },
                ]}
              >
                <Input
                  size="large"
                  className="_input_n0m7c_9"
                  placeholder="Enter your email"
                />
              </Form.Item>
            </div>
            <div className="_inputWrapper_11edg_9 _colSpan1_frxpx_187">
              <label className="_label_11edg_18">Enquiry type </label>
              <Form.Item name="enquiryType">
                <Select placeholder="Select enquiry type" size="large">
                  <Option value="general">General enquiry</Option>
                  <Option value="support">Support</Option>
                  <Option value="partnership">Partnership</Option>
                  {/* Add more options as needed */}
                </Select>
              </Form.Item>
            </div>
            <div className="_inputWrapper_1hndb_9 _colSpan2_frxpx_191">
              <label className="_label_1hndb_19">
                Message<span className="_required_1hndb_15">*</span>
              </label>
              <Form.Item
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Please enter your message!",
                  },
                ]}
              >
                <Input.TextArea
                  size="large"
                  rows={2}
                  maxLength={1000}
                  placeholder="Enter your message..."
                />
              </Form.Item>
            </div>
            <Form.Item>
              <Button
                style={{ background: "rgb(65 100 78)" }}
                type="primary"
                htmlType="submit"
                className="theme-button"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ServiceContact;
