import React, { useEffect, useState } from "react";
import OurExperties from "./OurExperties";
import OurClients from "./OurClients";
import ServiceContact from "./contact";
import { useLocation, useNavigate } from "react-router-dom";
import { ServicesConfig } from "../../assets/data/services";
import { Avatar, Spin } from "antd";
import banner from "../../assets/img/webDevelopmentBanner-969fc253.png";

const Services = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location?.search);
  const serviceType = queryParams?.get("service_type");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServiceData = async () => {
      setLoading(true);
      // Simulate API call or async data fetching
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate delay
      const serviceData = ServicesConfig?.[serviceType];
      setData(serviceData);
      setLoading(false);
    };

    fetchServiceData();
  }, [serviceType]);

  return (
    <div className="_webDevelopmentPage_ve90h_9">
      {loading ? (
        <div className="loader-container">
          <Spin size="large" className="custom-spin" />
        </div>
      ) : (
        <>
          {data && (
            <>
              <div
                className="_heroSection_10juh_9"
                style={{
                  height: "77vh",
                  backgroundImage: `url(${data?.heroSection?.backgroundImage})`,
                  backgroundPosition: data?.heroSection?.backgroundPosition,
                }}
              >
                <div className="_heroSectionGradient_tmpov_sub">
                  <div className="content _heroSectionContentContainer_ve90h_13">
                    <div className="_leftContainer_10juh_316">
                      <div className="_title_10juh_65 ">
                        {data?.heroSection?.title}
                      </div>
                      <p className="_description_10juh_109">
                        {data?.heroSection?.description}
                      </p>
                      <div className="_buttonsContainer_10juh_139">
                        {data?.heroSection?.buttons?.map((button, index) => (
                          <button
                            onClick={() => navigate("/contact-us")}
                            key={index}
                            className="_buttonRoot_1e920_9 _primaryButton_1e920_32 _contactUsButton_10juh_200  theme-button"
                          >
                            <span className="_iconContainer_10juh_231">
                              <Avatar icon={button?.icon} />
                            </span>
                            <span className="_btnText_10juh_224">
                              {button?.text}
                            </span>
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="_container_1tg7o_9" id="headSection">
                <div className="content" custom-id="darkBgNav">
                  <div className="_titleContainer_1tg7o_20">
                    <div className="_titleContainerHeader_1tg7o_86">
                      {data?.servicesSection?.title}
                    </div>
                    <div className="_titleContainerDescription_1tg7o_110">
                      {data?.servicesSection?.description}
                    </div>
                  </div>
                  <div className="_cardBox_1tg7o_124 _devopsCardBox_1tg7o_296">
                    {data?.servicesSection?.services?.map((service, index) => (
                      <div
                        key={index}
                        className="_cardContainer_1tg7o_146 _devopsOurServicesCard_1tg7o_317"
                      >
                        <Avatar
                          icon={service?.icon}
                          style={{
                            fontSize: "3rem",
                            width: "4rem",
                            height: "4rem",
                          }}
                        />
                        <div className="_cardContent_1tg7o_196 ">
                          <div className="_cardTitle_1tg7o_201">
                            {service?.title}
                          </div>
                          <div className="_cardDescription_1tg7o_213">
                            {service?.description}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className="_bottomSection_1tg7o_241 _devopsOurServicesBottomContainer_1tg7o_345 "
                  custom-id="lightBgNav"
                />
              </div>

              {data?.appCampaignsSection?.metaCampaigns && (
                <div
                  className="_howWeWorkSection_zox1y_9"
                  custom-id="metaSection"
                >
                  <div className="content _howWeWorkSectionContainer_ve90h_35">
                    <div className="_howWeWorkLayout_zox1y_22">
                      <div className="_content_zox1y_30">
                        <p className="_title_zox1y_49">
                          {data?.appCampaignsSection?.metaCampaigns?.title}
                        </p>
                        <p className="_description_zox1y_75">
                          {
                            data?.appCampaignsSection?.metaCampaigns
                              ?.description
                          }
                        </p>
                      </div>
                      <div className="_cardLayout_zox1y_85">
                        {data?.appCampaignsSection?.metaCampaigns?.steps?.map(
                          (step, index) => (
                            <div
                              key={index}
                              className="_cardStyle_zox1y_96 _webDevelopmentHowWeWorkCard_zox1y_169"
                            >
                              <div className="_howWeWorkLogo_zox1y_108">
                                <Avatar icon={step?.icon} />
                              </div>
                              <div className="_cardContent_zox1y_115">
                                <p className="_cardTitle_zox1y_122">
                                  {step?.title}
                                </p>
                                <p className="_cardDesc_zox1y_143">
                                  {step?.description}
                                </p>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {data?.appCampaignsSection?.googleCampaigns && (
                <div
                  className="_howWeWorkSection_zox1y_9"
                  custom-id="googleSection"
                >
                  <div className="content _howWeWorkSectionContainer_ve90h_35">
                    <div className="_howWeWorkLayout_zox1y_22">
                      <div className="_content_zox1y_30">
                        <p className="_title_zox1y_49">
                          {data?.appCampaignsSection?.googleCampaigns?.title}
                        </p>
                        <p className="_description_zox1y_75">
                          {
                            data?.appCampaignsSection?.googleCampaigns
                              ?.description
                          }
                        </p>
                      </div>
                      <div className="_cardLayout_zox1y_85">
                        {data?.appCampaignsSection?.googleCampaigns?.steps?.map(
                          (step, index) => (
                            <div
                              key={index}
                              className="_cardStyle_zox1y_96 _webDevelopmentHowWeWorkCard_zox1y_169"
                            >
                              <div className="_howWeWorkLogo_zox1y_108">
                                <Avatar icon={step?.icon} />
                              </div>
                              <div className="_cardContent_zox1y_115">
                                <p className="_cardTitle_zox1y_122">
                                  {step?.title}
                                </p>
                                <p className="_cardDesc_zox1y_143">
                                  {step?.description}
                                </p>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="_howWeWorkSection_zox1y_9" custom-id="lightBgNav">
                <div className="content _howWeWorkSectionContainer_ve90h_35">
                  <div className="_howWeWorkLayout_zox1y_22">
                    <div className="_content_zox1y_30">
                      <p className="_title_zox1y_49">
                        {data?.howWeWorkSection?.title}
                      </p>
                      <p className="_description_zox1y_75">
                        {data?.howWeWorkSection?.description}
                      </p>
                    </div>
                    <div className="_cardLayout_zox1y_85">
                      {data?.howWeWorkSection?.steps?.map((step, index) => (
                        <div
                          key={index}
                          className="_cardStyle_zox1y_96 _webDevelopmentHowWeWorkCard_zox1y_169"
                        >
                          <div className="_howWeWorkLogo_zox1y_108">
                            <Avatar icon={step?.icon} />
                          </div>
                          <div className="_cardContent_zox1y_115">
                            <p className="_cardTitle_zox1y_122">
                              {step?.title}
                            </p>
                            <p className="_cardDesc_zox1y_143">
                              {step?.description}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <OurExperties expertise={data?.expertise} />
            </>
          )}

          {/* <OurClients /> */}
          <ServiceContact />
        </>
      )}
    </div>
  );
};

export default Services;
