import React from "react";
import cardBfsi from "../../assets/img/expertise/cardBfsi-25a5b90f.png";
import cardDataProvider from "../../assets/img/expertise/cardDataProvider-e03d92c3.png";
import cardECommerce from "../../assets/img/expertise/cardECommerce-df93dd55.png";
import cardHealthcare from "../../assets/img/expertise/cardHealthcare-e180da3f.png";
import cardMarTech from "../../assets/img/expertise/cardMarTech-53bfbeaa.png";
import cardStockBroker from "../../assets/img/expertise/cardStockBroker-8c679773.png";
import cardShippingContainer from "../../assets/img/expertise/cardShippingContainer-0f91e0cc.png";

const OurExperties = ({ expertise }) => {
  return (
    <>
      <div custom-id="darkBgNav" className="_OurExperties_9t8gl_9">
        <div className="content">
          <div className="_layout_9t8gl_23">
            <div className="_title_9t8gl_37">Our Expertise</div>
            <div className="_description_9t8gl_75">
              Discover insights and drive innovation with our streamlined data
              &amp; AI work process.
            </div>
          </div>
          <div className="_layout_9t8gl_23 _cards_9t8gl_89">
            <div className="_wViewLayout_9t8gl_102 _mViewMT_9t8gl_118">
              <img
                src={cardMarTech}
                alt="MarTech"
                className="_expertiseImage_9t8gl_147"
              />
            </div>
            <div className="_mViewDP_9t8gl_122">
              <img
                src={cardDataProvider}
                alt="DataProvider"
                className="_expertiseImage_9t8gl_147"
              />
            </div>
            <div className="_wViewLayout_9t8gl_102 _mViewSB_9t8gl_130">
              <img
                src={cardStockBroker}
                alt="StockBroker"
                className="_expertiseImage_9t8gl_147"
              />
            </div>
            <div className="_mViewBFSI_9t8gl_134">
              <img
                src={cardBfsi}
                alt="Bfsi"
                className="_expertiseImage_9t8gl_147"
              />
            </div>
            <div className="_wViewLayout_9t8gl_102 _mViewHC_9t8gl_138">
              <img
                src={cardHealthcare}
                alt="Healthcare"
                className="_expertiseImage_9t8gl_147"
              />
            </div>
            <div className="_wViewLayout_9t8gl_102 _mViewSC_9t8gl_126">
              <img
                src={cardShippingContainer}
                alt="ShippingContainer"
                className="_expertiseImage_9t8gl_147"
              />
            </div>
            <div className="_mViewECom_9t8gl_142">
              <img
                src={cardECommerce}
                alt="Ecommerce"
                className="_expertiseImage_9t8gl_147"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurExperties;
