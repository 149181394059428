import React, { useEffect, useState } from "react";
import ServiceContact from "../services/contact";
import contact from "../../assets/img/home/helpdesk.webp";
import { Spin } from "antd";

const ContactUs = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServiceData = async () => {
      setLoading(true);
      // Simulate API call or async data fetching
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate delay

      setLoading(false);
    };

    fetchServiceData();
  }, []);
  return (
    <>
      {loading ? (
        <div className="loader-container">
          <Spin size="large" className="custom-spin" />
        </div>
      ) : (
        <>
          <div className="_contactUsPage_1p4va_9">
            <div
              className="_heroSection_10juh_9"
              style={{
                height: "77vh",
                backgroundPosition: "center center",
                backgroundImage: `url(${contact})`,
              }}
            >
              <div className="_heroSectionGradient_tmpov_sub">
                <div className="content _heroSectionContentContainer_1p4va_17">
                  <div className="_leftContainer_10juh_316 " style={{paddingTop:"25%"}}>
                    <div className="_title_10juh_65 ">
                      Let's Discuss what we can Build, and Scale Together{" "}
                    </div>
                    <p className="_description_10juh_109">
                      We would love to hear from you and will be in touch
                      shortly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div custom-id="lightBgNav">
              <div className=" _contactUsContainer_1p4va_37">
                <div className="_container_15l8x_22">
                  <ServiceContact />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ContactUs;
