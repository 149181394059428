import React, { useEffect, useState } from "react";
import { AboutUsConfig } from "../../assets/data/about-us-data";
import { Avatar, Spin } from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import { LinkedinOutlined } from "@ant-design/icons";

import ashsih from "../../assets/img/team/ashish.jpeg";
import ankit from "../../assets/img/team/ankit.jpeg";
import vedant from "../../assets/img/team/vedant.jpeg";

const AboutUs = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServiceData = async () => {
      setLoading(true);
      // Simulate API call or async data fetching
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate delay
      setData(AboutUsConfig);
      setLoading(false);
    };

    fetchServiceData();
  }, [AboutUsConfig]);

  const teamdata = [
    {
      name: "Ashish",
      profile: ashsih,
      subBio: "Co-Founder, Omaza",
      bio: "Ashish is committed to fostering talent and building a positive work environment. He is detail-oriented and passionate about coding.",
      linkedin: "https://www.linkedin.com/in/ashish-j-73044556/",
    },
    {
      name: "Vedant",
      profile: vedant,
      subBio: "Building Omaza | BITS Pilani | FMS",
      linkedin: "https://www.linkedin.com/in/vedantkatiyar/",
      bio: "Vedant is dedicated to fostering talent and creating a collaborative work environment. He is detail-oriented and passionate about innovation.",
    },
    {
      name: "Ankit Singh",
      profile: ankit,
      subBio: "Building Omaza | IIT(ISM) DHN",
      linkedin: "https://www.linkedin.com/in/ankit-singh-2507s/",
      bio: "Ankit is committed to fostering talent and driving innovation. He is detail-oriented and passionate about technology.",
    },
  ];

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <Spin size="large" className="custom-spin" />
        </div>
      ) : (
        <>
          <div className="_aboutUsPage_104ft_9">
            <div
              className="_heroSection_10juh_9"
              style={{
                backgroundImage:
                  'url("/assets/cyberBionicVirtual-a46dc9a2.png")',
                backgroundPosition: "center center",
              }}
            >
              <div className="_heroSectionGradient_104ft_9">
                <div className="content _heroSectionContentContainer_104ft_17">
                  <div className="_leftContainer_10juh_316 ">
                    <div className="_title_10juh_65 ">
                      {data?.heroSection?.title}
                    </div>
                    <p className="_description_10juh_109">
                      {data?.heroSection?.description}{" "}
                    </p>
                    <div className="_buttonsContainer_10juh_139">
                      <button
                        onClick={() => navigate("/contact-us")}
                        className="_buttonRoot_1e920_9 _primaryButton_1e920_32 _contactUsButton_10juh_200 theme-button"
                      >
                        <span className="_iconContainer_10juh_231 ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_4355_7310)">
                              <path
                                d="M20.9706 3.95437C21.0068 3.82608 21.0081 3.69047 20.9744 3.5615C20.9408 3.43253 20.8733 3.31487 20.7791 3.22062C20.6848 3.12637 20.5672 3.05893 20.4382 3.02527C20.3092 2.9916 20.1736 2.99291 20.0453 3.02906L2.04531 8.48719C1.89836 8.52866 1.76756 8.614 1.67041 8.7318C1.57326 8.84961 1.51438 8.99425 1.50164 9.14642C1.4889 9.29858 1.52291 9.45101 1.59912 9.58332C1.67533 9.71564 1.79011 9.82154 1.92813 9.88687L9.955 13.6875C10.1113 13.7616 10.2371 13.8875 10.3113 14.0437L14.1128 22.0697C14.1781 22.2077 14.284 22.3225 14.4164 22.3987C14.5487 22.4749 14.7011 22.5089 14.8533 22.4962C15.0054 22.4834 15.1501 22.4246 15.2679 22.3274C15.3857 22.2302 15.471 22.0995 15.5125 21.9525L20.9706 3.95437Z"
                                stroke="white"
                                strokeWidth="1.6"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10.1641 13.8356L14.9997 9"
                                stroke="white"
                                strokeWidth="1.6"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4355_7310">
                                <rect width={24} height={24} fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        <span className="_btnText_10juh_224">Contact Us</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div custom-id="lightBgNav" className="_whoWeAreSection_50wyh_9">
              <div className="content _whoWeAreContainer_104ft_27">
                <div className="_whoWeAreCardsWrapper_50wyh_98">
                  <div className="_cardSection_50wyh_116">
                    {data?.whoWeAre?.highlights?.map((item) => (
                      <div className="_whoWeAreCardsLayout_50wyh_123">
                        <Avatar
                          icon={item?.icon}
                          alt={item?.title}
                          style={{
                            fontSize: "3rem",
                            width: "4rem",
                            height: "4rem",
                          }}
                        />
                        <div>
                          <p className="_cardTitle_50wyh_145">{item?.title}</p>
                          <p className="_cardDescription_50wyh_166">
                            {item?.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="_contentWrapper_50wyh_9">
                  <p className="_title_50wyh_29  ml-4">
                    {data?.whoWeAre?.title}
                  </p>
                  <div className="_description_50wyh_66">
                    {data?.whoWeAre?.description}
                  </div>
                </div>
              </div>
            </div>
            <div className="_container_1tg7o_9 " id="headSection">
              <div className="content" custom-id="darkBgNav">
                <div className="_titleContainer_1tg7o_20">
                  <div className="_titleContainerHeader_1tg7o_86">
                    {data?.ourMission?.title}
                  </div>
                  <div className="_titleContainerDescription_1tg7o_110">
                    {data?.ourMission?.description}
                  </div>
                </div>
                <div
                  className="_cardBox_1tg7o_124 _ourMissionCardBox_1tg7o_711"
                  style={{ paddingBottom: "2rem" }}
                >
                  {data?.ourMission?.cardHeaders?.map((item) => (
                    <div className="_cardContainer_1tg7o_146 ">
                      <Avatar
                        src={item?.icon}
                        alt="Card Header"
                        style={{
                          fontSize: "4rem",
                          width: "5rem",
                          height: "5rem",
                        }}
                      />
                      <div className="_cardContent_1tg7o_196 ">
                        <div className="_cardTitle_1tg7o_201">
                          {item?.title}
                        </div>
                        <div className="_cardDescription_1tg7o_213">
                          {item?.description}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="_bottomSection_1tg7o_241  _bottomContainerDynamicClass_104ft_41"
                custom-id="darkBgNav"
              />
            </div>
            <div custom-id="lightBgNav" className="_container_1hs1q_9">
              <div className="content">
                <div className="_heading_1hs1q_23">
                  {" "}
                  {data?.ourVision?.title}
                </div>
                <div className="_subtext_1hs1q_65">
                  {data?.ourVision?.description}
                </div>
              </div>
            </div>
            <div
              className="_container_199mo_9 _container_1tg7o_9"
              id="headSectionAboutUs"
            >
              <div className="content">
                <div className="_titleContainer_199mo_25" custom-id="darkBgNav">
                  <div className="_titleContainerHeader_199mo_89">
                    Meet our Leadership
                  </div>
                  <div className="_titleContainerDescription_199mo_113">
                    Learn more about our torchbearers who steer the team towards
                    continuous improvement every day through their strong
                    guidance and leadership.
                  </div>
                </div>

                <div className="_cardBox_199mo_123">
                  {teamdata?.map((item) => (
                    <div className="_cardContainer_199mo_146" key={item?.name}>
                      <div className="_cardImage_199mo_187">
                        <img
                          src={item?.profile}
                          className="_personImage_199mo_214"
                          alt="Employee"
                        />
                      </div>
                      <div className="_cardDetails_199mo_219">
                        <div className="_cardContent_199mo_264">
                          <div className="_cardTitle_199mo_283">
                            {item?.name}
                          </div>
                          <div className="_cardSubTitle_199mo_172">
                            {item?.subBio}
                          </div>
                          <div className="_cardDescription_199mo_295">
                            {item?.bio}
                          </div>
                        </div>
                        <a
                          className="_detailsContainer_199mo_233"
                          href={item?.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#0A66C2",
                          }}
                        >
                          <LinkedinOutlined
                            style={{ fontSize: "24px", color: "#0A66C2" }}
                          />
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AboutUs;
