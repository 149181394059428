import React, { useEffect, useState } from "react";
import ServiceContact from "../services/contact";
import { useLocation, useNavigate } from "react-router-dom";
import { ourWork } from "../../assets/data/our-work";
import { Avatar, Spin } from "antd";

const Portfolio = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location?.search);
  const portfolio = queryParams?.get("portfolio");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServiceData = async () => {
      setLoading(true);
      // Simulate API call or async data fetching
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate delay
      const serviceData = ourWork?.[portfolio];
      setData(serviceData);
      setLoading(false);
    };

    fetchServiceData();
  }, [portfolio]);

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <Spin size="large" className="custom-spin" />
        </div>
      ) : (
        <>
        <div className="_homePage_tmpov_9" id="homePage">
          <div className="_caseStudyPage_q1x6y_9">
            <div
                className="_heroSection_10juh_9"
              style={{
                backgroundImage:`url(${data?.heroSection?.backgroundImage})`,
                backgroundPosition: "center center",
              }}
            >
              <div className="_heroSectionGradient_tmpov_9">
                <div className="content _heroSectionContentContainer_tmpov_12">
                  <div className="_leftContainer_10juh_316 ">
                    <div className="_homeTitle_10juh_21">
                      <div> {data?.heroSection?.title} </div>
                    </div>
                    <p className="_description_10juh_109">
                          {data?.heroSection?.description}
                        </p>
                    <div className="_buttonsContainer_10juh_139">
                          <button
                            onClick={() => navigate("/contact-us")}
                            className="_buttonRoot_1e920_9 _primaryButton_1e920_32 _contactUsButton_10juh_200 _contactUsBtnClass_tmpov_32"
                          >
                            Book Free Consultation
                          </button>
                        </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              custom-id="lightBgNav"
              className="_studyDescriptionSection_kjyec_9"
            >
              <div className="content _studyDescriptionContainer_q1x6y_29">
                <div className="_studyDescriptionLayout_kjyec_67">
                  <div className="_overviewLayout_kjyec_84">
                    <div className="_overviewSection_kjyec_90">
                      <h2 className="_title_kjyec_97">INDUSTRY</h2>
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAAECAYAAADbPvzIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABPSURBVHgB3dExEYBAEEPRZBUggZ5BExoYhh4coA5KTsIpSNgSDfuqtJnPaXuWoC8DAwoh0SQenPf7tTGiph5WrXJ/Ga6HrDVHQzV5ztb5AZunHfHXUvNmAAAAAElFTkSuQmCC"
                        alt="Divider"
                      />
                    </div>
                    <p className="_subTitle_kjyec_118">
                      {data?.heroSection?.industry}
                    </p>
                  </div>
                  <div className="_overviewLayout_kjyec_84">
                    <div className="_overviewSection_kjyec_90">
                      <h2 className="_title_kjyec_97">
                        {" "}
                        {data?.businessChallengeSection?.title}
                      </h2>
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAAECAYAAADbPvzIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABPSURBVHgB3dExEYBAEEPRZBUggZ5BExoYhh4coA5KTsIpSNgSDfuqtJnPaXuWoC8DAwoh0SQenPf7tTGiph5WrXJ/Ga6HrDVHQzV5ztb5AZunHfHXUvNmAAAAAElFTkSuQmCC"
                        alt="Divider"
                      />
                    </div>
                    <div className="_descriptionLayout_kjyec_127">
                      <p className="_description_kjyec_127">
                        {data?.businessChallengeSection?.description}
                      </p>
                      <ul className="_descriptionList_kjyec_154">
                        {data?.businessChallengeSection?.challenges?.map(
                          (item) => (
                            <li className="_list_kjyec_13">
                              {item?.description}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="_overviewLayout_kjyec_84">
                    <div className="_overviewSection_kjyec_90">
                      <h2 className="_title_kjyec_97">
                        {data?.solutionSection?.title}
                      </h2>
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAAECAYAAADbPvzIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABPSURBVHgB3dExEYBAEEPRZBUggZ5BExoYhh4coA5KTsIpSNgSDfuqtJnPaXuWoC8DAwoh0SQenPf7tTGiph5WrXJ/Ga6HrDVHQzV5ztb5AZunHfHXUvNmAAAAAElFTkSuQmCC"
                        alt="Divider"
                      />
                    </div>
                    <div className="_solutionDescriptionLayout_kjyec_171">
                      <p className="_highlightedText_kjyec_178">
                        <strong>{data?.solutionSection?.description}</strong>
                      </p>
                      <ul className="_solutionList_kjyec_200">
                        {data?.solutionSection?.solutions?.map((item) => (
                          <li className="_list_kjyec_13">
                            {item?.description}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="_technologyIconLayout_kjyec_217">
                      <h2 className="_title_kjyec_97">Technologies:</h2>
                      <div className="_iconsLayout_kjyec_239">
                        {data?.solutionSection?.technologies?.map((item) => (
                          <span className="_techIcon_kjyec_247">{item}</span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div custom-id="darkBgNav" className="_contentContainer_uk0ky_9">
              <div className="content">
                <div className="_mainContainer_uk0ky_13">
                  <h2 className="_heading_uk0ky_28">
                    {data?.outcomeSection?.title}
                  </h2>
                  <div className="_whiteLine_uk0ky_82" />
                  <div className="_subText_uk0ky_62">
                    {data?.outcomeSection?.description}
                  </div>
                  <div className="_outcomeArray_uk0ky_95 ">
                    {data?.outcomeSection?.outcomes?.map((item) => (
                      <div className="_outcome_uk0ky_95">
                        <Avatar
                          icon={item?.icon}
                          className="_outcomeIcon_uk0ky_114"
                          alt="Outcome Icon"
                        />
                        <div>{item?.description}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <ServiceContact />
          </div>
          </div>
        </>
      )}
    </>
  );
};

export default Portfolio;
