import {
  TeamOutlined,
  AppstoreOutlined,
  ClockCircleOutlined,
  LineChartOutlined,
} from "@ant-design/icons";

import Clean from "../img/media/clean.jpeg";
import OIP from "../img/media/OIP.jpeg";
import Startup from "../img/media/startup.jpeg";
export const CareerData = {
  heroSection: {
    title: "Create Impactful Software with Us",
    subtitle:
      "Join us to build a career where your potential and ideas are valued.",
  },
  whyJoinUs: {
    title: "Why Join Us",
    description:
      "We are a team of visionaries and achievers, blending the best of design and coding. Our employee-centric policies, competitive pay scale, dynamic work environment, and collaboration with talented peers make it the perfect place to grow your career and make an impact.",
    highlights: [
      {
        value: "Talented Team",
        label: "Engineers and Designers",
        icon: <TeamOutlined />,
      },
      {
        value: "Diverse Projects",
        label: "Web and Mobile applications built",
        icon: <AppstoreOutlined />,
      },
      {
        value: "Years of Experience",
        label: "Building software products",
        icon: <ClockCircleOutlined />,
      },
      {
        value: "Consistent Growth",
        label: "Year-on-year organizational growth",
        icon: <LineChartOutlined />,
      },
    ],
  },
  media: {
    title: "Omaza in Media",
    description:
      "We have been turning heads and catching eyes with our remarkable journey. Take a glimpse into what the media has to say about our thoughts, accomplishments, and vibrant culture.",
    articles: [
      {
        title: "How this IIT Bombay Alumni’s Bootstrapped Startup.",

        image: OIP,
      },
      {
        title: "Clean Coders: Why India isn’t on the list.",

        image: Clean,
      },
      {
        title:
          "Startup Circle: How has Omaza Helped ConnectAndSell Build their all-round platform?",

        image: Startup,
      },
    ],
  },
  lifeAtCompany: {
    title: "Life at Our Company",
    description1:
      "We believe that a company's progress is tied to the growth of its people. We foster an environment filled with endless opportunities and personal development for every member of our team.\n\nEmbracing diversity and individuality, we shun monotony as much as you do. Life here is an exciting blend of work and play, where laughter and creativity thrive. We celebrate moments, share jokes, and fuel our creativity together.",
    description2:
      "We believe that a company's progress is tied to the growth of its people. We foster an environment filled with endless opportunities and personal development for every member of our team.\n\nEmbracing diversity and individuality, we shun monotony as much as you do. Life here is an exciting blend of work and play, where laughter and creativity thrive. We celebrate moments, share jokes, and fuel our creativity together.",
  },
  testimonials: {
    title: "Employee Testimonials",
    testimonials: [
      {
        text: "This company offers endless opportunities to learn and grow, with the freedom to apply our skills and ideas. Leadership and management are very transparent and approachable. From day one, I was entrusted with opportunities and responsibilities, making me proud to be part of the team.",
        author: "Varun Elavia",
        position: "Associate Architect DevOps",
      },
      {
        text: "The focus on employee growth and well-being here is unparalleled. The open-door policy and approachable leadership make it a fantastic place to work.",
        author: "Samantha Green",
        position: "Senior Software Engineer",
      },
      {
        text: "Working here has been an amazing journey. The collaborative environment and the support from colleagues and management have helped me achieve professional and personal growth.",
        author: "Michael Brown",
        position: "Project Manager",
      },
    ],
  },
};
