import React from "react";

const TermsofUse = () => {
  return (
    <>
      <div className="_privacyPolicyPage_f2rzd_9">
        <div
          className="_heroSection_10juh_9"
          style={{
            backgroundImage: 'url("/assets/privacyPolicy-cf3d6bfb.png")',
            backgroundPosition: "center center",
          }}
        >
          <div className="_heroSectionGradient_f2rzd_13">
            <div className="content _heroSectionContentContainer_f2rzd_26">
              <div className="_leftContainer_10juh_316 ">
                <div className="_title_10juh_65 _title_f2rzd_16">
                  Terms Of Use{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div custom-id="lightBgNav" className="content">
          <div className="_cardContainer_f2rzd_30">
            <div className="_content_f2rzd_48">
              <div className="_section_f2rzd_59">
                <div className="_sectionTitle_f2rzd_80">
                  1. Introduction and Terms of Use
                </div>
                <div className="_description_f2rzd_87">
                  The terms and conditions contained herein ("Terms of Use")
                  apply to the use of Omaza ("Omaza" or "we" or "our"), a trade
                  name owned and managed by Omaza Innovations LLP. Omaza is an
                  application available on the Apple and Android stores that
                  facilitates users registered on Omaza to buy fresh fruits and
                  vegetables across cities in India ("Services").
                </div>
                <div className="_description_f2rzd_87">
                  Any person logging on to or using Omaza (even when such person
                  does not make any purchase from Omaza) ("User" or "you") shall
                  be presumed to have read these Terms of Use (which includes
                  the Privacy Policy, separately provided on Omaza) and
                  unconditionally accepted the terms and conditions set out
                  herein, constituting a binding and enforceable agreement
                  between the User and Omaza ("Agreement"). This Agreement does
                  not alter in any way the terms or conditions of any other
                  written agreement you may have with Omaza for other services.
                  If you do not agree to this Agreement (including any
                  referenced policies or guidelines), please immediately
                  terminate your use of Omaza. To the extent applicable, these
                  Terms of Use shall apply to the website of Omaza and its
                  visitors as well.
                </div>
                <div className="_sectionTitle_f2rzd_80">
                  2. Membership Eligibility
                </div>
                <div className="_description_f2rzd_87">
                  ### Membership Eligibility Use of Omaza is available only to
                  individuals who can form legally binding contracts under the
                  Indian Contract Act, 1872. Persons who are considered
                  "incompetent to contract" under the Indian Contract Act, 1872,
                  including minors and un-discharged insolvents, are not
                  eligible to use Omaza. If you are a minor, i.e., under the age
                  of 18 years, you must not register as a User of Omaza and must
                  not transact or use Omaza. If you are a minor and wish to use
                  or transact on Omaza, such use or transaction must be made by
                  your legal guardian or parents on Omaza. Omaza reserves the
                  right to terminate your membership and/or refuse to provide
                  you with access to Omaza if it is brought to Omaza's notice or
                  discovered that you are under the age of 18 years. By
                  accepting these Terms of Use, the User irrevocably declares
                  and undertakes that they are of legal age, i.e., 18 years or
                  older, and capable of entering into a binding contract. Such
                  usage shall be deemed to form a contract between Omaza and the
                  User to the extent permissible under applicable laws.
                </div>
                <div className="_sectionTitle_f2rzd_80">
                  3. Account and Registration Obligations
                </div>
                <div className="_description_f2rzd_87">
                  If you use Omaza, you are responsible for maintaining the
                  confidentiality of your account and password and for all
                  activities that occur under your account. We will not be
                  liable for any loss or damage arising from your failure to
                  protect your password or account. You should take all
                  necessary steps to ensure that your password is kept
                  confidential and secure. If you have any reason to believe
                  that your password has become known to anyone else or is being
                  used, or is likely to be used, in an unauthorized manner, you
                  should inform us immediately at support@omaza.in.
                  <br></br>
                  You agree that if you provide any information that is untrue,
                  inaccurate, not current, or incomplete, or if we have
                  reasonable grounds to suspect that such information is untrue,
                  inaccurate, not current, or incomplete, or not in accordance
                  with these Terms of Use, we shall have the right to
                  indefinitely suspend or terminate your account and refuse to
                  provide you with access to Omaza.
                  <br></br>
                  <br></br>
                  You also agree and confirm that you will:
                  <br></br>
                  <br></br>
                  1. provide accurate, current and complete information whenever
                  prompted by Omaza or when required by the Omaza’s Registered
                  form (“Registered Data”).
                  <br></br>
                  2. provide information about and/or access to your social
                  accounts to enable us to use such information solely for the
                  purpose of providing services to you.
                  <br></br>
                  3. maintain and promptly update the Registered Data to keep it
                  accurate, current and complete at all times. We reserve the
                  right to confirm and validate the information and other
                  details provided by you at any point of time. If you provide
                  any information that is untrue, inaccurate, incomplete, or not
                  current or if we have reasonable grounds to suspect that such
                  information is not in accordance with the Terms of Use
                  (whether wholly or in part thereof), we reserve the right to
                  indefinitely suspend or terminate your membership and to
                  refuse to provide you access to Omaza.
                  <br></br>
                  <br></br>
                </div>
                <div className="_sectionTitle_f2rzd_80">4. Communication</div>
                <div className="_description_f2rzd_87">
                  When you use Omaza or send emails or other data, information,
                  or communication to us, you agree and understand that you are
                  communicating with us through electronic records. You consent
                  to receive communications via electronic records from us
                  periodically and as needed. We may communicate with you by
                  email or other modes of communication, electronic or
                  otherwise.
                  <br></br>
                  <br></br>
                  When you visit Omaza or send emails to us, you are
                  communicating with us electronically. You will be required to
                  provide a valid phone number while placing an order with us.
                  We will communicate with you by email, phone calls, SMS, or by
                  posting notices on Omaza. You agree that all agreements,
                  notices, disclosures, and other communications that we provide
                  to you electronically satisfy any legal requirement that such
                  communications be in writing. Notwithstanding anything
                  contained in this Agreement and subject to applicable laws,
                  you consent to receive communications (including
                  transactional, promotional, and/or commercial messages) from
                  us regarding your use of the website and/or your order placed
                  on Omaza.
                </div>
                <div className="_sectionTitle_f2rzd_80">
                  5. Indemnity and Release
                </div>
                <div className="_description_f2rzd_87">
                  You shall indemnify and hold harmless Omaza, and any of its
                  officers, directors, agents, employees, and representatives,
                  from any claim, demand, or action, including reasonable
                  attorney's fees, made by any third party or penalty imposed
                  due to or arising out of your breach of these Terms of Use or
                  any document incorporated by reference, or your violation of
                  any applicable laws, rules, regulations, or the rights of any
                  third party.
                  <br></br>
                  <br></br>
                  You hereby expressly release Omaza and any of its officers and
                  representatives from any cost, damage, liability, or other
                  consequence of any actions or inactions of third parties and
                  specifically waive any claims or demands that you may have in
                  this regard under any statute, contract, or otherwise.
                </div>
                <div className="_sectionTitle_f2rzd_80">6. Privacy</div>
                <div className="_description_f2rzd_87">
                  To avail the Services from Omaza, you will need to provide
                  certain personal information ("Registered Data") prior to
                  registration. As an account holder, you are required to
                  provide Omaza with accurate personal information. Any personal
                  information collected by Omaza will not be disclosed to any
                  third party unrelated to Omaza unless such a disclosure is
                  authorized or required by law, or you have expressly consented
                  to such a disclosure.
                  <br />
                  However, by accepting these terms and conditions, you grant
                  Omaza an unconditional right to share your personal
                  information with Omaza partners, affiliates, employees, and
                  agents for administrative purposes. You are requested to
                  review the Privacy Policy before using Omaza. If you have any
                  objections to the terms of our Privacy Policy, you are
                  requested not to use Omaza.
                </div>
                <div className="_sectionTitle_f2rzd_80">
                  7. Credit Cards/ Financial Wallets / UPI/ Banking A/c No or
                  any other Financial Information.
                </div>
                <div className="_description_f2rzd_87">
                  You agree, understand and confirm that the financial details
                  provided by you for availing of Services of Omaza will be
                  correct and accurate and you shall not use any financial
                  payment option which is un-lawfully owned by you or outside
                  the purview of the law of the country of India. Thus, you must
                  not pay or attempt to pay for Services using fraudulent
                  financial information and through illegal payment option as
                  per law of India. You further agree and undertake to provide
                  the correct and details to Omaza.
                  <br></br>
                  <br></br>
                  Further the said information will not be utilized and shared
                  by Omaza with any third parties unless required for fraud
                  verifications or by law, regulation or court order. Omaza will
                  not be liable for any kind of financial fraud. The liability
                  for use of a financial payment option will be on you and the
                  onus to ‘prove otherwise’ shall be exclusively yours.
                </div>
                <div className="_sectionTitle_f2rzd_80">8. User Liability</div>
                <div className="_description_f2rzd_87">
                  The User, by the act of logging onto Omaza is deemed to have
                  consented to and has expressly/impliedly and irrevocably
                  authorized Omaza to use, reveal, analyze, display or transmit
                  all information required by Omaza. The User represents and
                  warrants that all the information provided by him/her are
                  true, correct and complete and if such information is found to
                  be untrue, incorrect or incomplete, Omaza has the right to
                  take any action it deems appropriate in relation to the
                  particular circumstances without any limitations.
                </div>
                <div className="_sectionTitle_f2rzd_80">
                  9.Representations, Warranties and Covenants
                </div>
                <div className="_description_f2rzd_87">
                  You shall not, directly or indirectly attempt to gain
                  unauthorized access to Omaza, other Users’ account(s),
                  computer systems and/or networks connected to Omaza through
                  hacking, phishing, password mining and/or any other means
                  (whether now known or hereafter developed or invented) or
                  obtain any material or information through any means not
                  intentionally made available to User.
                  <br></br>
                  <br></br>
                  You understand and undertake that you shall be solely
                  responsible for the Registered Data and undertake to not host,
                  display, upload, modify, publish, transmit, update or share
                  any information that:
                  <br></br>
                  <br></br>
                  1. belongs to another person and to which you do not have any
                  right to;
                  <br></br>2. is grossly harmful, harassing, blasphemous,
                  defamatory, obscene, pornographic, pedophilic, seditious,
                  libelous, invasive of another's privacy, hateful, or racially,
                  ethnically objectionable, disparaging, relating or encouraging
                  money laundering or gambling, or otherwise unlawful in any
                  manner whatever;
                  <br></br>3. harms minors in any way;
                  <br></br>4. infringes any patent, trademark, copyright or
                  other proprietary rights of any person anywhere in the world;
                  <br></br>5. violates any law for the time being in force;
                  <br></br>6. deceives or misleads the addressee about the
                  origin of such messages or communicates any information which
                  is grossly offensive or menacing in nature;
                  <br></br>7. impersonates another person;
                  <br></br>8. contains software viruses or any other computer
                  code, files or programs designed to interrupt, destroy or
                  limit the functionality of any computer resource;
                  <br></br>9. threatens the unity, integrity, defence, security
                  or sovereignty of India, friendly relations with foreign
                  states, or public order or causes incitement to the commission
                  of any cognizable offence or prevents investigation of any
                  offence or is insulting to any other nation;
                  <br></br>10. creates liability for Omaza or causes Omaza to
                  lose (in whole or in part) the services of Omaza’s ISPs or
                  other service providers;
                  <br></br>11. is in the nature of political campaigning,
                  unsolicited or unauthorized advertising, promotional and/ or
                  commercial solicitation, chain letters, pyramid schemes, mass
                  mailings and/or any form of ‘spam’ or solicitation; or
                  <br></br>12. is illegal in any other way.
                  <br></br>
                  <br></br>
                  The User understands and agrees that the Omaza team reserves
                  the right to remove and/or edit such detail / information.
                  <br></br>
                  <br></br>
                  If you do post content or submit material on Omaza, and unless
                  we indicate otherwise to you in writing, it shall be deemed
                  that you
                  <br></br>
                  <br></br>
                  1. grant Omaza a non-exclusive, royalty-free, irrevocable,
                  perpetual and fully sub-licensable rights to use, reproduce,
                  modify, adapt, publish, translate, create derivative works
                  from, distribute, and display such content throughout the
                  world in any media;
                  <br></br>2. grant Omaza the right to use the name that you
                  submit in connection with such content, if they choose; and
                  <br></br>3.agree that the rights you grant above are
                  irrevocable during the entire period of protection of your
                  intellectual property rights associated with such content and
                  material. You agree to waive your right to be identified as
                  the author of such content and your right to object to any
                  usage or derogatory treatment of such content. You agree to
                  perform all further acts necessary to perfect any of the above
                  rights granted by you to Omaza.
                  <br></br>
                  <br></br>
                  You represent and warrant that you own or otherwise control
                  all the rights to the content that you post or that you
                  otherwise provide on or through Omaza; that, as at the date
                  that the content or material is submitted to Omaza:
                  <br></br>
                  <br></br>
                  1. the content and material is accurate;
                  <br></br>2. the use of the content and material supplied by
                  you does not breach any applicable policies of Omaza or any
                  guidelines herein and will not cause injury to any person or
                  entity (including that the content or material is not
                  defamatory);
                  <br></br>3. the content is lawful and does not violate any
                  applicable laws.
                  <br></br>
                  <br></br>
                  You further agree to indemnify Omaza for all claims brought by
                  third parties against Omaza arising out of or in connection
                  with a breach of any of the representations and warranties
                  provided hereinabove.
                  <br></br>
                  <br></br>
                </div>
                <div className="_sectionTitle_f2rzd_80">
                  10. Complaint against Objectionable Content
                </div>
                <div className="_description_f2rzd_87">
                  If you believe that any content on Omaza is illegal, offensive
                  (including but not limited to material that has sexually
                  explicit content or which promotes racism, bigotry, hatred or
                  physical harm etc.), deceptive, misleading, abusive, indecent,
                  harassing, blasphemous, defamatory, libellous, obscene,
                  pornographic, paedophilic or menacing; ethnically
                  objectionable, disparaging; or is otherwise injurious to third
                  parties; or relates to or promotes money laundering or
                  gambling; or is harmful to minors in any way; or impersonates
                  another person; or threatens the unity, integrity, security or
                  sovereignty of India or friendly relations with foreign
                  States; or objectionable or otherwise unlawful in any manner
                  whatsoever; or which consists of or contains software viruses,
                  (“Objectionable Content”), please notify us immediately on
                  support@omaza.in. We will take reasonable steps to ensure that
                  such Objectionable Content complained about is reviewed and
                  removed (if required) within a reasonable time.
                  <br></br>
                  <br></br>
                </div>
                <div className="_sectionTitle_f2rzd_80">
                  11. Intellectual Property Rights
                </div>
                <div className="_description_f2rzd_87">
                  Omaza respects the intellectual property of others. If you
                  believe that your intellectual property rights have been used
                  in a way that gives rise to concerns of infringement, please
                  bring it to our notice by sending an email at
                  support@omaza.in. We will envisage to address your concerns
                  and take necessary steps, if required.
                  <br></br>
                  <br></br>
                </div>
                <div className="_sectionTitle_f2rzd_80">
                  13. Alteration of Service or Amendments to the Conditions
                </div>
                <div className="_description_f2rzd_87">
                  We reserve the right to make changes to Omaza, its policies
                  and these Terms of Use at any time. You will be subject to the
                  policies and Terms of Use in force at the time that you use
                  Omaza. If any of these terms are deemed invalid, void, or for
                  any reason unenforceable, that term will be deemed severable
                  and will not affect the validity and enforceability of any
                  remaining condition of this Terms of Use.
                  <br></br>
                  <br></br>
                </div>
                <div className="_sectionTitle_f2rzd_80">14. Waiver</div>
                <div className="_description_f2rzd_87">
                  Neither failure nor delay on the part of Omaza to exercise or
                  enforce any right, remedy, power or privilege contained
                  hereunder nor course of dealing between the parties shall
                  operate as a waiver thereof, or of the exercise of any other
                  right, remedy, power or privilege. No part of these Terms of
                  Use shall be deemed waived, and no breach consented to, unless
                  such waiver or consent shall be in writing and signed by the
                  party claimed to have waived or consented. No waiver of any
                  rights or consent to any breaches shall constitute a waiver of
                  any other rights or consent to any other breach.
                  <br></br>
                  <br></br>
                </div>
                <div className="_sectionTitle_f2rzd_80">
                  15. Limitation of Liability
                </div>
                <div className="_description_f2rzd_87">
                  In no event shall Omaza be liable for any special, incidental,
                  indirect or consequential damages of any kind in connection
                  with these Terms of Use, even if the User has been informed in
                  advance of the possibility of such damages.
                  <br></br>
                  <br></br>
                </div>
                <div className="_sectionTitle_f2rzd_80">
                  16. Refund & Cancellation Policy
                </div>
                <div className="_description_f2rzd_87">
                  Please note an order can only be cancelled within 24 hours of
                  placing the order. Once the order is processed after 24 hours,
                  no cancellation request will be entertained. Orders cancelled
                  within 24 hours shall be eligible for full refund.
                  <br></br>
                  <br></br>
                </div>
                <div className="_sectionTitle_f2rzd_80">17. Pricing Policy</div>
                <div className="_description_f2rzd_87">
                  We are committed to providing our partners - both producers
                  and retailers - with a fair, transparent, and dynamic pricing
                  structure. Our aim is to balance the sustainability of our
                  platform with the needs of our community, and we believe our
                  pricing policy achieves this. Here's how our pricing works:
                  <br></br>
                  <br></br>
                  a. Listing Fee
                  <br></br>
                  <br></br>
                  Producers wishing to list their commodities on our platform
                  will be charged a listing fee. This fee varies based on the
                  type and quantity of the commodity.
                  <br></br>
                  <br></br>
                  b. Premium Listings
                  <br></br>
                  <br></br>
                  Producers have the option to avail of premium listing
                  services, which provide higher visibility for their products
                  on our platform for an additional cost.
                  <br></br>
                  <br></br>
                  c. Service Charges
                  <br></br>
                  <br></br>
                  We offer value-added services like quality assurance, storage,
                  logistics, and expedited delivery, available at reasonable
                  additional costs.
                  <br></br>
                  <br></br>
                  d. Volume Discounts
                  <br></br>
                  <br></br>
                  Retailers ordering in large quantities are eligible for
                  discounts. This is our way of acknowledging and appreciating
                  our partners who contribute significantly to our business.
                  <br></br>
                  <br></br>
                  e. Early Payment Discounts
                  <br></br>
                  <br></br>
                  We value punctuality and hence, we reward early payments with
                  discounts. Conversely, late payments might attract additional
                  charges.
                  <br></br>
                  <br></br>
                  f. Seasonal Pricing
                  <br></br>
                  <br></br>
                  Given the seasonal nature of agri commodities, we adjust our
                  prices to reflect their availability in different seasons. You
                  might notice higher prices during off-seasons and lower prices
                  during peak seasons.
                  <br></br>
                  <br></br>
                  g. Market-based Pricing
                  <br></br>
                  <br></br>
                  Our prices are frequently updated to align with current market
                  rates, ensuring our platform remains competitive.
                  <br></br>
                  <br></br>
                  e. Dynamic Pricing
                  <br></br>
                  <br></br>
                  For high-demand commodities, we utilise a dynamic pricing
                  model. As supply decreases, prices might increase. This is a
                  strategy designed to balance supply and demand during peak
                  times.
                  <br></br>
                  <br></br>
                  We appreciate your understanding of this pricing policy, as it
                  helps us ensure the continuity of our services. We highly
                  recommend that you review this policy regularly, as we reserve
                  the right to update it based on market trends, user feedback,
                  and our platform's financial performance. Thank you for your
                  continued partnership with Omaza Pvt Ltd.
                  <br></br>
                  <br></br>
                </div>
                <div className="_sectionTitle_f2rzd_80">18. Disclaimer</div>
                <div className="_description_f2rzd_87">
                  a. General
                  <br></br>
                  <br></br>
                  You acknowledge and undertake that you are visiting and
                  accessing the Services on the mobile application and website,
                  as the case may be, (“Omaza Platform”) and transacting at your
                  own risk and are using your best and prudent judgment before
                  entering into any transactions through the Omaza Platform. We
                  shall neither be liable nor responsible for any actions or
                  inactions of sellers nor any breach of conditions,
                  representations or warranties by the sellers or manufacturers
                  of the products and hereby expressly disclaim and any all
                  responsibility and liability in that regard. We shall not
                  mediate or resolve any dispute or disagreement between you and
                  the sellers or manufacturers of the products.
                  <br></br>
                  <br></br>
                  b. No Warranties
                  <br></br>
                  <br></br>
                  We expressly disclaim any warranties or representations
                  (express or implied) in respect of quality, suitability,
                  accuracy, reliability, completeness, timeliness, performance,
                  safety, merchantability, fitness for a particular purpose, or
                  legality of the products listed or displayed or transacted or
                  the content (including product or pricing information and/or
                  specifications) on the Omaza Platform. While we have taken
                  precautions to avoid inaccuracies in content of Omaza
                  Platform, all content, information (including the price of
                  products), software, products, services and related graphics
                  are provided as is, without warranty of any kind.
                  <br></br>
                  <br></br>
                  Without prejudice to the generality of the foregoing
                  paragraph, we do not warrant that:
                  <br></br>
                  <br></br>
                  1. the Omaza Platform will be constantly available, or
                  available at all; or
                  <br></br>2. the information on the Omaza Platform is complete,
                  true, accurate or not misleading.
                  <br></br>
                  <br></br>
                  Nothing on the Omaza Platform constitutes, or is meant to
                  constitute, advice of any kind. While we strive to ensure that
                  the information contained in the Omaza Platform is accurate
                  and reliable, we make no warranties or representations as to
                  the accuracy, correctness, reliability or otherwise with
                  respect to such information, and we assume no liability or
                  responsibility for any omission or error in the content of the
                  Omaza Platform. The information and materials contained on the
                  Omaza Platform are subject to change without notice and are
                  provided for general information only and should not be used
                  as a basis for making business or financial decisions.
                  <br></br>
                  <br></br>
                  Despite our best efforts to provide accurate information on
                  the Omaza Platform, it is not possible to ensure that all the
                  information provided here is up to date. We shall not be
                  liable for any unintentional inaccuracies or errors with
                  respect to the description of any product. Further, no
                  liability shall be imposed on us, in the event of any damage,
                  error, injury or loss caused to any person or User, whether
                  direct or indirect, caused to him due to use or inability to
                  use the Omaza Platform or any links or hyperlinks provided on
                  the Omaza Platform. The usage of any link or hyperlink by any
                  visitor will be at his/ her own risk and we assume no
                  liability for any damage caused. We recommend that the users
                  read the terms and conditions of such link(s).
                  <br></br>
                  <br></br>
                  The contents, designs, materials, etc. available on this Omaza
                  Platform may be protected by copyright law. You may not
                  otherwise change, reproduce, modify, distribute, publicly
                  display the materials available on the Omaza Platform in any
                  way, unless authorized by us or the respective copyright
                  owner(s).
                  <br></br>
                  <br></br>
                  c. Limitations of Liability
                  <br></br>
                  <br></br>
                  We will not be liable to you (whether under the law of
                  contact, the law of torts or otherwise) in relation to the
                  contents of, or use of, or otherwise in connection with, this
                  Omaza Platform:
                  <br></br>
                  <br></br>
                  1. to the extent that the Omaza Platform is provided
                  free-of-charge, for any direct loss;
                  <br></br>2. for any indirect, special or consequential loss;
                  <br></br>3. for any business losses, loss of revenue, income,
                  profits or anticipated savings, loss of contracts or business
                  relationships, loss of reputation or goodwill, or loss or
                  corruption of information or data;
                  <br></br>4. for any errors in or omissions on this Omaza
                  Platform and the content therein, including but not limited to
                  technical inaccuracies and typographical errors;
                  <br></br>5. for any third-party websites or content therein
                  directly or indirectly accessed through links in this Omaza
                  Platform, including but not limited to any errors in or
                  omissions therefrom;
                  <br></br>6. for your use of this Omaza Platform; or
                  <br></br>7. for your use of any equipment or software in
                  connection with the Omaza Platform.
                  <br></br>
                  <br></br>These limitations of liability apply even if we have
                  been expressly advised of the potential loss.
                  <br></br>
                  <br></br>
                </div>
                <div className="_sectionTitle_f2rzd_80">
                  19. Governing law and Jurisdiction
                </div>
                <div className="_description_f2rzd_87">
                  These Terms of Use shall be governed by and construed in
                  accordance with the laws of India (“applicable laws” or
                  “laws”). The courts at Bengaluru, India shall have exclusive
                  jurisdiction in relation to any proceedings arising out of or
                  in connection with these Terms of Use.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsofUse;
