// Imports
import {
  AlertOutlined,
  ApiOutlined,
  AppstoreAddOutlined,
  AreaChartOutlined,
  ArrowRightOutlined,
  AuditOutlined,
  BarChartOutlined,
  BugOutlined,
  BuildOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  CloudOutlined,
  CloudServerOutlined,
  ClusterOutlined,
  CodeOutlined,
  CommentOutlined,
  ControlOutlined,
  CustomerServiceOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  DeploymentUnitOutlined,
  DesktopOutlined,
  EditOutlined,
  ExperimentOutlined,
  EyeOutlined,
  FileImageOutlined,
  FileTextOutlined,
  FontColorsOutlined,
  FormOutlined,
  FundProjectionScreenOutlined,
  FundViewOutlined,
  GitlabOutlined,
  GlobalOutlined,
  HighlightOutlined,
  InteractionOutlined,
  LaptopOutlined,
  LayoutOutlined,
  LineChartOutlined,
  LockOutlined,
  MailOutlined,
  MobileOutlined,
  PicCenterOutlined,
  PlayCircleOutlined,
  ProfileOutlined,
  ProjectOutlined,
  RadarChartOutlined,
  RetweetOutlined,
  RobotOutlined,
  RocketOutlined,
  SafetyCertificateOutlined,
  SearchOutlined,
  SettingOutlined,
  SnippetsOutlined,
  SolutionOutlined,
  TeamOutlined,
  ThunderboltOutlined,
  ToolOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import banner from "../../assets/img/webDevelopmentBanner-969fc253.png";
import {
  FaAppStore,
  FaBrain,
  FaBug,
  FaChartBar,
  FaChartLine,
  FaChartPie,
  FaCheckCircle,
  FaClipboardCheck,
  FaCloud,
  FaCode,
  FaCog,
  FaCogs,
  FaCubes,
  FaDatabase,
  FaDesktop,
  FaDraftingCompass,
  FaExclamationTriangle,
  FaFacebook,
  FaGlobe,
  FaGooglePlay,
  FaHandsHelping,
  FaHeadset,
  FaImage,
  FaLanguage,
  FaLaptop,
  FaLaptopCode,
  FaLock,
  FaMobileAlt,
  FaNetworkWired,
  FaPaintBrush,
  FaPalette,
  FaPenFancy,
  FaPlug,
  FaProjectDiagram,
  FaRedo,
  FaRobot,
  FaRocket,
  FaSearch,
  FaServer,
  FaShieldAlt,
  FaSitemap,
  FaSync,
  FaSyncAlt,
  FaTachometerAlt,
  FaTasks,
  FaThumbsUp,
  FaTools,
  FaUserAlt,
  FaUserCheck,
  FaUsers,
  FaVial,
  FaWrench,
} from "react-icons/fa";
import { FaMeta } from "react-icons/fa6";
import { SiGoogleads } from "react-icons/si";

import web_development from "../../assets/img/home/web-development.jpg";
import mobile_development from "../../assets/img/home/mobile-development.webp";
import UI from "../../assets/img/home/ui-ux.jpeg";
import generative_ai from "../../assets/img/home/generative-ai.webp";
import deveops from "../../assets/img/home/deveops.webp";
import qualti_enginner from "../../assets/img/home/quality-engineer.jpg";
import data_science from "../../assets/img/home/data-science.webp";
import low_Code from "../../assets/img/home/low-code.webp";
import cyber_security from "../../assets/img/home/cyber-security.webp";
import software from "../../assets/img/home/software-devlopment.jpeg";
import testing_QA from "../../assets/img/home/testing.jpeg";
import application_service from "../../assets/img/home/mobile-development.webp";
import help_desk_service from "../../assets/img/home/helpdesk.webp";
export const ServicesConfig = {
  web_development: {
    heroSection: {
      backgroundImage: web_development,
      backgroundPosition: "center bottom",
      title: "Empowering Your Success with Premier Web Solutions!",
      description:
        "Enhance your digital presence with our customized web development services.",
      buttons: [
        {
          text: "Get in Touch",
          icon: <MailOutlined />,
        },
      ],
    },
    servicesSection: {
      title: "Our Expertise",
      description:
        "We deliver tailored web development services. From innovative front-end and back-end solutions to intuitive design, we create a seamless digital experience.",
      services: [
        {
          title: "Front-end Development",
          description:
            "Our front-end services transform your product into a user-friendly interface with visually appealing and interactive designs, enhancing user engagement and satisfaction.",
          icon: <DesktopOutlined />,
        },
        {
          title: "Back-end Solutions",
          description:
            "We build robust and scalable back-end systems using cutting-edge technologies, ensuring high performance, security, and efficient data management.",
          icon: <DatabaseOutlined />,
        },
        {
          title: "Cloud Integration",
          description:
            "Our cloud services offer scalability and flexibility, optimizing your product's infrastructure for growth and success.",
          icon: <CloudOutlined />,
        },
        {
          title: "Responsive Design",
          description:
            "We develop responsive websites that adapt seamlessly to various screen sizes, providing an optimal user experience on all devices.",
          icon: <MobileOutlined />,
        },
        {
          title: "Cross-Browser Compatibility",
          description:
            "We ensure your website functions flawlessly across different browsers, offering a consistent and reliable experience for all users.",
          icon: <GlobalOutlined />,
        },
        {
          title: "Performance Enhancement",
          description:
            "Our team optimizes your website's performance for fast load times and smooth navigation, enhancing overall user satisfaction.",
          icon: <RocketOutlined />,
        },
        {
          title: "MVP Development",
          description:
            "We quickly bring your product vision to life, allowing you to validate ideas, gather user feedback, and iterate efficiently.",
          icon: <AppstoreAddOutlined />,
        },
      ],
    },
    howWeWorkSection: {
      title: "Our Process",
      description:
        "Discover how we collaborate with our clients to deliver exceptional results.",
      steps: [
        {
          title: "Research and Planning",
          description:
            "We start by understanding your business goals, target audience, and project requirements, then develop a strategic plan to guide the project.",
          icon: <SearchOutlined />,
        },
        {
          title: "Design and Mockups",
          description:
            "Our design team creates intuitive and visually appealing mockups, focusing on user experience and aligning with your brand identity.",
          icon: <HighlightOutlined />,
        },
        {
          title: "Development",
          description:
            "We transform designs into fully functional products using the latest technologies, ensuring high-quality code and seamless performance.",
          icon: <CodeOutlined />,
        },
        {
          title: "Testing and QA",
          description:
            "We conduct comprehensive testing to identify and resolve any issues, ensuring the product meets high standards of quality and performance.",
          icon: <BugOutlined />,
        },
        {
          title: "Launch and Deployment",
          description:
            "We manage the deployment process for a smooth launch, providing support during the initial phase to ensure a successful rollout.",
          icon: <RocketOutlined />,
        },
        {
          title: "Ongoing Support",
          description:
            "After launch, we offer maintenance and support services to keep your product updated and running smoothly, with regular improvements based on feedback.",
          icon: <ToolOutlined />,
        },
      ],
    },
    expertise: [
      {
        title: "Front-end Development",
        description:
          "Crafting user-friendly interfaces with visually appealing designs to enhance user engagement.",
        icon: <FaDesktop style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Back-end Solutions",
        description:
          "Building robust and scalable server-side applications for efficient data management and security.",
        icon: <FaDatabase style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Cloud Integration",
        description:
          "Implementing cloud services to optimize scalability, real-time data syncing, and infrastructure management.",
        icon: <FaCloud style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Responsive Design",
        description:
          "Developing websites that adapt seamlessly to various devices, ensuring a consistent user experience.",
        icon: <FaMobileAlt style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Cross-Browser Compatibility",
        description:
          "Ensuring websites function smoothly across different browsers for a reliable user experience.",
        icon: <FaGlobe style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Performance Optimization",
        description:
          "Optimizing website performance for fast load times and efficient navigation.",
        icon: <FaRocket style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "MVP Development",
        description:
          "Quickly prototyping and launching minimum viable products to validate ideas and gather feedback.",
        icon: <FaAppStore style={{ width: "32px", height: "32px" }} />,
      },
    ],
  },
  mobile_development: {
    heroSection: {
      backgroundImage: mobile_development,
      backgroundPosition: "center bottom",
      title: "Transforming Ideas into Mobile Experiences!",
      description:
        "Explore our mobile development services designed to create innovative and user-centric mobile applications.",
      buttons: [
        {
          text: "Get Started",
          icon: <AppstoreAddOutlined />,
        },
      ],
    },
    servicesSection: {
      title: "Our Mobile Expertise",
      description:
        "We specialize in crafting mobile solutions that drive engagement and deliver exceptional user experiences across various platforms.",
      services: [
        {
          title: "iOS App Development",
          description:
            "We develop feature-rich iOS applications tailored to your business needs, ensuring seamless performance and intuitive user interfaces.",
          icon: <MobileOutlined />,
        },
        {
          title: "Android App Development",
          description:
            "Our Android development services focus on creating scalable and robust applications that leverage the full potential of the Android platform.",
          icon: <MobileOutlined />,
        },
        {
          title: "Cross-Platform Development",
          description:
            "We utilize cross-platform frameworks to build applications that run smoothly on multiple platforms, maximizing reach and efficiency.",
          icon: <GlobalOutlined />,
        },
        {
          title: "Cloud Integration for Mobile",
          description:
            "Integrate cloud services into your mobile apps for enhanced scalability, real-time data syncing, and seamless user experiences across devices.",
          icon: <CloudOutlined />,
        },
        {
          title: "Performance Optimization",
          description:
            "We optimize mobile app performance for fast loading times, smooth navigation, and superior user satisfaction.",
          icon: <RocketOutlined />,
        },
      ],
    },
    howWeWorkSection: {
      title: "Our Mobile Development Process",
      description:
        "Learn about our streamlined process to deliver high-quality mobile applications that meet your business objectives.",
      steps: [
        {
          title: "Concept and Planning",
          description:
            "We collaborate to define your app's goals, target audience, and key features, creating a comprehensive plan to guide development.",
          icon: <SearchOutlined />,
        },
        {
          title: "UI/UX Design",
          description:
            "Our designers create intuitive and visually appealing interfaces, focusing on user experience and brand consistency.",
          icon: <HighlightOutlined />,
        },
        {
          title: "Development and Iteration",
          description:
            "Our developers build your app using agile methodologies, ensuring iterative development and continuous improvement.",
          icon: <CodeOutlined />,
        },
        {
          title: "Testing and Quality Assurance",
          description:
            "We conduct rigorous testing to identify and resolve bugs, ensuring your app meets high standards of quality and performance.",
          icon: <BugOutlined />,
        },
        {
          title: "Deployment and Launch",
          description:
            "We manage the deployment process for a smooth launch, providing support during the initial rollout phase.",
          icon: <RocketOutlined />,
        },
        {
          title: "Maintenance and Support",
          description:
            "Post-launch, we offer ongoing maintenance and support to keep your app updated, secure, and aligned with evolving user needs.",
          icon: <ToolOutlined />,
        },
      ],
    },
    expertise: [
      {
        title: "iOS App Development",
        description:
          "Developing feature-rich iOS applications tailored to your business needs, ensuring seamless performance and intuitive user interfaces.",
        icon: <MobileOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Android App Development",
        description:
          "Creating scalable and robust Android applications that leverage the full potential of the Android platform.",
        icon: <MobileOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Cross-Platform Development",
        description:
          "Utilizing cross-platform frameworks to build applications that run smoothly on multiple platforms, maximizing reach and efficiency.",
        icon: <GlobalOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Cloud Integration for Mobile",
        description:
          "Integrating cloud services into mobile apps for enhanced scalability, real-time data syncing, and seamless user experiences.",
        icon: <CloudOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Performance Optimization",
        description:
          "Optimizing mobile app performance for fast loading times, smooth navigation, and superior user satisfaction.",
        icon: <RocketOutlined style={{ width: "32px", height: "32px" }} />,
      },
    ],
  },
  ux_design: {
    heroSection: {
      backgroundImage: UI,
      backgroundPosition: "center bottom",
      title: "Crafting Intuitive User Experiences",
      description:
        "Explore our UX design services aimed at creating engaging and user-centric interfaces that enhance usability and satisfaction.",
      buttons: [
        {
          text: "Get Started",
          icon: <EditOutlined />,
        },
      ],
    },
    servicesSection: {
      title: "Our UX Design Services",
      description:
        "Discover how our UX design experts can transform your ideas into seamless digital experiences that captivate users.",
      services: [
        {
          title: "User Research and Analysis",
          description:
            "We conduct in-depth research to understand user behaviors and preferences, informing design decisions and improving usability.",
          icon: <RadarChartOutlined />,
        },
        {
          title: "Wireframing and Prototyping",
          description:
            "We create wireframes and interactive prototypes to visualize and test design concepts, ensuring intuitive and functional interfaces.",
          icon: <SnippetsOutlined />,
        },
        {
          title: "Information Architecture",
          description:
            "We structure information effectively to enhance findability and navigation within digital products, improving user satisfaction.",
          icon: <LayoutOutlined />,
        },
        {
          title: "Interaction Design",
          description:
            "Our designers focus on creating meaningful interactions that guide users through seamless and enjoyable experiences.",
          icon: <HighlightOutlined />,
        },
        {
          title: "Visual Design",
          description:
            "We design visually appealing interfaces that align with your brand identity while prioritizing clarity and aesthetics.",
          icon: <ClusterOutlined />,
        },
      ],
    },
    howWeWorkSection: {
      title: "Our UX Design Process",
      description:
        "Learn about our systematic approach to delivering effective UX design solutions that meet business objectives and user needs.",
      steps: [
        {
          title: "Research and Discovery",
          description:
            "We gather insights through user research and competitive analysis to understand user needs and project requirements.",
          icon: <RadarChartOutlined />,
        },
        {
          title: "Conceptualization",
          description:
            "Our team conceptualizes design ideas, creating wireframes and prototypes to visualize and refine UX solutions.",
          icon: <SnippetsOutlined />,
        },
        {
          title: "Design Development",
          description:
            "We develop detailed designs, focusing on interaction flows, visual aesthetics, and usability to create intuitive interfaces.",
          icon: <EditOutlined />,
        },
        {
          title: "Testing and Validation",
          description:
            "We conduct usability testing and gather feedback to iterate on designs, ensuring they meet usability and accessibility standards.",
          icon: <LineChartOutlined />,
        },
        {
          title: "Implementation and Support",
          description:
            "We assist with the implementation of UX designs, providing support to ensure seamless integration and user adoption.",
          icon: <ToolOutlined />,
        },
      ],
    },
    expertise: [
      {
        title: "User Research",
        description:
          "Conducting thorough user research to understand user needs and preferences.",
        icon: <FaUserAlt style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Wireframing and Prototyping",
        description:
          "Creating wireframes and prototypes to visualize the structure and flow of the user interface.",
        icon: <FaProjectDiagram style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "UI Design",
        description:
          "Designing visually appealing and user-friendly interfaces that enhance user experience.",
        icon: <FaPaintBrush style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Usability Testing",
        description:
          "Conducting usability tests to identify and fix issues, ensuring a seamless user experience.",
        icon: <FaVial style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Interaction Design",
        description:
          "Designing interactive elements to create engaging and intuitive user experiences.",
        icon: <FaHandsHelping style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Information Architecture",
        description:
          "Organizing and structuring content effectively to improve navigation and usability.",
        icon: <FaSitemap style={{ width: "32px", height: "32px" }} />,
      },
    ],
  },
  generative_ai: {
    heroSection: {
      backgroundImage: generative_ai,
      backgroundPosition: "center bottom",
      title: "Unlocking Creativity with Generative AI",
      description:
        "Explore the potential of Generative AI to generate innovative content, automate processes, and drive creative solutions.",
      buttons: [
        {
          text: "Get Started",
          icon: <EditOutlined />,
        },
      ],
    },
    servicesSection: {
      title: "Applications of Generative AI",
      description:
        "Discover how Generative AI is revolutionizing various industries by automating tasks, creating art, generating text, and more.",
      services: [
        {
          title: "Art Generation",
          description:
            "Generative AI algorithms create artwork, digital paintings, and graphics based on predefined styles or learned patterns.",
          icon: <PicCenterOutlined />,
        },
        {
          title: "Text Generation",
          description:
            "Natural Language Processing models generate human-like text, including articles, poetry, and creative writing.",
          icon: <FontColorsOutlined />,
        },
        {
          title: "Music Composition",
          description:
            "Generative models compose music and generate melodies, harmonies, and entire compositions based on input data or styles.",
          icon: <CustomerServiceOutlined />,
        },
        {
          title: "Image Synthesis",
          description:
            "AI generates realistic images and visual content, including faces, landscapes, and scenes, often from textual descriptions.",
          icon: <FileImageOutlined />,
        },
      ],
    },
    howWeWorkSection: {
      title: "Our Generative AI Development Process",
      description:
        "Explore our methodical approach to developing advanced Generative AI solutions tailored to specific industry needs.",
      steps: [
        {
          title: "Problem Definition",
          description:
            "We define the problem statement and project goals, identifying key metrics for success in the application of Generative AI.",
          icon: <FundProjectionScreenOutlined />,
        },
        {
          title: "Data Collection and Preparation",
          description:
            "We gather and preprocess relevant datasets, ensuring data quality and compatibility for training Generative AI models.",
          icon: <DatabaseOutlined />,
        },
        {
          title: "Model Selection and Architecture Design",
          description:
            "Our team selects appropriate Generative AI models and designs architectures optimized for specific tasks, considering computational efficiency and performance.",
          icon: <ClusterOutlined />,
        },
        {
          title: "Training and Optimization",
          description:
            "We train Generative AI models using advanced algorithms and techniques, optimizing parameters to enhance model performance and accuracy.",
          icon: <RobotOutlined />,
        },
        {
          title: "Evaluation and Iteration",
          description:
            "We evaluate model outputs, iterate on designs, and refine algorithms based on feedback and performance metrics to achieve desired outcomes.",
          icon: <ExperimentOutlined />,
        },
        {
          title: "Deployment and Maintenance",
          description:
            "We deploy Generative AI solutions into production environments, providing ongoing maintenance and support to ensure sustained performance and scalability.",
          icon: <DeploymentUnitOutlined />,
        },
      ],
    },
    expertise: [
      {
        title: "Natural Language Processing",
        description:
          "Building AI models that understand and generate human language.",
        icon: <FaLanguage style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "AI-Powered Content Creation",
        description:
          "Creating high-quality content using advanced generative AI techniques.",
        icon: <FaPenFancy style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Image and Video Generation",
        description:
          "Generating realistic images and videos using generative adversarial networks (GANs).",
        icon: <FaImage style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Automated Design",
        description:
          "Using AI to automate the design process, creating unique and creative designs.",
        icon: <FaRobot style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Predictive Analytics",
        description:
          "Leveraging AI to analyze data and make accurate predictions for various applications.",
        icon: <FaChartLine style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Personalized Recommendations",
        description:
          "Providing personalized recommendations using AI-driven algorithms.",
        icon: <FaThumbsUp style={{ width: "32px", height: "32px" }} />,
      },
    ],
  },
  devops_service: {
    heroSection: {
      backgroundImage: deveops,
      backgroundPosition: "center bottom",
      title: "Transforming Development with DevOps Services",
      description:
        "Explore how DevOps practices streamline software development, improve collaboration, and accelerate deployment cycles.",
      buttons: [
        {
          text: "Learn More",
          icon: <ArrowRightOutlined />,
        },
      ],
    },
    servicesSection: {
      title: "Our DevOps Services",
      description:
        "Discover our comprehensive range of DevOps services tailored to optimize your development lifecycle, enhance reliability, and ensure continuous integration and delivery.",
      services: [
        {
          title: "Continuous Integration and Deployment (CI/CD)",
          description:
            "Automate build, test, and deployment processes to deliver code changes reliably and frequently, ensuring rapid and iterative software releases.",
          icon: <ToolOutlined />,
        },
        {
          title: "Infrastructure as Code (IaC)",
          description:
            "Manage and provision infrastructure using code, enabling consistent and repeatable deployment of infrastructure components and configurations.",
          icon: <CodeOutlined />,
        },
        {
          title: "Monitoring and Logging",
          description:
            "Implement monitoring and logging solutions to gain visibility into application and infrastructure performance, enabling proactive issue resolution.",
          icon: <FundViewOutlined />,
        },
        {
          title: "Configuration Management",
          description:
            "Automate configuration and maintenance of servers and infrastructure to ensure consistency, security, and scalability.",
          icon: <DatabaseOutlined />,
        },
        {
          title: "Containerization and Orchestration",
          description:
            "Utilize container technology (e.g., Docker) and orchestration tools (e.g., Kubernetes) to package, deploy, and manage applications efficiently across environments.",
          icon: <CloudServerOutlined />,
        },
      ],
    },

    howWeWorkSection: {
      title: "Our DevOps Services Approach",
      description:
        "Discover our methodical approach to implementing DevOps practices, ensuring efficiency, collaboration, and continuous delivery in software development.",
      steps: [
        {
          title: "Planning and Analysis",
          description:
            "We analyze existing workflows and project requirements, identifying opportunities for automation and process improvement.",
          icon: <ProjectOutlined />,
        },
        {
          title: "Infrastructure as Code",
          description:
            "We define and manage infrastructure using code, leveraging tools like Terraform and CloudFormation for scalable and reproducible environments.",
          icon: <CodeOutlined />,
        },
        {
          title: "Continuous Integration and Deployment",
          description:
            "We set up CI/CD pipelines to automate testing, build, and deployment processes, ensuring rapid and reliable software delivery.",
          icon: <GitlabOutlined />,
        },
        {
          title: "Monitoring and Logging",
          description:
            "We implement monitoring solutions and logging frameworks to track performance metrics, detect issues, and optimize system reliability.",
          icon: <DashboardOutlined />,
        },
        {
          title: "Security and Compliance",
          description:
            "We integrate security best practices and compliance checks throughout the development lifecycle, ensuring robust protection and regulatory adherence.",
          icon: <SafetyCertificateOutlined />,
        },
        {
          title: "Continuous Improvement",
          description:
            "We foster a culture of continuous improvement, conducting post-mortems and feedback loops to refine processes and enhance team collaboration.",
          icon: <ThunderboltOutlined />,
        },
      ],
    },
    expertise: [
      {
        title: "Continuous Integration/Continuous Deployment",
        description:
          "Implementing CI/CD pipelines to automate the software development lifecycle.",
        icon: <FaSync style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Infrastructure as Code",
        description:
          "Using IaC to manage and provision infrastructure through code, ensuring consistency and efficiency.",
        icon: <FaCode style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Monitoring and Logging",
        description:
          "Setting up monitoring and logging solutions to ensure system reliability and performance.",
        icon: <FaChartBar style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Automated Testing",
        description:
          "Integrating automated testing to identify issues early and ensure high-quality software releases.",
        icon: <FaBug style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Containerization",
        description:
          "Using container technologies like Docker and Kubernetes for application deployment and management.",
        icon: <FaCubes style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Cloud Services",
        description:
          "Providing cloud-based solutions for scalable and flexible infrastructure management.",
        icon: <FaCloud style={{ width: "32px", height: "32px" }} />,
      },
    ],
  },
  quality_engineering: {
    heroSection: {
      backgroundImage: qualti_enginner,
      backgroundPosition: "center bottom",
      title: "Enhancing Software Quality with Quality Engineering",
      description:
        "Discover how Quality Engineering practices ensure superior software quality, reliability, and performance throughout the development lifecycle.",
      buttons: [
        {
          text: "Learn More",
          icon: <ArrowRightOutlined />,
        },
      ],
    },
    servicesSection: {
      title: "Our Quality Engineering Services",
      description:
        "Explore our comprehensive range of Quality Engineering services designed to identify defects early, automate testing processes, and ensure optimal software performance and user experience.",
      services: [
        {
          title: "Test Automation",
          description:
            "Implement automated testing solutions to accelerate testing cycles, improve test coverage, and detect regressions early in the development process.",
          icon: <RobotOutlined />,
        },
        {
          title: "Performance Testing",
          description:
            "Conduct performance tests to evaluate system responsiveness, scalability, and stability under varying workloads, ensuring optimal performance and reliability.",
          icon: <DashboardOutlined />,
        },
        {
          title: "Security Testing",
          description:
            "Identify vulnerabilities and security risks within software applications through comprehensive security testing measures, ensuring data protection and compliance.",
          icon: <SafetyCertificateOutlined />,
        },
        {
          title: "Accessibility Testing",
          description:
            "Assess software accessibility to ensure usability for users with disabilities, complying with accessibility standards and enhancing user inclusivity.",
          icon: <AuditOutlined />,
        },
        {
          title: "Usability Testing",
          description:
            "Evaluate user interface design and user experience (UX) to optimize usability, navigation, and overall satisfaction with software applications.",
          icon: <InteractionOutlined />,
        },
      ],
    },
    howWeWorkSection: {
      title: "Our Quality Engineering Process",
      description:
        "Learn about our systematic approach to ensuring software quality through rigorous testing, automation, and continuous improvement.",
      steps: [
        {
          title: "Test Planning and Strategy",
          description:
            "We develop comprehensive test plans and strategies, aligning testing activities with project goals and quality objectives.",
          icon: <UnorderedListOutlined />,
        },
        {
          title: "Test Automation",
          description:
            "We automate test cases and scripts using industry-standard frameworks like Selenium and JUnit, improving test coverage and efficiency.",
          icon: <RobotOutlined />,
        },
        {
          title: "Performance Testing",
          description:
            "We conduct performance tests to assess system responsiveness, scalability, and stability under various load conditions.",
          icon: <LineChartOutlined />,
        },
        {
          title: "Security Testing",
          description:
            "We perform security assessments and vulnerability scans to identify and mitigate potential risks and threats.",
          icon: <LineChartOutlined />,
        },
        {
          title: "Regression Testing",
          description:
            "We execute regression tests to validate software changes and ensure that new features do not adversely impact existing functionality.",
          icon: <CheckOutlined />,
        },
        {
          title: "Continuous Feedback and Improvement",
          description:
            "We gather feedback from testing cycles and stakeholders, implementing iterative improvements to enhance software quality and user experience.",
          icon: <CommentOutlined />,
        },
      ],
    },
    expertise: [
      {
        title: "Test Automation",
        description:
          "Developing automated test scripts to improve testing efficiency and coverage.",
        icon: <FaRobot style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Performance Testing",
        description:
          "Conducting performance tests to ensure software meets speed and scalability requirements.",
        icon: <FaTachometerAlt style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Security Testing",
        description:
          "Identifying and addressing security vulnerabilities through rigorous testing.",
        icon: <FaShieldAlt style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Functional Testing",
        description:
          "Ensuring software functionality meets specified requirements through systematic testing.",
        icon: <FaTasks style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Regression Testing",
        description:
          "Performing regression tests to ensure new changes do not adversely affect existing functionality.",
        icon: <FaRedo style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Usability Testing",
        description:
          "Evaluating software usability to ensure a smooth and intuitive user experience.",
        icon: <FaUserCheck style={{ width: "32px", height: "32px" }} />,
      },
    ],
  },
  data_science: {
    heroSection: {
      backgroundImage: data_science,
      backgroundPosition: "center bottom",
      title: "Harnessing Data Science for Insightful Decision-Making",
      description:
        "Explore how our data science expertise transforms raw data into valuable insights, empowering businesses to make informed decisions and drive innovation.",
      buttons: [
        {
          text: "Learn More",
          icon: <ArrowRightOutlined />,
        },
      ],
    },
    servicesSection: {
      title: "Our Data Science Services",
      description:
        "Discover our comprehensive range of data science services designed to extract actionable insights from data, enhance predictive modeling capabilities, and drive business growth.",
      services: [
        {
          title: "Data Analysis",
          description:
            "Utilize advanced analytical techniques to extract meaningful patterns, trends, and insights from structured and unstructured data sets.",
          icon: <LineChartOutlined />,
        },
        {
          title: "Machine Learning",
          description:
            "Develop and deploy machine learning models to automate processes, predict outcomes, and optimize business operations.",
          icon: <RobotOutlined />,
        },
        {
          title: "Predictive Analytics",
          description:
            "Apply statistical algorithms and predictive modeling techniques to forecast future trends, behavior patterns, and business outcomes.",
          icon: <AreaChartOutlined />,
        },
        {
          title: "Natural Language Processing",
          description:
            "Extract insights and sentiment from textual data, enabling intelligent processing and understanding of human language.",
          icon: <FileTextOutlined />,
        },
        {
          title: "Big Data Analytics",
          description:
            "Process and analyze large volumes of data to uncover hidden patterns, optimize operations, and drive strategic decision-making.",
          icon: <DatabaseOutlined />,
        },
      ],
    },
    howWeWorkSection: {
      title: "Our Data Science Approach",
      description:
        "Discover our systematic approach to extracting insights from data, building predictive models, and delivering data-driven solutions.",
      steps: [
        {
          title: "Data Collection and Cleaning",
          description:
            "We gather and preprocess data from various sources, ensuring data quality and consistency for analysis.",
          icon: <DatabaseOutlined />,
        },
        {
          title: "Exploratory Data Analysis",
          description:
            "We analyze and visualize data to uncover patterns, trends, and correlations that inform further insights and modeling.",
          icon: <LineChartOutlined />,
        },
        {
          title: "Feature Engineering",
          description:
            "We transform raw data into meaningful features, optimizing inputs for machine learning algorithms.",
          icon: <ToolOutlined />,
        },
        {
          title: "Model Development and Training",
          description:
            "We develop and train machine learning models using algorithms such as regression, classification, and clustering.",
          icon: <ExperimentOutlined />,
        },
        {
          title: "Model Evaluation and Validation",
          description:
            "We assess model performance, validate predictions, and fine-tune parameters to improve accuracy and reliability.",
          icon: <AuditOutlined />,
        },
        {
          title: "Deployment and Monitoring",
          description:
            "We deploy models into production environments and monitor their performance, ensuring ongoing relevance and effectiveness.",
          icon: <DeploymentUnitOutlined />,
        },
      ],
    },
    expertise: [
      {
        title: "Data Analysis",
        description:
          "Analyzing data to extract valuable insights and inform decision-making.",
        icon: <FaChartPie style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Machine Learning",
        description:
          "Developing machine learning models to predict outcomes and automate tasks.",
        icon: <FaBrain style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Data Visualization",
        description:
          "Creating visual representations of data to communicate insights effectively.",
        icon: <FaChartBar style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Big Data Solutions",
        description:
          "Implementing big data technologies to handle and process large datasets.",
        icon: <FaDatabase style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Data Engineering",
        description:
          "Building and maintaining data pipelines to ensure data quality and accessibility.",
        icon: <FaTools style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Predictive Modeling",
        description:
          "Using statistical models to forecast future trends and behaviors.",
        icon: <FaChartLine style={{ width: "32px", height: "32px" }} />,
      },
    ],
  },
  low_code: {
    heroSection: {
      backgroundImage: low_Code,
      backgroundPosition: "center bottom",
      title: "Empower Your Business with Low Code Platforms",
      description:
        "Discover how our low code platforms accelerate application development, empower citizen developers, and streamline digital transformation.",
      buttons: [
        {
          text: "Explore Now",
          icon: <ArrowRightOutlined />,
        },
      ],
    },
    servicesSection: {
      title: "Key Features of Low Code Platforms",
      description:
        "Explore the features that make low code platforms a powerful tool for rapid application development and innovation.",
      services: [
        {
          title: "Visual Development Environment",
          description:
            "Intuitive drag-and-drop interface for building applications without traditional coding.",
          icon: <BuildOutlined />,
        },
        {
          title: "Pre-built Templates and Components",
          description:
            "Library of reusable templates and components to accelerate development and maintain consistency.",
          icon: <AppstoreAddOutlined />,
        },
        {
          title: "Integration Capabilities",
          description:
            "Easily connect with existing systems and third-party services through built-in integration features.",
          icon: <ApiOutlined />,
        },
        {
          title: "Workflow Automation",
          description:
            "Automate business processes and workflows using visual tools and rules-based engines.",
          icon: <ControlOutlined />,
        },
        {
          title: "Collaboration and Feedback",
          description:
            "Facilitate collaboration among teams and stakeholders with real-time feedback and version control.",
          icon: <TeamOutlined />,
        },
      ],
    },
    howWeWorkSection: {
      title: "Our Low Code Platform Approach",
      description:
        "Learn about our approach to accelerating application development through low code platforms, empowering rapid prototyping and deployment.",
      steps: [
        {
          title: "Requirements Gathering",
          description:
            "We collaborate with stakeholders to gather requirements and define application functionalities and workflows.",
          icon: <FormOutlined />,
        },
        {
          title: "Visual Modeling",
          description:
            "We use visual interfaces and drag-and-drop tools to design application logic, data models, and user interfaces.",
          icon: <HighlightOutlined />,
        },
        {
          title: "Integration and Extensions",
          description:
            "We integrate with external systems and extend functionality through APIs and pre-built components available in the platform ecosystem.",
          icon: <ApiOutlined />,
        },
        {
          title: "Testing and Validation",
          description:
            "We conduct automated testing and user acceptance testing to ensure quality and functionality meet requirements.",
          icon: <CheckOutlined />,
        },
        {
          title: "Deployment and Maintenance",
          description:
            "We deploy applications to various environments and provide ongoing support and maintenance to optimize performance and usability.",
          icon: <CloudServerOutlined />,
        },
        {
          title: "Scalability and Optimization",
          description:
            "We scale applications horizontally and vertically, optimizing performance and resource utilization as demand grows.",
          icon: <ClusterOutlined />,
        },
      ],
    },
    expertise: [
      {
        title: "Application Development",
        description:
          "Building custom applications rapidly using low-code platforms.",
        icon: <FaLaptopCode style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Workflow Automation",
        description:
          "Automating business processes and workflows with minimal coding.",
        icon: <FaProjectDiagram style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Integration Services",
        description:
          "Integrating low-code applications with existing systems and databases.",
        icon: <FaPlug style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Rapid Prototyping",
        description:
          "Creating prototypes quickly to validate ideas and gather user feedback.",
        icon: <FaDraftingCompass style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "User Interface Design",
        description:
          "Designing intuitive and user-friendly interfaces using low-code tools.",
        icon: <FaPalette style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Mobile App Development",
        description:
          "Developing mobile applications with low-code platforms for cross-platform compatibility.",
        icon: <FaMobileAlt style={{ width: "32px", height: "32px" }} />,
      },
    ],
  },
  cyber_security: {
    heroSection: {
      title: "Secure Your Digital Assets",
      subtitle: "Protecting businesses with robust cyber security solutions",
      description:
        "We specialize in comprehensive cyber security services, offering proactive strategies and advanced technologies to safeguard your digital infrastructure.",
      backgroundImage: cyber_security,
      backgroundPosition: "center bottom",
      buttons: [
        {
          text: "Explore Now",
          icon: <ArrowRightOutlined />,
        },
      ],
    },
    servicesSection: {
      title: "Our Cyber Security Services",
      services: [
        {
          title: "Vulnerability Assessment",
          description:
            "Identify and assess vulnerabilities in networks, systems, and applications to prioritize remediation efforts.",
          icon: <SafetyCertificateOutlined />,
        },
        {
          title: "Penetration Testing",
          description:
            "Simulate real-world attacks to identify security weaknesses and validate the effectiveness of defensive measures.",
          icon: <BugOutlined />,
        },
        {
          title: "Security Audits and Compliance",
          description:
            "Conduct thorough audits and assessments to ensure compliance with industry regulations and standards.",
          icon: <AuditOutlined />,
        },
        {
          title: "Incident Response and Management",
          description:
            "Develop and implement incident response plans to detect, respond to, and recover from security incidents effectively.",
          icon: <AlertOutlined />,
        },
        {
          title: "Security Awareness Training",
          description:
            "Educate employees and stakeholders about cyber threats and best practices to enhance organizational security posture.",
          icon: <TeamOutlined />,
        },
        {
          title: "Cloud Security Solutions",
          description:
            "Implement and manage security measures tailored for cloud environments to protect data and applications.",
          icon: <CloudOutlined />,
        },
      ],
    },
    howWeWorkSection: {
      title: "Our Cyber Security Approach",
      description:
        "Explore our comprehensive approach to safeguarding digital assets and mitigating cyber threats through proactive strategies and defensive measures.",
      steps: [
        {
          title: "Risk Assessment and Analysis",
          description:
            "We assess potential risks and vulnerabilities through threat modeling and risk analysis, identifying critical assets and potential threats.",
          icon: <SafetyCertificateOutlined />,
        },
        {
          title: "Security Architecture Design",
          description:
            "We design and implement robust security architectures, incorporating best practices and regulatory requirements.",
          icon: <LockOutlined />,
        },
        {
          title: "Incident Response Planning",
          description:
            "We develop incident response plans and playbooks, outlining procedures for detecting, responding to, and recovering from security incidents.",
          icon: <AlertOutlined />,
        },
        {
          title: "Continuous Monitoring and Threat Detection",
          description:
            "We deploy monitoring tools and employ threat detection techniques to detect and respond to suspicious activities in real-time.",
          icon: <EyeOutlined />,
        },
        {
          title: "Security Awareness and Training",
          description:
            "We conduct security awareness programs and training sessions to educate employees and stakeholders about cybersecurity best practices.",
          icon: <TeamOutlined />,
        },
        {
          title: "Compliance and Regulatory Compliance",
          description:
            "We ensure compliance with industry regulations and standards, conducting audits and assessments to maintain adherence.",
          icon: <AuditOutlined />,
        },
      ],
    },
    expertise: [
      {
        title: "Threat Detection",
        description:
          "Identifying and responding to potential security threats and vulnerabilities.",
        icon: <FaShieldAlt style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Data Protection",
        description:
          "Implementing measures to protect sensitive data from unauthorized access.",
        icon: <FaLock style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Network Security",
        description:
          "Securing network infrastructure to prevent attacks and ensure safe communication.",
        icon: <FaNetworkWired style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Incident Response",
        description:
          "Managing and responding to security incidents to minimize impact and recovery time.",
        icon: (
          <FaExclamationTriangle style={{ width: "32px", height: "32px" }} />
        ),
      },
      {
        title: "Compliance Management",
        description:
          "Ensuring compliance with relevant regulations and standards to avoid legal penalties.",
        icon: <FaClipboardCheck style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Security Audits",
        description:
          "Conducting regular security audits to assess and improve the overall security posture.",
        icon: <FaSearch style={{ width: "32px", height: "32px" }} />,
      },
    ],
  },

  software_development: {
    heroSection: {
      backgroundImage: software,
      backgroundPosition: "center bottom",
      title: "Innovative Software Solutions for Your Business Success!",
      description:
        "Transform your business with our comprehensive software development services tailored to your needs.",
      buttons: [
        {
          text: "Get in Touch",
          icon: <MailOutlined />,
        },
      ],
    },
    servicesSection: {
      title: "Our Expertise",
      description:
        "We offer a wide range of software development services, from initial consultation to final deployment and maintenance.",
      services: [
        {
          title: "Custom Software Development",
          description:
            "We build bespoke software solutions tailored to your specific business requirements, ensuring a perfect fit for your needs.",
          icon: <BuildOutlined />,
        },
        {
          title: "Web Application Development",
          description:
            "Developing robust and scalable web applications to enhance your online presence and improve business operations.",
          icon: <LaptopOutlined />,
        },
        {
          title: "Mobile Application Development",
          description:
            "Creating intuitive and engaging mobile apps for both iOS and Android platforms to reach your customers on the go.",
          icon: <MobileOutlined />,
        },
        {
          title: "Cloud Solutions",
          description:
            "Leveraging cloud technologies to build scalable and flexible software solutions that grow with your business.",
          icon: <CloudOutlined />,
        },
        {
          title: "Software Integration",
          description:
            "Seamlessly integrating new software with your existing systems to ensure smooth and efficient operations.",
          icon: <CheckCircleOutlined />,
        },
        {
          title: "Software Maintenance and Support",
          description:
            "Providing ongoing maintenance and support to keep your software up-to-date and running smoothly.",
          icon: <PicCenterOutlined />,
        },
      ],
    },
    howWeWorkSection: {
      title: "Our Process",
      description:
        "Discover our comprehensive approach to delivering high-quality software solutions.",
      steps: [
        {
          title: "Consultation and Planning",
          description:
            "We start by understanding your business needs and goals, then develop a detailed plan to guide the project.",
          icon: <SearchOutlined />,
        },
        {
          title: "Design and Prototyping",
          description:
            "Our design team creates user-centric designs and prototypes to ensure a great user experience.",
          icon: <HighlightOutlined />,
        },
        {
          title: "Development",
          description:
            "We build your software using the latest technologies, ensuring high performance and scalability.",
          icon: <CodeOutlined />,
        },
        {
          title: "Testing and QA",
          description:
            "Conducting rigorous testing to identify and fix any issues, ensuring your software meets our high-quality standards.",
          icon: <BugOutlined />,
        },
        {
          title: "Deployment",
          description:
            "We handle the deployment process to ensure a smooth and successful launch of your software.",
          icon: <RocketOutlined />,
        },
        {
          title: "Ongoing Support",
          description:
            "Providing continuous support and maintenance to keep your software updated and running efficiently.",
          icon: <ToolOutlined />,
        },
      ],
    },
    expertise: [
      {
        title: "Custom Software Development",
        description:
          "Crafting unique software solutions tailored to your specific business needs.",
        icon: <FaCogs style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Web Application Development",
        description:
          "Building robust web applications to enhance your online presence and business operations.",
        icon: <FaLaptopCode style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Mobile Application Development",
        description:
          "Developing engaging mobile apps for both iOS and Android platforms.",
        icon: <FaMobileAlt style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Cloud Solutions",
        description:
          "Implementing cloud-based solutions for scalability and flexibility.",
        icon: <FaCloud style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Software Integration",
        description:
          "Ensuring seamless integration of new software with your existing systems.",
        icon: <FaProjectDiagram style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Software Maintenance and Support",
        description:
          "Providing ongoing maintenance and support to keep your software running smoothly.",
        icon: <FaTools style={{ width: "32px", height: "32px" }} />,
      },
    ],
  },
  testing_qa: {
    heroSection: {
      backgroundImage: testing_QA,
      backgroundPosition: "center bottom",
      title: "Ensuring Quality and Reliability in Every Step!",
      description:
        "Guarantee the performance, security, and reliability of your software with our comprehensive testing and QA services.",
      buttons: [
        {
          text: "Get in Touch",
          icon: <MailOutlined />,
        },
      ],
    },
    servicesSection: {
      title: "Our Expertise",
      description:
        "We provide a full range of testing and quality assurance services to ensure your software meets the highest standards.",
      services: [
        {
          title: "Functional Testing",
          description:
            "Ensuring that your software operates according to the specified requirements and performs all its functions correctly.",
          icon: <CheckCircleOutlined />,
        },
        {
          title: "Performance Testing",
          description:
            "Evaluating the speed, responsiveness, and stability of your software under various conditions.",
          icon: <DashboardOutlined />,
        },
        {
          title: "Security Testing",
          description:
            "Identifying and addressing potential security vulnerabilities to protect your software from threats.",
          icon: <LockOutlined />,
        },
        {
          title: "Usability Testing",
          description:
            "Assessing the user-friendliness of your software to ensure a positive user experience.",
          icon: <UserOutlined />,
        },
        {
          title: "Compatibility Testing",
          description:
            "Making sure your software works seamlessly across different browsers, devices, and operating systems.",
          icon: <DesktopOutlined />,
        },
        {
          title: "Automated Testing",
          description:
            "Implementing automated tests to improve efficiency and consistency in the testing process.",
          icon: <RobotOutlined />,
        },
      ],
    },
    howWeWorkSection: {
      title: "Our Process",
      description:
        "Learn about our thorough approach to delivering top-notch testing and QA services.",
      steps: [
        {
          title: "Requirement Analysis",
          description:
            "Understanding your software requirements and identifying the key areas that need testing.",
          icon: <SearchOutlined />,
        },
        {
          title: "Test Planning",
          description:
            "Creating a detailed test plan that outlines the scope, approach, resources, and schedule of the testing activities.",
          icon: <CalendarOutlined />,
        },
        {
          title: "Test Design",
          description:
            "Designing test cases and scenarios to cover all aspects of the software functionality and performance.",
          icon: <ProfileOutlined />,
        },
        {
          title: "Test Execution",
          description:
            "Executing the test cases and documenting the outcomes to identify any defects or issues.",
          icon: <PlayCircleOutlined />,
        },
        {
          title: "Defect Management",
          description:
            "Tracking, managing, and resolving any defects or issues identified during the testing process.",
          icon: <BugOutlined />,
        },
        {
          title: "Reporting and Analysis",
          description:
            "Providing detailed reports on the testing outcomes and offering insights for improvement.",
          icon: <BarChartOutlined />,
        },
      ],
    },
    expertise: [
      {
        title: "Functional Testing",
        description:
          "Ensuring your software operates correctly according to specified requirements.",
        icon: <FaCheckCircle style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Performance Testing",
        description:
          "Evaluating the speed and stability of your software under various conditions.",
        icon: <FaTachometerAlt style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Security Testing",
        description:
          "Identifying and mitigating security vulnerabilities to protect against threats.",
        icon: <FaShieldAlt style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Usability Testing",
        description:
          "Assessing the user-friendliness of your software for a positive user experience.",
        icon: <FaUserCheck style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Compatibility Testing",
        description:
          "Ensuring seamless operation across different browsers, devices, and OS.",
        icon: <FaDesktop style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Automated Testing",
        description:
          "Improving efficiency and consistency with automated testing processes.",
        icon: <FaRobot style={{ width: "32px", height: "32px" }} />,
      },
    ],
  },
  application_services: {
    heroSection: {
      backgroundImage: application_service,
      backgroundPosition: "center bottom",
      title: "Optimizing Your Digital Solutions for Success!",
      description:
        "Our experts help mid-sized and large firms build, test, protect, manage, migrate and optimize digital solutions ensuring they’re always up and running and achieve the optimal TCO.",
      buttons: [
        {
          text: "Get in Touch",
          icon: <MailOutlined />,
        },
      ],
    },
    servicesSection: {
      title: "Our Expertise",
      description:
        "We deliver a comprehensive range of application services to support your business needs.",
      services: [
        {
          title: "Application Management",
          description:
            "Ensure your applications are always up and running with our comprehensive management services.",
          icon: <SettingOutlined />,
        },
        {
          title: "Application Modernization",
          description:
            "Upgrade and enhance your existing applications to meet modern standards and demands.",
          icon: <RetweetOutlined />,
        },
        {
          title: "Application Integration",
          description:
            "Seamlessly integrate various applications within your IT environment to ensure smooth operations.",
          icon: <ApiOutlined />,
        },
        {
          title: "Application Security Services",
          description:
            "Protect your applications from threats with our robust security services.",
          icon: <LockOutlined />,
        },
        {
          title: "Application Development",
          description:
            "Build custom applications tailored to your specific business requirements.",
          icon: <CodeOutlined />,
        },
        {
          title: "Application Testing",
          description:
            "Thoroughly test your applications to ensure they are bug-free and perform optimally.",
          icon: <BugOutlined />,
        },
        {
          title: "Application Maintenance and Support",
          description:
            "Provide ongoing maintenance and support to keep your applications running smoothly.",
          icon: <ToolOutlined />,
        },
      ],
    },
    howWeWorkSection: {
      title: "Our Process",
      description: "Discover how we deliver top-notch application services.",
      steps: [
        {
          title: "Requirement Analysis",
          description:
            "Understanding your business goals and application needs to provide tailored solutions.",
          icon: <SearchOutlined />,
        },
        {
          title: "Strategic Planning",
          description:
            "Developing a strategic plan to guide the application development and management process.",
          icon: <CalendarOutlined />,
        },
        {
          title: "Design and Development",
          description:
            "Designing and developing applications that align with your business objectives.",
          icon: <CodeOutlined />,
        },
        {
          title: "Testing and QA",
          description:
            "Conducting comprehensive testing to ensure the application meets quality standards.",
          icon: <BugOutlined />,
        },
        {
          title: "Deployment",
          description:
            "Managing the deployment process to ensure a smooth launch.",
          icon: <RocketOutlined />,
        },
        {
          title: "Maintenance and Support",
          description:
            "Providing ongoing support and maintenance to keep the application up-to-date and running efficiently.",
          icon: <ToolOutlined />,
        },
      ],
    },
    expertise: [
      {
        title: "Application Management",
        description:
          "Ensuring your applications are reliable and always available.",
        icon: <FaCog style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Application Modernization",
        description:
          "Updating your legacy applications to meet current technological standards.",
        icon: <FaSyncAlt style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Application Integration",
        description:
          "Integrating different applications for seamless operations.",
        icon: <FaPlug style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Application Security Services",
        description: "Protecting your applications from potential threats.",
        icon: <FaLock style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Application Development",
        description:
          "Creating custom applications tailored to your business needs.",
        icon: <FaCode style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Application Testing",
        description:
          "Ensuring your applications are bug-free and perform optimally.",
        icon: <FaBug style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Application Maintenance and Support",
        description:
          "Providing ongoing maintenance to keep your applications running smoothly.",
        icon: <FaWrench style={{ width: "32px", height: "32px" }} />,
      },
    ],
  },
  help_desk_services: {
    heroSection: {
      backgroundImage: help_desk_service,
      backgroundPosition: "center bottom",
      title: "Comprehensive Help Desk Services for Seamless IT Operations!",
      description:
        "Help desk services for your IT environment or software products. We take on solving diverse issues from answering application functionality questions to performing fixes and enhancements on the code level for improved adoption of software, its smooth functioning and increased end user satisfaction.",
      buttons: [
        {
          text: "Get in Touch",
          icon: <MailOutlined />,
        },
      ],
    },
    servicesSection: {
      title: "Our Expertise",
      description:
        "We offer a wide range of help desk services to ensure the optimal functioning of your IT environment and software products.",
      services: [
        {
          title: "Help Desk Outsourcing",
          description:
            "Outsource your help desk needs to us for expert handling of IT and software issues.",
          icon: <CustomerServiceOutlined />,
        },
        {
          title: "IT Help Desk Services",
          description:
            "Get comprehensive IT help desk support to manage and resolve technical issues efficiently.",
          icon: <LaptopOutlined />,
        },
        {
          title: "Outsourced Help Desk for MSP",
          description:
            "Provide your Managed Service Provider (MSP) with robust help desk support to enhance service delivery.",
          icon: <TeamOutlined />,
        },
        {
          title: "NOC Outsourcing",
          description:
            "Outsource your Network Operations Center (NOC) to ensure continuous monitoring and management of your IT infrastructure.",
          icon: <ClusterOutlined />,
        },
      ],
    },
    howWeWorkSection: {
      title: "Our Process",
      description:
        "Discover how we deliver exceptional help desk services to support your IT and software needs.",
      steps: [
        {
          title: "Requirement Analysis",
          description:
            "Understanding your help desk needs and IT environment to provide tailored support solutions.",
          icon: <SearchOutlined />,
        },
        {
          title: "Strategic Planning",
          description:
            "Developing a strategic plan to guide the help desk support process.",
          icon: <CalendarOutlined />,
        },
        {
          title: "Implementation",
          description:
            "Setting up and integrating help desk services within your IT framework.",
          icon: <DeploymentUnitOutlined />,
        },
        {
          title: "Support and Resolution",
          description:
            "Providing ongoing help desk support and resolving issues promptly.",
          icon: <SolutionOutlined />,
        },
        {
          title: "Monitoring and Improvement",
          description:
            "Continuously monitoring help desk performance and implementing improvements.",
          icon: <LineChartOutlined />,
        },
      ],
    },
    expertise: [
      {
        title: "Help Desk Outsourcing",
        description:
          "Expert handling of your help desk needs to ensure efficient issue resolution.",
        icon: <FaHeadset style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "IT Help Desk Services",
        description:
          "Comprehensive support for managing and resolving IT issues.",
        icon: <FaLaptop style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Outsourced Help Desk for MSP",
        description:
          "Enhancing MSP service delivery with robust help desk support.",
        icon: <FaUsers style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "NOC Outsourcing",
        description:
          "Ensuring continuous monitoring and management of your IT infrastructure.",
        icon: <FaServer style={{ width: "32px", height: "32px" }} />,
      },
    ],
  },

  digital_marketing: {
    heroSection: {
      title: "Boost Your Online Presence",
      subtitle:
        "Transform Your Business with Strategic Digital Marketing Solutions",
      description:
        "In today’s digital-first world, effective online marketing is key to driving business growth. At Omaza, we provide comprehensive digital marketing services to help you connect with your target audience, boost brand visibility, and maximize your ROI.",
      backgroundImage: UI,
      backgroundPosition: "center bottom",
      buttons: [
        {
          text: "Explore Now",
          icon: <ArrowRightOutlined />,
        },
      ],
    },
    servicesSection: {
      title: "Our Digital Marketing Solutions Include",
      services: [
        {
          title: " Meta Ads (Facebook & Instagram Advertising)",
          description:
            "Leverage the power of Meta platforms to reach your ideal customers. We create and manage highly targeted Facebook and Instagram ad campaigns designed to increase brand awareness, generate leads, and drive conversions.",
          icon: <FaMeta />,
        },
        {
          title: "Google Ads (PPC)",
          description:
            "Appear at the top of search results when potential customers are looking for your services. Our Google Ads (Pay-Per-Click) campaigns are designed to drive high-intent traffic to your website and increase conversions.",
          icon: <SiGoogleads />,
        },
        {
          title: "SEO Strategy & Optimization",
          description:
            "Alongside paid campaigns, we ensure your website is optimized for organic search rankings. We implement on-page and off-page SEO strategies that improve your visibility on Google, resulting in sustainable, long-term traffic growth.",
          icon: <AuditOutlined />,
        },
        {
          title: "Social Media Marketing",
          description:
            "Beyond paid ads, we help you engage with your audience on social media. From creating content calendars to managing your profiles, we ensure your brand stays active and visible across key social platforms.",
          icon: <AlertOutlined />,
        },
        {
          title: " Analytics & Reporting",
          description:
            "We believe in data-driven marketing. With our advanced analytics and tracking, we monitor the performance of every campaign, giving you insights into customer behavior, campaign ROI, and areas of improvement.",
          icon: <TeamOutlined />,
        },
      ],
    },
    howWeWorkSection: {
      title: "Why Choose Us?",
      description:
        "This updated version highlights your expertise in managing substantial budgets, giving potential clients confidence in your ability to scale their marketing efforts effectively.",
      steps: [
        {
          title: "Tailored Strategies",
          description:
            "Every business is unique, and so are our marketing strategies. We customize each campaign to meet your specific goals.",
          icon: <SafetyCertificateOutlined />,
        },
        {
          title: "Proven Experience",
          description:
            "With a strong background in software solutions and digital marketing, we understand the digital landscape inside out.",
          icon: <LockOutlined />,
        },
        {
          title: "Measurable Results",
          description:
            "Our campaigns are designed to deliver real, measurable outcomes that align with your business objectives.",
          icon: <AlertOutlined />,
        },
        {
          title: "Expert Budget Management",
          description:
            "With extensive experience in handling large ad budgets (over Rs 50L per account), we ensure efficient and effective resource allocation.",
          icon: <AlertOutlined />,
        },
      ],
    },

    appCampaignsSection: {
      metaCampaigns: {
        title: "App Campaigns on Meta",
        description:
          "If your business has a mobile app, we help you promote it on Facebook and Instagram using specialized app ads. These campaigns focus on driving app installs and user engagement, ensuring your app gets in front of the right audience.",
        steps: [
          {
            title: "App Install Ads",
            description: "Drive app downloads from highly relevant users.",
            icon: <FaMeta />,
          },
          {
            title: "App Engagement Ads",
            description:
              "Re-engage existing app users to increase retention and usage.",
            icon: <FaMeta />,
          },
          {
            title: "Expert Management",
            description:
              "Our team manages ad accounts with budgets over Rs 50L, ensuring every rupee is optimized for maximum performance.",
            icon: <TeamOutlined />,
          },
          {
            title: "In-depth Reporting",
            description:
              "Track app installs, in-app events, and overall app engagement.",
            icon: <AuditOutlined />,
          },
        ],
      },
      googleCampaigns: {
        title: "App Campaigns on Google Ads",
        description:
          "Google App Campaigns are a powerful tool for promoting mobile apps across Google’s vast network, including Google Search, Play Store, YouTube, and the Google Display Network. We manage these campaigns to help you drive app downloads and increase in-app activity.",
        steps: [
          {
            title: "Automated App Campaigns",
            description:
              "Google’s machine learning optimizes your ads to appear where they’ll drive the most app installs.",
            icon: <FaGooglePlay />,
          },
          {
            title: "Multi-Platform Reach",
            description:
              "Ads run on Search, Play Store, YouTube, and Display Network.",
            icon: <BugOutlined />,
          },
          {
            title: "User Actions",
            description:
              "Promote specific in-app actions like sign-ups, purchases, or usage events.",
            icon: <AlertOutlined />,
          },
          {
            title: "Expert Budget Management",
            description:
              "Our specialists handle ad budgets exceeding Rs 25L per account, ensuring effective use of resources and maximizing ROI.",
            icon: <TeamOutlined />,
          },
          {
            title: "Optimization and Reporting",
            description:
              "We continuously optimize for the best results and provide in-depth analytics on app installs and user engagement.",
            icon: <AuditOutlined />,
          },
        ],
      },
    },
  },
};
