import {
  AlertOutlined,
  ApiOutlined,
  AuditOutlined,
  BankOutlined,
  BarChartOutlined,
  BlockOutlined,
  BookOutlined,
  BugOutlined,
  CameraOutlined,
  CarOutlined,
  CodeOutlined,
  CreditCardOutlined,
  CustomerServiceOutlined,
  DatabaseOutlined,
  DesktopOutlined,
  EnvironmentOutlined,
  FileDoneOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  GiftOutlined,
  GlobalOutlined,
  HighlightOutlined,
  HomeOutlined,
  LineChartOutlined,
  MailOutlined,
  MobileOutlined,
  ProfileOutlined,
  RobotOutlined,
  RocketOutlined,
  SafetyOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  SolutionOutlined,
  StarOutlined,
  TeamOutlined,
  ToolOutlined,
  TwitterOutlined,
  UserOutlined,
} from "@ant-design/icons";
import travel_loyality from "../img/home/travel.webp";
import bfsi from "../img/home/bfsi.webp";
import Ecommerce from "../img/home/e-commerce.webp";
import ed_tech from "../img/home/ed-tech.jpeg";
import mar_tech from "../img/home/mar-tech.webp";
import real_state from "../img/home/real-state.jpeg";
import shipping_logitics from "../img/home/shippping.jpeg";

export const industries = {
  travel_and_loyality: {
    heroSection: {
      backgroundImage: travel_loyality,
      backgroundPosition: "center bottom",
      title: "Explore Your World with Our Travel and Loyalty Solutions!",
      description:
        "Enhance customer engagement and loyalty with our tailored travel and loyalty program solutions.",
      buttons: [
        {
          text: "Contact Us",
          icon: <MailOutlined />,
        },
      ],
    },
    servicesSection: {
      title: "Our Services",
      description:
        "We offer comprehensive travel and loyalty solutions designed to boost customer satisfaction and retention.",
      services: [
        {
          title: "Travel Booking Platforms",
          description:
            "Developing robust platforms for seamless travel bookings and itinerary management.",
          icon: <HighlightOutlined />,
        },
        {
          title: "Loyalty Program Management",
          description:
            "Creating customized loyalty programs to reward and retain loyal customers.",
          icon: <GiftOutlined />,
        },
        {
          title: "Personalized Recommendations",
          description:
            "Implementing AI-driven systems to provide personalized travel recommendations based on customer preferences.",
          icon: <StarOutlined />,
        },
        {
          title: "Mobile App Development",
          description:
            "Designing intuitive mobile apps for travel booking and loyalty program management.",
          icon: <MobileOutlined />,
        },
        {
          title: "Customer Engagement Tools",
          description:
            "Developing tools to enhance customer engagement through targeted promotions and communications.",
          icon: <CustomerServiceOutlined />,
        },
        {
          title: "Data Analytics for Insights",
          description:
            "Utilizing data analytics to gain actionable insights into customer behavior and preferences.",
          icon: <BarChartOutlined />,
        },
        {
          title: "Integration Services",
          description:
            "Integrating with third-party systems for seamless data exchange and functionality.",
          icon: <ApiOutlined />,
        },
      ],
    },
    howWeWorkSection: {
      title: "Our Approach",
      description:
        "Discover how we collaborate with clients to deliver innovative travel and loyalty solutions.",
      steps: [
        {
          title: "Consultation and Planning",
          description:
            "Understanding client goals and requirements to develop a strategic plan.",
          icon: <SolutionOutlined />,
        },
        {
          title: "Design and Development",
          description:
            "Creating intuitive designs and developing robust solutions tailored to client needs.",
          icon: <CodeOutlined />,
        },
        {
          title: "Testing and Quality Assurance",
          description:
            "Conducting rigorous testing to ensure high performance and reliability.",
          icon: <BugOutlined />,
        },
        {
          title: "Deployment and Launch",
          description:
            "Managing smooth deployment and providing support during launch.",
          icon: <RocketOutlined />,
        },
        {
          title: "Maintenance and Support",
          description:
            "Offering ongoing maintenance and support to ensure continuous improvement.",
          icon: <ToolOutlined />,
        },
      ],
    },
    expertise: [
      {
        title: "Travel Booking Platforms",
        description:
          "Developing robust platforms for seamless travel bookings and itinerary management.",
        icon: <HighlightOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Loyalty Program Management",
        description:
          "Creating customized loyalty programs to reward and retain loyal customers.",
        icon: <GiftOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Personalized Recommendations",
        description:
          "Implementing AI-driven systems to provide personalized travel recommendations based on customer preferences.",
        icon: <StarOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Mobile App Development",
        description:
          "Designing intuitive mobile apps for travel booking and loyalty program management.",
        icon: <MobileOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Customer Engagement Tools",
        description:
          "Developing tools to enhance customer engagement through targeted promotions and communications.",
        icon: (
          <CustomerServiceOutlined style={{ width: "32px", height: "32px" }} />
        ),
      },
      {
        title: "Data Analytics for Insights",
        description:
          "Utilizing data analytics to gain actionable insights into customer behavior and preferences.",
        icon: <BarChartOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Integration Services",
        description:
          "Integrating with third-party systems for seamless data exchange and functionality.",
        icon: <ApiOutlined style={{ width: "32px", height: "32px" }} />,
      },
    ],
  },
  e_commerce: {
    heroSection: {
      backgroundImage: Ecommerce,
      backgroundPosition: "center bottom",
      title: "Powering Your E-commerce Success!",
      description:
        "Drive sales and customer satisfaction with our comprehensive e-commerce solutions.",
      buttons: [
        {
          text: "Contact Us",
          icon: <MailOutlined />,
        },
      ],
    },
    servicesSection: {
      title: "Our Services",
      description:
        "We offer tailored e-commerce solutions designed to enhance your online retail presence.",
      services: [
        {
          title: "Online Store Development",
          description:
            "Building robust and scalable online stores tailored to your brand and target audience.",
          icon: <ShoppingOutlined />,
        },
        {
          title: "Payment Gateway Integration",
          description:
            "Integrating secure and efficient payment gateways for seamless transactions.",
          icon: <CreditCardOutlined />,
        },
        {
          title: "Product Catalog Management",
          description:
            "Managing and optimizing product catalogs for enhanced discoverability and sales.",
          icon: <DatabaseOutlined />,
        },
        {
          title: "Shopping Cart Solutions",
          description:
            "Implementing intuitive shopping cart systems for streamlined purchase experiences.",
          icon: <ShoppingCartOutlined />,
        },
        {
          title: "Order Management",
          description:
            "Developing efficient order management systems for smooth order processing and fulfillment.",
          icon: <ProfileOutlined />,
        },
        {
          title: "Mobile Commerce Solutions",
          description:
            "Creating responsive and user-friendly mobile commerce apps for on-the-go shopping.",
          icon: <MobileOutlined />,
        },
        {
          title: "Analytics and Insights",
          description:
            "Utilizing data analytics to gain actionable insights into customer behavior and sales trends.",
          icon: <LineChartOutlined />,
        },
      ],
    },
    howWeWorkSection: {
      title: "Our Approach",
      description:
        "Discover how we collaborate with clients to deliver successful e-commerce solutions.",
      steps: [
        {
          title: "Consultation and Planning",
          description:
            "Understanding client goals and requirements to develop a strategic e-commerce plan.",
          icon: <SolutionOutlined />,
        },
        {
          title: "Design and Development",
          description:
            "Creating user-centric designs and developing robust e-commerce platforms.",
          icon: <CodeOutlined />,
        },
        {
          title: "Testing and Quality Assurance",
          description:
            "Conducting rigorous testing to ensure high performance and reliability.",
          icon: <BugOutlined />,
        },
        {
          title: "Deployment and Launch",
          description:
            "Managing smooth deployment and providing support during launch.",
          icon: <RocketOutlined />,
        },
        {
          title: "Maintenance and Support",
          description:
            "Offering ongoing maintenance and support to ensure continuous improvement.",
          icon: <ToolOutlined />,
        },
      ],
    },
    expertise: [
      {
        title: "Online Store Development",
        description:
          "Building robust and scalable online stores tailored to your brand and target audience.",
        icon: <ShoppingOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Payment Gateway Integration",
        description:
          "Integrating secure and efficient payment gateways for seamless transactions.",
        icon: <CreditCardOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Product Catalog Management",
        description:
          "Managing and optimizing product catalogs for enhanced discoverability and sales.",
        icon: <DatabaseOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Shopping Cart Solutions",
        description:
          "Implementing intuitive shopping cart systems for streamlined purchase experiences.",
        icon: (
          <ShoppingCartOutlined style={{ width: "32px", height: "32px" }} />
        ),
      },
      {
        title: "Order Management",
        description:
          "Developing efficient order management systems for smooth order processing and fulfillment.",
        icon: <ProfileOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Mobile Commerce Solutions",
        description:
          "Creating responsive and user-friendly mobile commerce apps for on-the-go shopping.",
        icon: <MobileOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Analytics and Insights",
        description:
          "Utilizing data analytics to gain actionable insights into customer behavior and sales trends.",
        icon: <LineChartOutlined style={{ width: "32px", height: "32px" }} />,
      },
    ],
  },
  edtech: {
    heroSection: {
      backgroundImage: ed_tech,
      backgroundPosition: "center bottom",
      title: "Transforming Education with Innovative Edtech Solutions!",
      description:
        "Empower learners and educators with our cutting-edge educational technology solutions.",
      buttons: [
        {
          text: "Contact Us",
          icon: <MailOutlined />,
        },
      ],
    },
    servicesSection: {
      title: "Our Services",
      description:
        "We offer comprehensive edtech solutions designed to enhance learning experiences.",
      services: [
        {
          title: "Learning Management Systems",
          description:
            "Developing scalable and intuitive learning management systems for educational institutions.",
          icon: <BookOutlined />,
        },
        {
          title: "Virtual Classroom Solutions",
          description:
            "Creating interactive virtual classrooms with live sessions and collaborative tools.",
          icon: <DesktopOutlined />,
        },
        {
          title: "Content Management Systems",
          description:
            "Managing and delivering educational content efficiently with content management systems.",
          icon: <FileTextOutlined />,
        },
        {
          title: "Assessment and Evaluation Tools",
          description:
            "Developing tools for creating, administering, and analyzing assessments.",
          icon: <AuditOutlined />,
        },
        {
          title: "Mobile Learning Apps",
          description:
            "Designing mobile apps for anytime, anywhere learning on smartphones and tablets.",
          icon: <MobileOutlined />,
        },
        {
          title: "Data Analytics for Education",
          description:
            "Utilizing data analytics to track student progress and optimize educational outcomes.",
          icon: <BarChartOutlined />,
        },
        {
          title: "Collaboration and Communication",
          description:
            "Facilitating collaboration and communication among students, teachers, and parents.",
          icon: <TeamOutlined />,
        },
      ],
    },
    howWeWorkSection: {
      title: "Our Approach",
      description:
        "Discover how we collaborate with educators to deliver impactful edtech solutions.",
      steps: [
        {
          title: "Consultation and Needs Assessment",
          description:
            "Understanding educational goals and requirements to tailor solutions accordingly.",
          icon: <SolutionOutlined />,
        },
        {
          title: "Design and Development",
          description:
            "Creating engaging and user-friendly interfaces for effective learning experiences.",
          icon: <CodeOutlined />,
        },
        {
          title: "Testing and Quality Assurance",
          description:
            "Conducting thorough testing to ensure functionality and usability.",
          icon: <BugOutlined />,
        },
        {
          title: "Deployment and Implementation",
          description:
            "Managing deployment and providing training for seamless integration into educational settings.",
          icon: <RocketOutlined />,
        },
        {
          title: "Support and Maintenance",
          description:
            "Offering ongoing support and maintenance to ensure smooth operation and updates.",
          icon: <ToolOutlined />,
        },
      ],
    },
    expertise: [
      {
        title: "Learning Management Systems",
        description:
          "Developing scalable and intuitive learning management systems for educational institutions.",
        icon: <BookOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Virtual Classroom Solutions",
        description:
          "Creating interactive virtual classrooms with live sessions and collaborative tools.",
        icon: <DesktopOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Content Management Systems",
        description:
          "Managing and delivering educational content efficiently with content management systems.",
        icon: <FileTextOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Assessment and Evaluation Tools",
        description:
          "Developing tools for creating, administering, and analyzing assessments.",
        icon: <AuditOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Mobile Learning Apps",
        description:
          "Designing mobile apps for anytime, anywhere learning on smartphones and tablets.",
        icon: <MobileOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Data Analytics for Education",
        description:
          "Utilizing data analytics to track student progress and optimize educational outcomes.",
        icon: <BarChartOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Collaboration and Communication",
        description:
          "Facilitating collaboration and communication among students, teachers, and parents.",
        icon: <TeamOutlined style={{ width: "32px", height: "32px" }} />,
      },
    ],
  },
  mar_tech: {
    heroSection: {
      backgroundImage: mar_tech,
      backgroundPosition: "center bottom",
      title: "Revolutionize Your Marketing with Cutting-Edge Technology!",
      description:
        "Leverage our MarTech solutions to enhance your marketing strategy and drive results.",
      buttons: [
        {
          text: "Contact Us",
          icon: <MailOutlined />,
        },
      ],
    },
    servicesSection: {
      title: "Our Services",
      description:
        "We provide comprehensive MarTech solutions to optimize your marketing efforts.",
      services: [
        {
          title: "Marketing Automation",
          description:
            "Implementing automation tools to streamline marketing campaigns and workflows.",
          icon: <RobotOutlined />,
        },
        {
          title: "Customer Data Platforms",
          description:
            "Integrating and managing customer data for personalized marketing and insights.",
          icon: <DatabaseOutlined />,
        },
        {
          title: "Content Management Systems",
          description:
            "Developing efficient CMS for seamless content creation and distribution.",
          icon: <FileTextOutlined />,
        },
        {
          title: "Social Media Management",
          description:
            "Providing tools and strategies for effective social media engagement and analysis.",
          icon: <TwitterOutlined />,
        },
        {
          title: "Email Marketing Solutions",
          description:
            "Creating and managing targeted email campaigns to engage and convert leads.",
          icon: <MailOutlined />,
        },
        {
          title: "Analytics and Reporting",
          description:
            "Utilizing data analytics to measure marketing performance and ROI.",
          icon: <LineChartOutlined />,
        },
        {
          title: "SEO and SEM Services",
          description:
            "Optimizing your online presence with SEO and SEM strategies for better visibility.",
          icon: <SearchOutlined />,
        },
      ],
    },
    howWeWorkSection: {
      title: "Our Approach",
      description:
        "Discover how we leverage technology to enhance your marketing strategy.",
      steps: [
        {
          title: "Consultation and Planning",
          description:
            "Understanding your marketing goals and developing a tailored strategy.",
          icon: <SolutionOutlined />,
        },
        {
          title: "Implementation and Integration",
          description:
            "Integrating MarTech tools and platforms to streamline your marketing efforts.",
          icon: <CodeOutlined />,
        },
        {
          title: "Testing and Optimization",
          description:
            "Ensuring all tools and strategies are optimized for maximum performance.",
          icon: <BugOutlined />,
        },
        {
          title: "Deployment and Launch",
          description:
            "Managing the launch of marketing campaigns and tools for immediate impact.",
          icon: <RocketOutlined />,
        },
        {
          title: "Monitoring and Support",
          description:
            "Providing ongoing monitoring and support to ensure sustained success.",
          icon: <ToolOutlined />,
        },
      ],
    },
    expertise: [
      {
        title: "Marketing Automation",
        description:
          "Implementing automation tools to streamline marketing campaigns and workflows.",
        icon: <RobotOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Customer Data Platforms",
        description:
          "Integrating and managing customer data for personalized marketing and insights.",
        icon: <DatabaseOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Content Management Systems",
        description:
          "Developing efficient CMS for seamless content creation and distribution.",
        icon: <FileTextOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Social Media Management",
        description:
          "Providing tools and strategies for effective social media engagement and analysis.",
        icon: <TwitterOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Email Marketing Solutions",
        description:
          "Creating and managing targeted email campaigns to engage and convert leads.",
        icon: <MailOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Analytics and Reporting",
        description:
          "Utilizing data analytics to measure marketing performance and ROI.",
        icon: <LineChartOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "SEO and SEM Services",
        description:
          "Optimizing your online presence with SEO and SEM strategies for better visibility.",
        icon: <SearchOutlined style={{ width: "32px", height: "32px" }} />,
      },
    ],
  },
  real_estate: {
    heroSection: {
      backgroundImage: real_state,
      backgroundPosition: "center bottom",
      title: "Your Partner in Real Estate Success!",
      description:
        "Transform your real estate business with our comprehensive digital solutions.",
      buttons: [
        {
          text: "Contact Us",
          icon: <MailOutlined />,
        },
      ],
    },
    servicesSection: {
      title: "Our Services",
      description:
        "We provide innovative real estate solutions to enhance your business operations.",
      services: [
        {
          title: "Property Management Systems",
          description:
            "Developing efficient systems for managing property listings, tenants, and maintenance.",
          icon: <HomeOutlined />,
        },
        {
          title: "Virtual Tours and 3D Modeling",
          description:
            "Creating immersive virtual tours and 3D models to showcase properties online.",
          icon: <CameraOutlined />,
        },
        {
          title: "Real Estate CRM",
          description:
            "Implementing CRM systems to manage client relationships and streamline sales processes.",
          icon: <UserOutlined />,
        },
        {
          title: "Mobile Applications",
          description:
            "Designing mobile apps for on-the-go property search and management.",
          icon: <MobileOutlined />,
        },
        {
          title: "Analytics and Reporting",
          description:
            "Utilizing data analytics to provide insights into market trends and business performance.",
          icon: <LineChartOutlined />,
        },
        {
          title: "SEO and Digital Marketing",
          description:
            "Enhancing your online presence with SEO and targeted digital marketing strategies.",
          icon: <SearchOutlined />,
        },
        {
          title: "Custom Website Development",
          description:
            "Building responsive and user-friendly websites tailored to your real estate business.",
          icon: <GlobalOutlined />,
        },
      ],
    },
    howWeWorkSection: {
      title: "Our Process",
      description:
        "Discover how we collaborate with real estate professionals to deliver exceptional results.",
      steps: [
        {
          title: "Consultation and Planning",
          description:
            "Understanding your business needs and developing a strategic plan.",
          icon: <SolutionOutlined />,
        },
        {
          title: "Design and Development",
          description:
            "Creating tailored solutions that meet your specific real estate requirements.",
          icon: <CodeOutlined />,
        },
        {
          title: "Testing and Quality Assurance",
          description:
            "Conducting rigorous testing to ensure high performance and reliability.",
          icon: <BugOutlined />,
        },
        {
          title: "Deployment and Launch",
          description:
            "Managing smooth deployment and providing support during launch.",
          icon: <RocketOutlined />,
        },
        {
          title: "Maintenance and Support",
          description:
            "Offering ongoing maintenance and support to ensure continuous improvement.",
          icon: <ToolOutlined />,
        },
      ],
    },
    expertise: [
      {
        title: "Property Management Systems",
        description:
          "Developing efficient systems for managing property listings, tenants, and maintenance.",
        icon: <HomeOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Virtual Tours and 3D Modeling",
        description:
          "Creating immersive virtual tours and 3D models to showcase properties online.",
        icon: <CameraOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Real Estate CRM",
        description:
          "Implementing CRM systems to manage client relationships and streamline sales processes.",
        icon: <UserOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Mobile Applications",
        description:
          "Designing mobile apps for on-the-go property search and management.",
        icon: <MobileOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Analytics and Reporting",
        description:
          "Utilizing data analytics to provide insights into market trends and business performance.",
        icon: <LineChartOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "SEO and Digital Marketing",
        description:
          "Enhancing your online presence with SEO and targeted digital marketing strategies.",
        icon: <SearchOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Custom Website Development",
        description:
          "Building responsive and user-friendly websites tailored to your real estate business.",
        icon: <GlobalOutlined style={{ width: "32px", height: "32px" }} />,
      },
    ],
  },
  shipping_and_logistics: {
    heroSection: {
      backgroundImage: shipping_logitics,
      backgroundPosition: "center bottom",
      title: "Optimize Your Supply Chain with Advanced Logistics Solutions!",
      description:
        "Streamline your shipping and logistics operations with our comprehensive services.",
      buttons: [
        {
          text: "Contact Us",
          icon: <MailOutlined />,
        },
      ],
    },
    servicesSection: {
      title: "Our Services",
      description:
        "We provide a range of shipping and logistics services to enhance your supply chain efficiency.",
      services: [
        {
          title: "Warehousing and Storage",
          description:
            "Secure and efficient warehousing solutions to manage your inventory.",
          icon: <HomeOutlined />,
        },
        {
          title: "Transportation Management",
          description:
            "Optimizing transportation routes and methods to ensure timely delivery.",
          icon: <CarOutlined />,
        },
        {
          title: "Freight Forwarding",
          description:
            "Comprehensive freight forwarding services to handle international shipping.",
          icon: <GlobalOutlined />,
        },
        {
          title: "Supply Chain Consulting",
          description:
            "Expert consulting services to optimize your entire supply chain process.",
          icon: <SolutionOutlined />,
        },
        {
          title: "Customs Brokerage",
          description:
            "Navigating customs regulations to ensure smooth and compliant shipping.",
          icon: <FileDoneOutlined />,
        },
        {
          title: "Last Mile Delivery",
          description:
            "Efficient last mile delivery solutions to enhance customer satisfaction.",
          icon: <EnvironmentOutlined />,
        },
        {
          title: "Inventory Management",
          description:
            "Advanced inventory management systems for real-time tracking and control.",
          icon: <DatabaseOutlined />,
        },
      ],
    },
    howWeWorkSection: {
      title: "Our Process",
      description:
        "Discover how we streamline your shipping and logistics operations for optimal efficiency.",
      steps: [
        {
          title: "Assessment and Planning",
          description:
            "Understanding your logistics needs and developing a tailored strategy.",
          icon: <SolutionOutlined />,
        },
        {
          title: "Implementation and Integration",
          description:
            "Integrating advanced logistics solutions into your existing operations.",
          icon: <CodeOutlined />,
        },
        {
          title: "Optimization and Monitoring",
          description:
            "Continuously monitoring and optimizing logistics processes for efficiency.",
          icon: <LineChartOutlined />,
        },
        {
          title: "Execution and Delivery",
          description:
            "Executing logistics plans and ensuring timely delivery.",
          icon: <CarOutlined />,
        },
        {
          title: "Feedback and Improvement",
          description:
            "Gathering feedback and making improvements to enhance logistics performance.",
          icon: <ToolOutlined />,
        },
      ],
    },
    expertise: [
      {
        title: "Warehousing and Storage",
        description:
          "Secure and efficient warehousing solutions to manage your inventory.",
        icon: <HomeOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Transportation Management",
        description:
          "Optimizing transportation routes and methods to ensure timely delivery.",
        icon: <CarOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Freight Forwarding",
        description:
          "Comprehensive freight forwarding services to handle international shipping.",
        icon: <GlobalOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Supply Chain Consulting",
        description:
          "Expert consulting services to optimize your entire supply chain process.",
        icon: <SolutionOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Customs Brokerage",
        description:
          "Navigating customs regulations to ensure smooth and compliant shipping.",
        icon: <FileDoneOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Last Mile Delivery",
        description:
          "Efficient last mile delivery solutions to enhance customer satisfaction.",
        icon: <EnvironmentOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Inventory Management",
        description:
          "Advanced inventory management systems for real-time tracking and control.",
        icon: <DatabaseOutlined style={{ width: "32px", height: "32px" }} />,
      },
    ],
  },
  bfsi: {
    heroSection: {
      backgroundImage: Ecommerce,
      backgroundPosition: "center bottom",
      title: "Empowering Financial Services with Innovative Solutions!",
      description:
        "Enhance your BFSI operations with our cutting-edge technology and expertise.",
      buttons: [
        {
          text: "Contact Us",
          icon: <MailOutlined />,
        },
      ],
    },
    servicesSection: {
      title: "Our Services",
      description:
        "We offer a range of services to streamline your BFSI operations and improve customer satisfaction.",
      services: [
        {
          title: "Digital Banking",
          description:
            "Transform your banking services with our advanced digital banking solutions.",
          icon: <BankOutlined />,
        },
        {
          title: "Risk Management",
          description:
            "Comprehensive risk management services to safeguard your financial operations.",
          icon: <AlertOutlined />,
        },
        {
          title: "Insurance Solutions",
          description:
            "Innovative insurance solutions to enhance policy management and customer service.",
          icon: <SafetyOutlined />,
        },
        {
          title: "Investment Management",
          description:
            "Optimize your investment strategies with our expert management services.",
          icon: <BarChartOutlined />,
        },
        {
          title: "Compliance and Regulation",
          description:
            "Ensure compliance with financial regulations through our comprehensive services.",
          icon: <FileProtectOutlined />,
        },
        {
          title: "Customer Analytics",
          description:
            "Leverage customer analytics to improve engagement and service delivery.",
          icon: <LineChartOutlined />,
        },
        {
          title: "Blockchain Solutions",
          description:
            "Secure and transparent blockchain solutions for financial transactions.",
          icon: <BlockOutlined />,
        },
      ],
    },
    howWeWorkSection: {
      title: "Our Process",
      description:
        "Discover how we enhance your BFSI operations through our structured approach.",
      steps: [
        {
          title: "Consultation and Planning",
          description:
            "Understanding your financial needs and developing a customized strategy.",
          icon: <SolutionOutlined />,
        },
        {
          title: "Implementation and Integration",
          description:
            "Seamlessly integrating our solutions into your existing infrastructure.",
          icon: <CodeOutlined />,
        },
        {
          title: "Optimization and Monitoring",
          description:
            "Continuous monitoring and optimization of financial processes for efficiency.",
          icon: <LineChartOutlined />,
        },
        {
          title: "Execution and Delivery",
          description:
            "Executing financial strategies and ensuring effective delivery of services.",
          icon: <BankOutlined />,
        },
        {
          title: "Feedback and Improvement",
          description:
            "Gathering feedback and making improvements to enhance BFSI performance.",
          icon: <ToolOutlined />,
        },
      ],
    },
    expertise: [
      {
        title: "Digital Banking",
        description:
          "Transform your banking services with our advanced digital banking solutions.",
        icon: <BankOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Risk Management",
        description:
          "Comprehensive risk management services to safeguard your financial operations.",
        icon: <AlertOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Insurance Solutions",
        description:
          "Innovative insurance solutions to enhance policy management and customer service.",
        icon: <SafetyOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Investment Management",
        description:
          "Optimize your investment strategies with our expert management services.",
        icon: <BarChartOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Compliance and Regulation",
        description:
          "Ensure compliance with financial regulations through our comprehensive services.",
        icon: <FileProtectOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Customer Analytics",
        description:
          "Leverage customer analytics to improve engagement and service delivery.",
        icon: <LineChartOutlined style={{ width: "32px", height: "32px" }} />,
      },
      {
        title: "Blockchain Solutions",
        description:
          "Secure and transparent blockchain solutions for financial transactions.",
        icon: <BlockOutlined style={{ width: "32px", height: "32px" }} />,
      },
    ],
  },
};
