import { useLocation } from "react-router-dom";
import { Button, Dropdown, Menu, Drawer } from "antd";
import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo-onato.png";
import { Link } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  const [drawerVisible, setDrawerVisible] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 500);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const getActiveClass = (path) => {
    return location.pathname === path
      ? "_navItem_1x8qw_9 _defaultActive_1x8qw_38 active"
      : "_navItem_1x8qw_9";
  };

  const industries = [
    {
      key: "1",
      label: (
        <Link to="/industries?industry_type=bfsi" onClick={closeDrawer}>
          BFSI
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link
          to="/industries?industry_type=travel_and_loyality"
          onClick={closeDrawer}
        >
          Travel and Loyalty
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link to="/industries?industry_type=e_commerce" onClick={closeDrawer}>
          E-commerce
        </Link>
      ),
    },
    {
      key: "4",
      label: (
        <Link to="/industries?industry_type=edtech" onClick={closeDrawer}>
          EdTech
        </Link>
      ),
    },
    {
      key: "5",
      label: (
        <Link to="/industries?industry_type=mar_tech" onClick={closeDrawer}>
          MarTech
        </Link>
      ),
    },
    {
      key: "6",
      label: (
        <Link to="/industries?industry_type=real_estate" onClick={closeDrawer}>
          Real Estate
        </Link>
      ),
    },
    {
      key: "7",
      label: (
        <Link
          to="/industries?industry_type=shipping_and_logistics"
          onClick={closeDrawer}
        >
          Shipping and Logistics
        </Link>
      ),
    },
  ];

  const services = [
    {
      key: "1",
      label: (
        <Link to="/services?service_type=web_development" onClick={closeDrawer}>
          Web Development
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link
          to="/services?service_type=mobile_development"
          onClick={closeDrawer}
        >
          Mobile Development
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link to="/services?service_type=ux_design" onClick={closeDrawer}>
          UX-Design
        </Link>
      ),
    },
    {
      key: "4",
      label: (
        <Link to="/services?service_type=generative_ai" onClick={closeDrawer}>
          Generative AI
        </Link>
      ),
    },
    {
      key: "5",
      label: (
        <Link to="/services?service_type=devops_service" onClick={closeDrawer}>
          Devops services
        </Link>
      ),
    },
    {
      key: "6",
      label: (
        <Link to="/services?service_type=data_science" onClick={closeDrawer}>
          Data Science
        </Link>
      ),
    },
    {
      key: "7",
      label: (
        <Link
          to="/services?service_type=quality_engineering"
          onClick={closeDrawer}
        >
          Quality Engineering
        </Link>
      ),
    },
    {
      key: "8",
      label: (
        <Link to="/services?service_type=low_code" onClick={closeDrawer}>
          Low Code
        </Link>
      ),
    },
    {
      key: "9",
      label: (
        <Link to="/services?service_type=cyber_security" onClick={closeDrawer}>
          Cyber Security
        </Link>
      ),
    },
    {
      key: "10",
      label: (
        <Link
          to="/services?service_type=software_development"
          onClick={closeDrawer}
        >
          Software Development
        </Link>
      ),
    },
    {
      key: "11",
      label: (
        <Link to="/services?service_type=testing_qa" onClick={closeDrawer}>
          Testing & QA
        </Link>
      ),
    },
    {
      key: "12",
      label: (
        <Link
          to="/services?service_type=application_services"
          onClick={closeDrawer}
        >
          Application Services
        </Link>
      ),
    },
    {
      key: "13",
      label: (
        <Link
          to="/services?service_type=help_desk_services"
          onClick={closeDrawer}
        >
          helpDesk Services{" "}
        </Link>
      ),
    },
    {
      key: "14",
      label: (
        <Link
          to="/services?service_type=digital_marketing"
          onClick={closeDrawer}
        >
          Digital Marketing{" "}
        </Link>
      ),
    },
  ];

  const insights = [
    { key: "1", label: <Link to="/insights/blogs">Blogs</Link> },
    { key: "2", label: <Link to="/insights/whitepapers">WhitePapers</Link> },
  ];

  return (
    <>
      <header className="_headerWrapper_w7l6v_72 _transparentBackground_bgColor_w7l6v_48">
        <div className="content _container_w7l6v_83">
          <div className="_headerContainer_w7l6v_89">
            <div className="_leftSection_w7l6v_103">
              <div
                className="_logoContainer_w7l6v_103"
                style={{ position: "relative" }}
              >
                <Link to="/">
                  <img
                    src={logo}
                    alt="company logo"
                    style={{ width: "60px", height: "auto" }}
                  />
                </Link>
              </div>
            </div>

            <div className="_rightSection_w7l6v_128">
              <nav className="_navContainer_w7l6v_137">
                <div className="_activeMarker_w7l6v_148 _transparentBackground_activeMarker_w7l6v_55 _hideActiveMarker_w7l6v_155" />
                <Link className={getActiveClass("/about-us")} to="/about-us">
                  <span>About Us</span>
                </Link>
                <div className="_dropdown_1x8qw_86">
                  <Dropdown
                    overlay={<Menu items={services} />}
                    placement="bottomLeft"
                  >
                    <button
                      className="_dropbtn_1x8qw_52"
                      style={{ color: "#000", fontWeight: "500" }}
                    >
                      Our services
                    </button>
                  </Dropdown>
                </div>
                <div className="_dropdown_1x8qw_86">
                  <Dropdown
                    overlay={<Menu items={industries} />}
                    placement="bottomLeft"
                  >
                    <button
                      className="_dropbtn_1x8qw_52"
                      style={{ color: "#000", fontWeight: "500" }}
                    >
                      Industries
                    </button>
                  </Dropdown>
                </div>

                {/* <Link className={getActiveClass("/careers")} to="/careers">
                  <span>Careers</span>
                </Link> */}

                <Link
                  className={getActiveClass("/contact-us")}
                  to="/contact-us"
                >
                  <span>Contact Us</span>
                </Link>
              </nav>
              <button
                className="_hamberContainer_w7l6v_170"
                onClick={showDrawer}
              >
                <svg
                  width={48}
                  height={48}
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="_hambergerIcon_w7l6v_180 _transparentBackground_logoColor_w7l6v_52"
                >
                  <g id="Group 394">
                    <g id="menu">
                      <mask
                        id="mask0_2086_7459"
                        maskUnits="userSpaceOnUse"
                        x={12}
                        y={12}
                        width={24}
                        height={24}
                        style={{ maskType: "alpha" }}
                      >
                        <rect
                          id="Bounding box"
                          x={12}
                          y={12}
                          width={24}
                          height={24}
                          fill="#D9D9D9"
                        />
                      </mask>
                      <g mask="url(#mask0_2086_7459)">
                        <path
                          id="menu_2"
                          d="M16 29.6347C15.7875 29.6347 15.6094 29.5628 15.4656 29.419C15.3219 29.2751 15.25 29.0969 15.25 28.8844C15.25 28.6718 15.3219 28.4937 15.4656 28.3501C15.6094 28.2065 15.7875 28.1347 16 28.1347H32C32.2125 28.1347 32.3906 28.2066 32.5344 28.3504C32.6781 28.4942 32.75 28.6724 32.75 28.885C32.75 29.0976 32.6781 29.2757 32.5344 29.4193C32.3906 29.5629 32.2125 29.6347 32 29.6347H16ZM16 24.7501C15.7875 24.7501 15.6094 24.6782 15.4656 24.5344C15.3219 24.3905 15.25 24.2123 15.25 23.9998C15.25 23.7872 15.3219 23.6091 15.4656 23.4655C15.6094 23.3219 15.7875 23.2501 16 23.2501H32C32.2125 23.2501 32.3906 23.322 32.5344 23.4658C32.6781 23.6096 32.75 23.7878 32.75 24.0004C32.75 24.213 32.6781 24.3911 32.5344 24.5347C32.3906 24.6783 32.2125 24.7501 32 24.7501H16ZM16 19.8655
H16 19.8655C15.7875 19.8655 15.6094 19.7935 15.4656 19.6497C15.3219 19.5059 15.25 19.3277 15.25 19.1151C15.25 18.9025 15.3219 18.7245 15.4656 18.5809C15.6094 18.4373 15.7875 18.3655 16 18.3655H32C32.2125 18.3655 32.3906 18.4374 32.5344 18.5812C32.6781 18.725 32.75 18.9032 32.75 19.1158C32.75 19.3284 32.6781 19.5065 32.5344 19.6501C32.3906 19.7937 32.2125 19.8655 32 19.8655H16Z"
                          fill="white"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </header>
      <Drawer
        title="Menu"
        placement="right"
        onClose={closeDrawer}
        visible={drawerVisible}
        className="mobileDrawer"
      >
        <nav className="_navContainer_w7l6v_137">
          <Link
            style={{ color: "black" }}
            className={getActiveClass("/about-us")}
            to="/about-us"
            onClick={closeDrawer}
          >
            <span>About Us</span>
          </Link>
          <div className="_dropdown_1x8qw_86">
            <Dropdown
              style={{ color: "black" }}
              overlay={<Menu items={services} />}
              placement="bottomLeft"
            >
              <button style={{ color: "black" }} className="_dropbtn_1x8qw_52">
                Our services
              </button>
            </Dropdown>
          </div>
          <div className="_dropdown_1x8qw_86">
            <Dropdown
              style={{ color: "black" }}
              overlay={<Menu items={industries} />}
              placement="bottomLeft"
            >
              <button style={{ color: "black" }} className="_dropbtn_1x8qw_52">
                Industries
              </button>
            </Dropdown>
          </div>
          {/* <Link
            className={getActiveClass("/our-work")}
            to="/our-work"
            onClick={closeDrawer}
            style={{ color: "black" }}
          >
            <span>Our Work</span>
          </Link> */}
          {/* <Link
            style={{ color: "black" }}
            className={getActiveClass("/careers")}
            to="/careers"
            onClick={closeDrawer}
          >
            <span>Careers</span>
          </Link> */}

          <Link
            style={{ color: "black" }}
            className={getActiveClass("/contact-us")}
            to="/contact-us"
            onClick={closeDrawer}
          >
            <span>Contact Us</span>
          </Link>
        </nav>
      </Drawer>
    </>
  );
};

export default Header;
