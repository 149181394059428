import {
  AimOutlined,
  AntDesignOutlined,
  BankOutlined,
  BookOutlined,
  BranchesOutlined,
  CheckCircleOutlined,
  CloudOutlined,
  CloudSyncOutlined,
  CodeOutlined,
  ContainerOutlined,
  DatabaseOutlined,
  FireOutlined,
  FlagOutlined,
  GithubOutlined,
  HddOutlined,
  HomeOutlined,
  MailOutlined,
  MobileOutlined,
  NodeIndexOutlined,
  ProjectOutlined,
  PythonOutlined,
  RadarChartOutlined,
  RobotOutlined,
  RocketOutlined,
  SafetyOutlined,
  SearchOutlined,
  ShoppingOutlined,
  SketchOutlined,
} from "@ant-design/icons";
import acenture from "../img/accenture-logo.png";
import apple from "../img/apple-logo.png";
import aws from "../img/aws-0f8a2368.svg";
import google from "../img/google-logo.png";
import ibm from "../img/ibm-logo.png";
import oracle from "../img/oracle-logo.png";
import microsoft from "../img/microsoft-logo.png";
import Transition from "../img/techno/Transition to Thought Machine's Value Core.jpg";
import Boosting from "../img/techno/Boosting Sales Operations and Revenue.png";
import Bridging from "../img/techno/Bridging Technology Gaps in Orthopedic Manufacturing.jpeg";
import Managing from "../img/techno/Managing Secrets with Hashicorp Vault.jpeg";
import Optimizing from "../img/techno/Optimizing EHR Workflow for Business Growth.jpeg";
import Sales from "../img/techno/Sales Forecasting with Data Science.png";
import managed_it from "../img/managed-it-services~-~media--e324573b--query.webp";
import { industries } from "./industries";

export const homeConfig = {
  heroSection: {
    title: "Software Consulting and Development",
    description:
      "Achieve digital excellence through advanced technology. We are your partner in innovation, design, and modernization, delivering comprehensive solutions powered by the latest advancements.",
  },
  digitallyTransformed: {
    title: "Digital Transformation Achieved",
    description:
      "Revolutionized digital experiences through our intuitive solutions and innovative approach.",
    repeatText:
      "Digital TransformationDigital TransformationDigital TransformationDigital TransformationDigital Transformation",
    logos: [acenture, apple, aws, google, oracle, ibm, microsoft],
  },
  services: {
    title: "Our Expertise",
    description:
      "We craft exceptional software with a focus on clean code, agile methodologies, and outstanding user experience.",
    serviceCards: [
      {
        header: "Web Development",
        description:
          "Overcome business challenges and excel in the digital world with our custom web development services.",
        linkText: "Explore more",
        icon: <CodeOutlined style={{ height: "32px" }} />,
        link: "/services?service_type=web_development",
      },
      {
        header: "Mobile Development",
        description:
          "Bring your vision to life with our native Android and iOS app development services.",
        linkText: "Explore more",
        icon: <MobileOutlined style={{ height: "32px" }} />,
        link: "/services?service_type=mobile_development",
      },
      {
        header: "Generative AI",
        description:
          "Unlock endless possibilities with our Generative AI-powered solutions.",
        linkText: "Explore more",

        icon: <RobotOutlined style={{ height: "32px" }} />,
        link: "/services?service_type=generative_ai",
      },
      {
        header: "UX Design",
        description:
          "Enhance user engagement and create delightful experiences with our design solutions based on Design Thinking and HCI principles.",
        linkText: "Explore more",
        icon: <SketchOutlined style={{ height: "32px" }} />,
        link: "/services?service_type=ux_design",
      },
      {
        header: "DevOps Services",
        description:
          "Maintain a competitive edge with our agile DevOps services tailored to your business needs.",
        linkText: "Explore more",
        icon: <CloudSyncOutlined style={{ height: "32px" }} />,
        link: "/services?service_type=devops_service",
      },
      {
        header: "Quality Engineering",
        description:
          "Elevate your website with our comprehensive testing services.",
        linkText: "Explore more",
        icon: <CheckCircleOutlined style={{ height: "32px" }} />,
        link: "/services?service_type=quality_engineering",
      },
      {
        header: "Data Science",
        description:
          "Gain a competitive advantage with data-driven insights and advanced analytics.",
        linkText: "Explore more",
        icon: <DatabaseOutlined style={{ height: "32px" }} />,
        link: "/services?service_type=data_science",
      },
      {
        header: "Cybersecurity",
        description:
          "We ensure agility, proactive defense, and digital trust in an evolving software landscape.",
        linkText: "Explore more",
        icon: <SafetyOutlined style={{ height: "32px" }} />,
        link: "/services?service_type=cyber_security",
      },

      {
        header: "Digital Marketing",
        description:
          "In today's digital-first world, effective online marketing is key to driving business growth. At Omaza, we provide comprehensive digital marketing services to help you connect with your target audience, boost brand visibility, and maximize your ROI.",
        linkText: "Explore more",
        icon: <AimOutlined style={{ height: "32px" }} />,
        link: "/services?service_type=digital_marketing",
      },
    ],
  },
  ourWork: {
    title: "Our Portfolio",
    description:
      "We are committed to delivering top-tier service and software solutions, ensuring your success is our priority.",
    workCards: [
      {
        banner: Transition,
        header: "Transition to Thought Machine's Value Core",
        description:
          "Our client's semi-digital legacy system needed a full transformation to a digital banking service.",
        tags: [
          "Data Science",
          "Cloud Migration",
          "Digital Transformation",
          "+2",
        ],
        linkText: "READ MORE...",
        link: "transitionToValueCore",
      },
      {
        banner: Sales,
        header: "Sales Forecasting with Data Science",
        description:
          "To enhance sales strategies and maximize cargo capacity, our client needed a product for intelligent customer outreach and efficient cargo loading.",
        tags: ["Data Science", "Logistics", "Shipping", "+5"],
        linkText: "READ MORE...",
        link: "salesForecasting",
      },
      {
        banner: Boosting,
        header: "Boosting Sales Operations and Revenue",
        description:
          "Our client develops tools to enhance sales growth, focusing on improving lead prospecting and follow-up to increase sales conversions.",
        tags: ["Data Science", "Mar-Tech", "Machine Learning", "+1"],
        linkText: "READ MORE...",
        link: "boostingSalesOperations",
      },
      {
        banner: Bridging,
        header: "Bridging Technology Gaps in Orthopedic Manufacturing",
        description:
          "Traditional methods of capturing foot data for orthopedic footwear presented many challenges for our client.",
        tags: ["Healthcare", "Health IT", "Healthcare Technology"],
        linkText: "READ MORE...",
        link: "bridgingTechnologyGaps",
      },
      {
        banner: Optimizing,
        header: "Optimizing EHR Workflow for Business Growth",
        description:
          "Our client's digital solution for heart condition detection required integration with multiple EHR systems for comprehensive patient record management.",
        tags: ["Healthcare", "Health IT", "Healthcare Technology"],
        linkText: "READ MORE...",
        link: "optimizingEHRWorkflow",
      },
      {
        banner: Managing,
        header: "Managing Secrets with Hashicorp Vault",
        description:
          "Recognizing the importance of securing sensitive data, our client sought a robust tool for managing secrets at scale.",
        tags: ["DevOps", "Cloud Security", "BFSI", "+6"],
        linkText: "READ MORE...",
        link: "managingSecretsWithVault",
      },
    ],
  },
  caseStudies: {
    title: "Case Studies",
    description: "Explore our extensive collection of case studies.",
    count: "40+",
    linkText: "arrow",
  },
  technologies: {
    title: "Technologies",
    description:
      "We leverage over 20 diverse technologies, integrating the latest and most renowned tech stacks and platforms.",
    icons: [
      {
        name: "React",
        icon: <AntDesignOutlined style={{ height: "32px" }} />,
      },
      {
        name: "Node.js",
        icon: <NodeIndexOutlined style={{ height: "32px" }} />,
      },
      {
        name: "Python",
        icon: <PythonOutlined style={{ height: "32px" }} />,
      },
      {
        name: "AWS",
        icon: <CloudOutlined style={{ height: "32px" }} />,
      },
      {
        name: "Docker",
        icon: <ContainerOutlined style={{ height: "32px" }} />,
      },

      {
        name: "Java",
        icon: <CodeOutlined style={{ height: "32px" }} />,
      },

      {
        name: "MongoDB",
        icon: <DatabaseOutlined style={{ height: "32px" }} />,
      },

      {
        name: "Firebase",
        icon: <FireOutlined style={{ height: "32px" }} />,
      },
      {
        name: "Git",
        icon: <BranchesOutlined style={{ height: "32px" }} />,
      },
      {
        name: "GitHub",
        icon: <GithubOutlined style={{ height: "32px" }} />,
      },

      {
        name: "Jira",
        icon: <ProjectOutlined style={{ height: "32px" }} />,
      },

      {
        name: "Apache",
        icon: <HddOutlined style={{ height: "32px" }} />,
      },

      {
        name: "Flask",
        icon: <FireOutlined style={{ height: "32px" }} />,
      },
      {
        name: "Django",
        icon: <RocketOutlined style={{ height: "32px" }} />,
      },

      {
        name: "Elasticsearch",
        icon: <SearchOutlined style={{ height: "32px" }} />,
      },
      // {
      //   name: "Kafka",
      //   icon: <RadarChartOutlined style={{ height: "32px" }} />,
      // },
    ],
  },
  careers: {
    title: "Join Our Team!",
    description:
      "We provide a dynamic and inclusive environment where you can excel in solving impactful real-world challenges. Explore our current job openings and become a part of our innovative team.",
    apply: [
      {
        role: "Talent Scouts",
        description:
          "Do you have an eye for discovering exceptional talent? We need your expertise.",
        linkText: "Apply Now",
      },
      {
        role: "Developers",
        description:
          "Seize the opportunity to create amazing products with passionate tech enthusiasts.",
        linkText: "Apply Now",
      },
      {
        role: "Creative Designers",
        description:
          "Your ability to understand user needs and design outstanding solutions is what we seek.",
        linkText: "Apply Now",
      },
    ],
  },

  offices: {
    title: "Our Offices",
    locations: [
      {
        country: "India",
        address: "C3/13, Vipul Khand, Gomti Nagar,Lucknow 226010",
        icon: <FlagOutlined style={{ height: "32px" }} />,
      },
    ],
  },
  servicesData: [
    {
      title: "Software Development",
      content: {
        name: "Software Development",
        description:
          "We develop reliable, scalable, and secure software solutions for various platforms and devices, combining industry expertise with the latest IT advancements to deliver custom solutions that meet users' needs.",
        bullets: [
          { label: "Software consulting", link: "#" },
          { label: "Custom Software Development", link: "#" },
          { label: "Enterprise Software Development", link: "#" },
          { label: "SaaS Development", link: "#" },
          { label: "Software development outsourcing", link: "#" },
          { label: "Software product development", link: "#" },
          { label: "Team augmentation", link: "#" },
          { label: "Cloud application development", link: "#" },
          { label: "Legacy software modernization", link: "#" },
        ],
        exploreLink: "#",
      },
    },
    {
      title: "Testing & QA",
      content: {
        name: "Testing & QA",
        description:
          "We provide comprehensive QA and testing services, enhancing your QA processes and performing end-to-end testing for mobile, web, and desktop applications at every development stage.",
        bullets: [
          { label: "QA outsourcing", link: "#" },
          { label: "QA consulting", link: "#" },
          { label: "Security testing", link: "#" },
          { label: "Functional testing", link: "#" },
          { label: "Usability testing", link: "#" },
          { label: "Performance testing", link: "#" },
          { label: "Test automation", link: "#" },
          { label: "Penetration testing", link: "#" },
        ],
        exploreLink: "#",
      },
    },
    {
      title: "Application Services",
      content: {
        name: "Application Services",
        description:
          "We help businesses build, test, protect, manage, migrate, and optimize digital solutions to ensure they are always operational and cost-effective.",
        bullets: [
          { label: "Application Management", link: "#" },
          { label: "Application Modernization", link: "#" },
          { label: "Application Integration", link: "#" },
          { label: "Application development", link: "#" },
          { label: "Application testing", link: "#" },
          { label: "Application maintenance and support", link: "#" },
        ],
        exploreLink: "#",
      },
    },
    {
      title: "Cybersecurity Services",
      content: {
        name: "Cybersecurity Services",
        description:
          "With extensive experience and adherence to industry-standard security practices, we provide robust protection for your applications and networks.",
        bullets: [
          { label: "Security assessment", link: "#" },
          { label: "Managed security services", link: "#" },
          { label: "Security testing", link: "#/testing" },
          { label: "Information security consulting", link: "#/consulting" },
          { label: "Security code review", link: "#" },
          { label: "Compliance assessment", link: "#" },
          { label: "Penetration testing", link: "#/penetration-testing" },
        ],
        exploreLink: "#",
      },
    },
    {
      title: "UX/UI Design",
      content: {
        name: "UX/UI Design",
        description:
          "We design user experiences and interfaces for websites, SaaS, and web/mobile apps, combining the latest trends with your specific needs to create impactful designs.",
        bullets: [
          { label: "User Interface (UI) Design", link: "#" },
          {
            label: "Responsive Web App Design",
            link: "/services/managed/security",
          },
          {
            label: "Software-as-a-Service (SaaS) UI Design",
            link: "#/testing",
          },
          { label: "Ecommerce Website Design", link: "#/consulting" },
          {
            label: "User Experience (UX) Design",
            link: "/software-development/code-review",
          },
          { label: "Website Redesign", link: "/security/compliance" },
          { label: "Responsive Web Design", link: "#/penetration-testing" },
        ],
        exploreLink: "#",
      },
    },
    {
      title: "IT Consulting",
      content: {
        name: "IT Consulting",
        description:
          "Our experts provide guidance on developing and implementing effective IT strategies, digital transformation, system integration, and enhancing digital customer experiences.",
        bullets: [
          { label: "Digital Transformation Consulting", link: "#" },
          { label: "Project Management Consulting", link: "#" },
          { label: "Digital Crisis Management Consulting", link: "#" },
          { label: "IT Service Management Consulting", link: "#" },
          { label: "Solution consulting", link: "#" },
          { label: "Platform consulting", link: "#" },
          { label: "Enterprise IT consulting", link: "#" },
        ],
        exploreLink: "#",
      },
    },
    {
      title: "Data Analytics",
      content: {
        name: "Data Analytics",
        description:
          "We help businesses make data-driven decisions by converting their data into actionable insights and strengthening their analytics capabilities.",
        bullets: [
          { label: "Business Intelligence", link: "#" },
          { label: "Big Data", link: "#" },
          { label: "Data Warehousing", link: "#" },
          { label: "Data Science", link: "#" },
          { label: "Data Management", link: "#" },
          { label: "Machine and Deep Learning", link: "#" },
          { label: "Data Analytics as a Service", link: "#" },
        ],
        exploreLink: "#",
      },
    },
    {
      title: "Help Desk Services",
      content: {
        name: "Help Desk Services",
        description:
          "We provide help desk services for your IT environment or software products, solving issues from application functionality to code-level fixes for better software adoption and user satisfaction.",
        bullets: [
          { label: "Help desk outsourcing", link: "#" },
          { label: "IT help desk services", link: "#" },
          { label: "Outsourced help desk for MSP", link: "#" },
          { label: "NOC outsourcing", link: "#" },
        ],
        exploreLink: "#",
      },
    },
    {
      title: "Infrastructure Services",
      content: {
        name: "Infrastructure Services",
        description:
          "With years of experience and adherence to industry-standard certifications, we offer comprehensive infrastructure services that ensure your data security.",
        bullets: [
          { label: "Managed IT support", link: "#" },
          { label: "Data center support and management", link: "#" },
          { label: "Cloud consulting and management", link: "#" },
          { label: "DevOps consulting and implementation", link: "#" },
          { label: "Networking management services", link: "#" },
        ],
        exploreLink: "#",
      },
    },
  ],
  solutionTypes: [
    {
      title: "Enterprise applications",
      url: "/services/application",
      event: "Editable: Solution Types Editable,click,Enterprise applications",
    },
    {
      title: "ERP",
      url: "/services/software-development/erp",
      event: "Editable: Solution Types Editable,click,ERP",
    },
    {
      title: "Financial management",
      url: "/financial-management",
      event: "Editable: Solution Types Editable,click,Financial management",
    },
    {
      title: "Supply chain management",
      url: "/scm",
      event: "Editable: Solution Types Editable,click,Supply chain management",
    },
    {
      title: "Asset management",
      url: "/asset-management",
      event: "Editable: Solution Types Editable,click,Asset management",
    },
    {
      title: "Fleet management",
      url: "/fleet-management",
      event: "Editable: Solution Types Editable,click,Fleet management",
    },
    {
      title: "HR software",
      url: "/services/software-development/hr",
      event: "Editable: Solution Types Editable,click,HR software",
    },
    {
      title: "eLearning software",
      url: "/elearning",
      event: "Editable: Solution Types Editable,click,eLearning software",
    },
    {
      title: "eCommerce",
      url: "/ecommerce",
      event: "Editable: Solution Types Editable,click,eCommerce",
    },
    {
      title: "Mobile apps",
      url: "/services/mobile-app-development",
      event: "Editable: Solution Types Editable,click,Mobile apps",
    },
    {
      title: "Collaboration and productivity solutions",
      url: "/sharepoint-office-365",
      event:
        "Editable: Solution Types Editable,click,Collaboration and productivity solutions",
    },
    {
      title: "Content management",
      url: "/services/web-development/cms",
      event: "Editable: Solution Types Editable,click,Content management",
    },
    {
      title: "CRM",
      url: "/services/crm",
      event: "Editable: Solution Types Editable,click,CRM",
    },
    {
      title: "Data analytics",
      url: "/data/analytics/consulting",
      event: "Editable: Solution Types Editable,click,Data analytics",
    },
    {
      title: "Web portals",
      url: "/web-portal-development",
      event: "Editable: Solution Types Editable,click,Web portals",
    },
    {
      title: "ITSM",
      url: "/services/itsm",
      event: "Editable: Solution Types Editable,click,ITSM",
    },
  ],
  stats: [
    {
      amount: "35",
      label: "years of expertise",
    },
    {
      amount: "750+",
      label: "IT professionals",
    },
    {
      amount: "4K",
      label: "success stories",
    },
    {
      amount: "30+",
      label: "industries covered",
    },
  ],
  CareOf: {
    title: "Managed IT Services",
    imageUrl: managed_it,
    description:
      "Comprehensive care of your cloud or on-premises infrastructure and applications:",
    points: [
      "Consulting",
      "Implementation",
      "Management and support",
      "Security",
      "Help desk services",
      "Migration",
    ],
    exploreLink: "/services/managed-it",
  },
  techTrends: {
    title: "Enhance Your Business with Emerging Tech Trends",
    description:
      "Our team can help you transform your business by leveraging the latest technology trends to stay competitive.",
    tabs: [
      {
        title: "Big Data",
        description:
          "Utilize vast amounts of data to streamline tasks, enhance safety, and create engaging experiences.",
        services: [
          "Consulting and implementation",
          "Data governance",
          "Support and evolution",
          "Data security",
        ],
      },
      {
        title: "Artificial Intelligence",
        description:
          "Enhance decision-making, planning, and business processes with AI and ML technologies.",
        services: [
          "Machine learning (including deep learning)",
          "Natural language processing",
          "Robotics",
          "Image recognition",
        ],
      },
      {
        title: "Computer Vision",
        description: "Extract actionable insights from images and videos.",
        services: [
          "Detection of visual defects and anomalies",
          "Recognition of item location and positioning",
          "Barcode verification and validation",
          "3D modelling and mapping",
        ],
      },
      {
        title: "Internet of Things",
        description:
          "Gather, store, and analyze sensor data to build intelligent operations.",
        services: [
          "IoT consulting",
          "IoT solution deployment",
          "IoT analytics",
        ],
      },
      {
        title: "Blockchain",
        description:
          "Implement solutions for enhanced traceability, security, and faster data processing.",
        services: [
          "Blockchain-based product development",
          "Blockchain consulting and implementation",
        ],
      },
      {
        title: "Mixed Reality",
        description:
          "Combine digital and real-world environments to improve tasks and activities.",
        services: [
          "Virtual Reality development",
          "Augmented Reality development",
        ],
      },
    ],
  },
  industries: {
    title: "Industries",
    description:
      "We craft exceptional software with a focus on clean code, agile methodologies, and outstanding user experience.",
    serviceCards: [
      {
        header: "Travel and loyality",
        description:
          "Explore Your World with Our Travel and Loyalty Solutions!.",
        linkText: "Explore more",

        icon: <MailOutlined style={{ height: "32px" }} />,
        link: "/industries?industry_type=travel_and_loyality",
      },
      {
        header: "E-Commerce",
        description:
          "Drive sales and customer satisfaction with our comprehensive e-commerce solutions.",
        linkText: "Explore more",
        icon: <ShoppingOutlined style={{ height: "32px" }} />,
        link: "/industries?industry_type=e_commerce",
      },
      {
        header: "Ed-tech",
        description:
          "Empower learners and educators with our cutting-edge educational technology solutions.",
        linkText: "Explore more",
        icon: <BookOutlined style={{ height: "32px" }} />,
        link: "/industries?industry_type=edtech",
      },
      {
        header: "Mar Tech",
        description:
          "Revolutionize Your Marketing with Cutting-Edge Technology!",
        linkText: "Explore more",
        icon: <RobotOutlined style={{ height: "32px" }} />,
        link: "/industries?industry_type=mar_tech",
      },
      {
        header: "Real State",
        description:
          "Transform your real estate business with our comprehensive digital solutions.",
        linkText: "Explore more",
        icon: <HomeOutlined style={{ height: "32px" }} />,
        link: "/industries?industry_type=real_estate",
      },
      {
        header: "Shipping and Logistics",
        description:
          "Streamline your shipping and logistics operations with our comprehensive services.",
        linkText: "Explore more",
        icon: <DatabaseOutlined style={{ height: "32px" }} />,
        link: "/industries?industry_type=shipping_and_logistics",
      },
      {
        header: "BFSI",
        description:
          "Enhance your BFSI operations with our cutting-edge technology and expertise.",
        linkText: "Explore more",
        icon: <BankOutlined style={{ height: "32px" }} />,
        link: "/industries?industry_type=bfsi",
      },
    ],
  },
  faqs: [
    {
      question: "Does Omaza provide comprehensive software solutions?",
      answer:
        "Absolutely: this is our core service. Omaza has developed robust software solutions that cater to various business needs. Our technology ensures efficient management of processes, transactions, data, and more, facilitating seamless operations for all stakeholders involved.",
    },
    {
      question: "Can Omaza speed up business processes?",
      answer:
        "Yes, our software solutions are designed to optimize and expedite business processes. By automating routine tasks and enhancing data accuracy, our platform helps ensure timely completion of projects and efficient workflow management.",
    },
    {
      question: "What's the cost for your software solutions?",
      answer:
        "Costs vary based on factors such as the type of solution, the scope of implementation, and specific service requirements. Contact us for a detailed quote tailored to your business needs.",
    },
    {
      question: "What's your post-implementation support policy?",
      answer:
        "After implementation, our team ensures that all queries are addressed promptly and any issues are resolved. We provide continuous support to maintain smooth operations and foster long-term partnerships with our clients.",
    },
    {
      question: "What is your preferred development methodology?",
      answer:
        "We use a combination of agile methodologies to ensure flexibility and efficiency. For example, we might use Scrum for iterative development and Kanban for managing ongoing support and maintenance tasks.",
    },
    {
      question: "How do you ensure the quality of your software solutions?",
      answer:
        "We maintain high standards by following best practices in software development, conducting thorough testing, and continuously monitoring performance. Our team is committed to delivering reliable and high-quality solutions that meet our clients' needs.",
    },
  ],
};
