import { useRoutes } from "react-router-dom";
import Home from "./component/home";
import AboutUs from "./component/about-us";
import ContactUs from "./component/contact-us";
import PrivacyPolicy from "./component/privacy-policy";
import Services from "./component/services";
//import Insights from "./component/insights";
import TermsofUse from "./component/termsofuse/TermsofUse";
import Industries from "./component/industries";
import Careers from "./component/careers";
import NotFound from "./component/404Page";
import Portfolio from "./component/portfolio";


export const PublicRoutes = () => {
  return useRoutes([
    { path: "/", element: <Home /> },
    { path: "*", element: <NotFound /> },
    { path: "about-us", element: <AboutUs /> },
    { path: "contact-us", element: <ContactUs /> },
    // { path: "our-work", element: <OurWork /> },
    { path: "privacy-policy", element: <PrivacyPolicy /> },
    { path: "terms-of-use", element: <TermsofUse /> },
    { path: "services", element: <Services /> },
    // { path: "blogs", element: <Blog /> },
    // { path: "insights/whitepapers", element: <Whitepapers /> },
    { path: "industries", element: <Industries /> },
    { path: "careers", element: <Careers /> },
    { path: "portfolio", element: <Portfolio /> },
  ]);
};
