import {
  HistoryOutlined,
  ExpandOutlined,
  ClusterOutlined,
  SyncOutlined,
  ApiOutlined,
  ToolOutlined,
  ThunderboltOutlined,
  LineChartOutlined,
  DollarOutlined,
  SmileOutlined,
  SafetyOutlined,
  ClockCircleOutlined,
  CheckSquareOutlined,
  CloudOutlined,
  DashboardOutlined,
  SafetyCertificateOutlined,
  SlidersOutlined,
  DollarCircleOutlined,
  HourglassOutlined,
  LockOutlined,
  GlobalOutlined,
  TeamOutlined,
  HeartOutlined,
  UnlockOutlined,
  KeyOutlined,
  ReloadOutlined,
  SecurityScanOutlined,
  GatewayOutlined,
} from "@ant-design/icons";
import {
  UserOutlined,
  TableOutlined,
  AuditOutlined,
  DatabaseOutlined,
  ContainerOutlined,
  RobotOutlined,
  CheckCircleOutlined,
  FundOutlined,
} from "@ant-design/icons";
import Transition from "../img/techno/Transition to Thought Machine's Value Core.jpg";
import Boosting from "../img/techno/Boosting Sales Operations and Revenue.png";
import Bridging from "../img/techno/Bridging Technology Gaps in Orthopedic Manufacturing.jpeg";
import Managing from "../img/techno/Managing Secrets with Hashicorp Vault.jpeg";
import Optimizing from "../img/techno/Optimizing EHR Workflow for Business Growth.jpeg";
import Sales from "../img/techno/Sales Forecasting with Data Science.png";

export const ourWork = {
  transitionToValueCore: {
    heroSection: {
      backgroundImage: Transition,
      backgroundPosition: "center bottom",
      title: "Transition to Thought Machine's Value Core",
      description:
        "Leverage the power of Thought Machine's Value Core to modernize your banking infrastructure and drive innovation.",
      industry: "Financial Services",
      divider: true,
    },
    businessChallengeSection: {
      title: "Business Challenge",
      description:
        "To modernize their banking infrastructure and drive innovation, the client needed a robust and flexible core banking system that could adapt to changing market demands.",
      challenges: [
        {
          title: "Legacy Systems",
          description:
            "Outdated legacy systems limit the ability to innovate and respond quickly to market changes.",
          icon: <HistoryOutlined />,
        },
        {
          title: "Scalability Issues",
          description:
            "Existing systems struggle to scale efficiently to meet growing customer demands.",
          icon: <ExpandOutlined />,
        },
        {
          title: "Complex Integrations",
          description:
            "Integrating multiple systems and data sources leads to inefficiencies and increased maintenance costs.",
          icon: <ClusterOutlined />,
        },
      ],
      divider: true,
    },
    solutionSection: {
      title: "Our Solution",
      description:
        "Implementing Thought Machine's Value Core to transform the core banking system with a focus on flexibility, scalability, and innovation.",
      solutions: [
        {
          title: "Core System Migration",
          description:
            "Migrated the client's core banking operations to Thought Machine's Value Core, enabling a more agile and responsive banking environment.",
          icon: <SyncOutlined />,
        },
        {
          title: "API-Driven Architecture",
          description:
            "Implemented an API-driven architecture to facilitate seamless integration with external systems and services.",
          icon: <ApiOutlined />,
        },
        {
          title: "Customizable Product Engine",
          description:
            "Leveraged Value Core's customizable product engine to quickly develop and deploy new banking products tailored to customer needs.",
          icon: <ToolOutlined />,
        },
      ],
      technologies: ["Thought Machine's Value Core", "API", "Microservices"],
      divider: true,
    },
    outcomeSection: {
      title: "The Outcome",
      description:
        "Enhanced banking capabilities and customer satisfaction through a modernized core banking system.",
      outcomes: [
        {
          title: "Increased Agility",
          description:
            "The bank can now quickly adapt to market changes and introduce new products and services with ease.",
          icon: <ThunderboltOutlined />,
        },
        {
          title: "Improved Scalability",
          description:
            "The new system scales efficiently to accommodate growing customer demands and transaction volumes.",
          icon: <LineChartOutlined />,
        },
        {
          title: "Reduced Maintenance Costs",
          description:
            "Simplified system architecture and integrations have led to lower maintenance and operational costs.",
          icon: <DollarOutlined />,
        },
        {
          title: "Enhanced Customer Experience",
          description:
            "Customers benefit from faster, more reliable banking services and a broader range of products.",
          icon: <SmileOutlined />,
        },
      ],
    },
  },
  salesForecasting: {
    heroSection: {
      backgroundImage: Sales,
      backgroundPosition: "center bottom",
      title: "Sales Forecasting with Data Science",
      description:
        "Harness the power of data science to predict sales trends and optimize your sales strategy.",
      industry: "Logistics and Shipping",
      divider: true,
    },
    businessChallengeSection: {
      title: "Business Challenge",
      description:
        "To optimize sales strategy and maximize cargo vessel capacity, the client needed a product that enables intelligent customer outreach and efficient cargo loading.",
      challenges: [
        {
          title: "Reliance on Expertise",
          description:
            "Heavy reliance on individual expertise and past sales experiences limits adaptability to market changes and exploration of new customer engagement strategies.",
          icon: <UserOutlined />,
        },
        {
          title: "Inefficient Data Analysis",
          description:
            "Managing multiple Excel spreadsheets for sales data analysis impedes efficient insights extraction and resource management.",
          icon: <TableOutlined />,
        },
        {
          title: "Manual Forecasting",
          description:
            "Manual forecasting hampers responsiveness to market fluctuations, resource allocation, and strategic decision-making.",
          icon: <AuditOutlined />,
        },
      ],
      divider: true,
    },
    solutionSection: {
      title: "Our Solution",
      description:
        "Leveraging a Data Lake, Predictive Analytics, and Machine Learning for Optimized Cargo Planning and Sales Forecasting.",
      solutions: [
        {
          title: "Data Lake Creation",
          description:
            "We created a Data Lake for getting all the data dumped from the IMOS data since the data changes over time.",
          icon: <DatabaseOutlined />,
        },
        {
          title: "Robust API Development",
          description:
            "We built a robust API that could handle different queries based on the requirements of the sub-application.",
          icon: <ApiOutlined />,
        },
        {
          title: "Stage Prediction",
          description:
            "We outlined the various stages of a cargo journey and predicted the SOS (Start of Shipment) sales and Departure Sales for specific routes.",
          icon: <ContainerOutlined />,
        },
        {
          title: "Machine Learning Model",
          description:
            "We applied and trained a machine learning model using the combined data of various elements of the Departure Sales Forecast.",
          icon: <RobotOutlined />,
        },
      ],
      technologies: ["Python", "ML Algorithms", "Flask"],
      divider: true,
    },
    outcomeSection: {
      title: "The Outcome",
      description:
        "Optimized Cargo Planning and Improved Revenues through a Sales Forecasting Engine.",
      outcomes: [
        {
          title: "Optimized Cargo Planning",
          description:
            "Sales forecasting engine assists the sales team, resulting in ships getting filled to their maximum capacity on time, leading to better revenues and profit margins.",
          icon: <DollarOutlined />,
        },
        {
          title: "High Accuracy",
          description:
            "Achieved accuracy of approximately 80 to 85% depending on region or cluster.",
          icon: <CheckCircleOutlined />,
        },
        {
          title: "Better Results",
          description:
            "Compared to manual forecasting, the SOS sales forecasting model obtained better results with lesser deviations of ~15%.",
          icon: <LineChartOutlined />,
        },
        {
          title: "Enhanced Optimization",
          description:
            "The client can now analyze if further optimization can be done to save on vessels running optimized cargo and make port handling more efficient.",
          icon: <FundOutlined />,
        },
      ],
    },
  },
  boostingSalesOperations: {
    heroSection: {
      backgroundImage:Boosting,
      backgroundPosition: "center bottom",
      title: "Boosting Sales Operations and Revenue",
      description:
        "Leverage data science and innovative technologies to enhance your sales operations and increase revenue.",
      industry: "Logistics and Shipping",
      divider: true,
    },
    businessChallengeSection: {
      title: "Business Challenge",
      description:
        "To optimize sales strategy and maximize cargo vessel capacity, the client needed a solution that enhances customer outreach and improves cargo loading efficiency.",
      challenges: [
        {
          title: "Dependency on Expertise",
          description:
            "Reliance on individual expertise and historical sales data limits adaptability to market changes and new customer engagement strategies.",
          icon: <UserOutlined />,
        },
        {
          title: "Inefficient Data Management",
          description:
            "Multiple Excel spreadsheets for sales data analysis hinder efficient insights extraction and resource management.",
          icon: <TableOutlined />,
        },
        {
          title: "Manual Forecasting Limitations",
          description:
            "Manual sales forecasting limits responsiveness to market fluctuations, resource allocation, and strategic decision-making.",
          icon: <AuditOutlined />,
        },
      ],
      divider: true,
    },
    solutionSection: {
      title: "Our Solution",
      description:
        "Utilizing Data Lakes, Predictive Analytics, and Machine Learning to enhance sales operations and revenue.",
      solutions: [
        {
          title: "Data Lake Implementation",
          description:
            "Created a Data Lake to consolidate all sales data from IMOS, accommodating data changes over time.",
          icon: <DatabaseOutlined />,
        },
        {
          title: "API Development",
          description:
            "Developed a robust API to manage diverse queries based on sub-application requirements.",
          icon: <ApiOutlined />,
        },
        {
          title: "Cargo Journey Stages Prediction",
          description:
            "Outlined cargo journey stages and predicted SOS (Start of Shipment) and Departure Sales for specific routes.",
          icon: <ContainerOutlined />,
        },
        {
          title: "Machine Learning Application",
          description:
            "Trained a machine learning model using combined data elements of the Departure Sales Forecast.",
          icon: <RobotOutlined />,
        },
      ],
      technologies: ["Python", "ML Algorithms", "Flask"],
      divider: true,
    },
    outcomeSection: {
      title: "The Outcome",
      description:
        "Enhanced Sales Operations and Increased Revenue through Advanced Sales Forecasting.",
      outcomes: [
        {
          title: "Optimized Cargo Capacity",
          description:
            "Sales forecasting engine improved ship capacity utilization, resulting in higher revenues and profit margins.",
          icon: <DollarOutlined />,
        },
        {
          title: "High Forecast Accuracy",
          description:
            "Achieved forecast accuracy of 80-85% depending on the region or cluster.",
          icon: <CheckCircleOutlined />,
        },
        {
          title: "Improved Forecast Reliability",
          description:
            "Sales forecasting model demonstrated better results than manual forecasting with ~15% fewer deviations.",
          icon: <LineChartOutlined />,
        },
        {
          title: "Enhanced Optimization Capabilities",
          description:
            "Enabled further optimization of cargo operations and improved port handling efficiency.",
          icon: <FundOutlined />,
        },
      ],
    },
  },
  bridgingTechnologyGaps: {
    heroSection: {
      backgroundImage: Bridging,
      backgroundPosition: "center bottom",
      title: "Bridging Technology Gaps in Orthopedic Manufacturing",
      description:
        "Utilizing innovative technology solutions to enhance efficiency and quality in orthopedic manufacturing processes.",
      industry: "Healthcare - Orthopedic Manufacturing",
      divider: true,
    },
    businessChallengeSection: {
      title: "Business Challenge",
      description:
        "To address technology gaps hindering efficiency and quality in orthopedic manufacturing processes.",
      challenges: [
        {
          title: "Technological Obsolescence",
          description:
            "Outdated technology impacting production efficiency and product quality.",
          icon: <ToolOutlined />,
        },
        {
          title: "Quality Control Issues",
          description:
            "Challenges in maintaining consistent product quality standards throughout manufacturing processes.",
          icon: <SafetyOutlined />,
        },
        {
          title: "Operational Inefficiencies",
          description:
            "Lack of integration and automation leading to operational bottlenecks and delays.",
          icon: <ClockCircleOutlined />,
        },
      ],
      divider: true,
    },
    solutionSection: {
      title: "Our Solution",
      description:
        "Implementing advanced technology solutions to bridge gaps and improve efficiency and quality in orthopedic manufacturing.",
      solutions: [
        {
          title: "Technology Audit and Upgrade",
          description:
            "Conducted a comprehensive audit and upgraded outdated technology to enhance production capabilities.",
          icon: <AuditOutlined />,
        },
        {
          title: "Quality Management System",
          description:
            "Implemented a robust quality management system to ensure consistent product quality.",
          icon: <CheckSquareOutlined />,
        },
        {
          title: "Process Automation",
          description:
            "Automated key manufacturing processes to reduce operational inefficiencies and delays.",
          icon: <RobotOutlined />,
        },
        {
          title: "Integration of IoT",
          description:
            "Integrated IoT devices for real-time monitoring and data-driven decision-making.",
          icon: <CloudOutlined />,
        },
      ],
      technologies: [
        "IoT",
        "Automation Systems",
        "Quality Management Software",
      ],
      divider: true,
    },
    outcomeSection: {
      title: "The Outcome",
      description:
        "Improved Efficiency and Quality in Orthopedic Manufacturing Through Technological Advancements.",
      outcomes: [
        {
          title: "Enhanced Production Efficiency",
          description:
            "Technology upgrades and automation reduced production cycle times and improved throughput.",
          icon: <DashboardOutlined />,
        },
        {
          title: "Consistent Product Quality",
          description:
            "Implementing a robust quality management system ensured consistent adherence to quality standards.",
          icon: <SafetyCertificateOutlined />,
        },
        {
          title: "Operational Optimization",
          description:
            "Process automation and IoT integration optimized operational workflows and reduced bottlenecks.",
          icon: <SlidersOutlined />,
        },
        {
          title: "Cost Savings and Scalability",
          description:
            "Improved efficiency and quality led to cost savings and enhanced scalability in manufacturing operations.",
          icon: <DollarCircleOutlined />,
        },
      ],
    },
  },
  optimizingEHRWorkflow: {
    heroSection: {
      backgroundImage: Optimizing,
      backgroundPosition: "center bottom",
      title: "Optimizing EHR Workflow for Business Growth",
      description:
        "Enhancing Electronic Health Record (EHR) workflows to support business growth and operational efficiency in healthcare.",
      industry: "Healthcare - Electronic Health Records",
      divider: true,
    },
    businessChallengeSection: {
      title: "Business Challenge",
      description:
        "To streamline EHR workflows to support business growth and improve operational efficiency in healthcare facilities.",
      challenges: [
        {
          title: "Workflow Inefficiencies",
          description:
            "Complex and inefficient EHR workflows hindering operational efficiency and staff productivity.",
          icon: <HourglassOutlined />,
        },
        {
          title: "Integration Issues",
          description:
            "Challenges in integrating EHR systems with other healthcare applications and platforms.",
          icon: <ApiOutlined />,
        },
        {
          title: "Data Security Concerns",
          description:
            "Ensuring data security and compliance with healthcare regulations while optimizing EHR workflows.",
          icon: <LockOutlined />,
        },
      ],
      divider: true,
    },
    solutionSection: {
      title: "Our Solution",
      description:
        "Implementing streamlined EHR workflows and integrating advanced solutions to overcome challenges and support business growth.",
      solutions: [
        {
          title: "Workflow Analysis and Optimization",
          description:
            "Conducted a comprehensive analysis of existing workflows and optimized processes to improve efficiency.",
          icon: <AuditOutlined />,
        },
        {
          title: "Integration with Healthcare Systems",
          description:
            "Integrated EHR systems with other healthcare applications for seamless data exchange and workflow automation.",
          icon: <GlobalOutlined />,
        },
        {
          title: "Enhanced Data Security Measures",
          description:
            "Implemented advanced data security measures and compliance protocols to safeguard patient information.",
          icon: <SafetyOutlined />,
        },
        {
          title: "Training and Support",
          description:
            "Provided training and ongoing support to healthcare staff for effective utilization of optimized EHR workflows.",
          icon: <TeamOutlined />,
        },
      ],
      technologies: [
        "EHR Systems",
        "Healthcare Integration Platforms",
        "Data Security Solutions",
      ],
      divider: true,
    },
    outcomeSection: {
      title: "The Outcome",
      description:
        "Improved Operational Efficiency and Business Growth Through Optimized EHR Workflows.",
      outcomes: [
        {
          title: "Streamlined Workflows",
          description:
            "Optimized EHR workflows improved operational efficiency and staff productivity.",
          icon: <DashboardOutlined />,
        },
        {
          title: "Enhanced Integration",
          description:
            "Seamless integration with healthcare systems facilitated efficient data exchange and workflow automation.",
          icon: <ApiOutlined />,
        },
        {
          title: "Data Security Compliance",
          description:
            "Implemented robust data security measures ensuring compliance with healthcare regulations.",
          icon: <SafetyCertificateOutlined />,
        },
        {
          title: "Improved Patient Care",
          description:
            "Enhanced EHR workflows contributed to improved patient care delivery and satisfaction.",
          icon: <HeartOutlined />,
        },
      ],
    },
  },
  managingSecretsWithVault: {
    heroSection: {
      backgroundImage: Managing,
      backgroundPosition: "center bottom",
      title: "Managing Secrets with HashiCorp Vault",
      description:
        "Implementing secure secret management solutions using HashiCorp Vault to protect sensitive data and enhance security posture.",
      industry: "Information Technology - Security",
      divider: true,
    },
    businessChallengeSection: {
      title: "Business Challenge",
      description:
        "To securely manage and protect sensitive information such as API keys, passwords, and certificates across different environments and applications.",
      challenges: [
        {
          title: "Secrets Management Complexity",
          description:
            "Challenges in securely managing and rotating secrets across multiple applications and environments.",
          icon: <UnlockOutlined />,
        },
        {
          title: "Security Compliance",
          description:
            "Ensuring compliance with industry regulations and best practices for storing and managing sensitive data.",
          icon: <SafetyCertificateOutlined />,
        },
        {
          title: "Integration with Existing Systems",
          description:
            "Integrating HashiCorp Vault with existing infrastructure and applications while maintaining operational continuity.",
          icon: <ApiOutlined />,
        },
      ],
      divider: true,
    },
    solutionSection: {
      title: "Our Solution",
      description:
        "Implementing HashiCorp Vault for centralized secret management, encryption, and access control to address security challenges and enhance operational efficiency.",
      solutions: [
        {
          title: "Centralized Secret Management",
          description:
            "Deployed HashiCorp Vault to centrally manage and secure secrets, including API keys, passwords, and certificates.",
          icon: <KeyOutlined />,
        },
        {
          title: "Dynamic Secrets and Automated Rotation",
          description:
            "Implemented dynamic secrets and automated rotation policies to enhance security and reduce manual intervention.",
          icon: <ReloadOutlined />,
        },
        {
          title: "Access Control Policies",
          description:
            "Configured fine-grained access control policies to enforce least privilege access and ensure compliance.",
          icon: <SecurityScanOutlined />,
        },
        {
          title: "Audit and Monitoring",
          description:
            "Enabled audit logging and monitoring capabilities to track access and changes to secrets for enhanced visibility and compliance.",
          icon: <AuditOutlined />,
        },
      ],
      technologies: [
        "HashiCorp Vault",
        "Secrets Management",
        "Encryption",
        "Access Control",
      ],
      divider: true,
    },
    outcomeSection: {
      title: "The Outcome",
      description:
        "Improved Security Posture and Operational Efficiency Through HashiCorp Vault Implementation.",
      outcomes: [
        {
          title: "Enhanced Security",
          description:
            "Implemented robust security measures for sensitive data protection and compliance with industry regulations.",
          icon: <SafetyOutlined />,
        },
        {
          title: "Operational Efficiency",
          description:
            "Streamlined secrets management processes with automated rotation and centralized control, reducing operational overhead.",
          icon: <ToolOutlined />,
        },
        {
          title: "Compliance Adherence",
          description:
            "Achieved compliance with regulatory requirements and industry standards for data protection and security management.",
          icon: <SafetyCertificateOutlined />,
        },
        {
          title: "Scalability and Flexibility",
          description:
            "Designed a scalable and flexible secrets management solution that can grow with the organization's needs and evolving security challenges.",
          icon: <GatewayOutlined />,
        },
      ],
    },
  },
};
