import { BrowserRouter as Router } from "react-router-dom";
import { PublicRoutes } from "./routes";
import Header from "./component/header";
import Footer from "./component/footer";

import "./assets/css/about-us.css";
import "./assets/css/card.css";
import "./assets/css/container.css";
import "./assets/css/home.css";
import "./assets/css/section.card.css";
import "./assets/css/our-work.css";
import "./assets/css/servicess.css";
import "./assets/css/privacy-policy.css";
import "./assets/css/whitepapers.css";
import "./assets/css/blog.css";
import "./assets/css/footer.css";
import "./assets/css/contact-us.css";
import "./assets/css/termsofuse.css";
import "./assets/css/custom.css";
import "./App.css";

function App() {
  return (
    <>
      <div className="pageContainer">
        <div className="contentMainWrapper">
          {/* header */}

          <Router>
            <Header />
            <PublicRoutes />
            <Footer />
          </Router>
        </div>
        {/* footer */}
      </div>
      <div
        style={{
          position: "fixed",
          zIndex: 9999,
          inset: 16,
          pointerEvents: "none",
        }}
      />
    </>
  );
}

export default App;
