import React from "react";
import logo from "../../assets/img/logo-onato.png";
import { a } from "react-router-dom";

const Footer = () => {
  const industries = [
    {
      key: "1",
      label: <a href="/industries?industry_type=bfsi">BFSI</a>,
    },
    {
      key: "2",
      label: (
        <a href="/industries?industry_type=travel_and_loyality">
          Travel and Loyalty
        </a>
      ),
    },
    {
      key: "3",
      label: <a href="/industries?industry_type=e_commerce">E-commerce</a>,
    },
    {
      key: "4",
      label: <a href="/industries?industry_type=edtech">EdTech</a>,
    },
    {
      key: "5",
      label: <a href="/industries?industry_type=mar_tech">MarTech</a>,
    },
    {
      key: "6",
      label: <a href="/industries?industry_type=real_estate">Real Estate</a>,
    },
    {
      key: "7",
      label: (
        <a href="/industries?industry_type=shipping_and_logistics">
          Shipping and Logistics
        </a>
      ),
    },
  ];

  const services = [
    {
      key: "1",
      label: (
        <a href="/services?service_type=web_development">Web Development</a>
      ),
    },
    {
      key: "2",
      label: (
        <a href="/services?service_type=mobile_development">
          Mobile Development
        </a>
      ),
    },
    {
      key: "3",
      label: <a href="/services?service_type=ux_design">UX-Design</a>,
    },
    {
      key: "4",
      label: <a href="/services?service_type=generative_ai">Generative AI</a>,
    },
    {
      key: "5",
      label: (
        <a href="/services?service_type=devops_service">Devops services</a>
      ),
    },
    {
      key: "6",
      label: <a href="/services?service_type=data_science">Data Science</a>,
    },
    {
      key: "7",
      label: <a href="/services?service_type=cyber_security">Cyber Security</a>,
    },
  ];

  return (
    <>
      <section
        id="contact"
        className="main_footer section_space "
        style={{ background: "#f6f6f6" }}
      >
        <div className="container mt-5">
          <div className="row footer-col-spans">
            <div className="footer_top col-md-12">
              <div className="footer_toplist">
                <div className="footeraddress footer_companylegallist">
                  <h6>Get In Touch</h6>
                  <ul>
                    <li>
                      <a href="mailto:support@omaza.in" target="_blank">
                        <img
                          src={logo}
                          alt="company logo"
                          style={{ width: "50px", height: "auto" }}
                        />
                        <p>support@omaza.in</p>
                      </a>
                    </li>
                    <li>
                      <p>
                        Address : 3rd floor, Padmavathi Complex, 91 Spring
                        Board, 80 Feet Rd, Koramangala 8th Block, Koramangala,
                        Bengaluru, Karnataka 560095
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer_toplist">
                <div className="footer_companylegallist">
                  <h6>Company</h6>
                  <ul>
                    <li>
                      <a href="about-us">About Us</a>
                    </li>
                    <li>
                      <a href="privacy-policy" target="_blank">
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a href="terms-of-use" target="_blank">
                        Terms of use
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer_toplist">
                <div className="footer_companylegallist">
                  <h6>Services</h6>
                  <ul>
                    {services?.map((item) => (
                      <li key={item.key}>{item.label}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="footer_toplist">
                <div className="footer_companylegallist">
                  <h6>Industries</h6>
                  <ul>
                    {industries?.map((item) => (
                      <li key={item.key}>{item.label}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-lg-1 col-md-2 col-sm-2">
              <img
                src={logo}
                alt="company logo"
                style={{ width: "50px", height: "auto" }}
              />
            </div>
            <div className="col-lg-7 col-md-8 col-sm-8">
              <p>
                <div>Omaza Innovations LLP</div>
                <div>YULIN INNOVATIONS PTE. LTD.</div>
                <div>(Subsidiary of Omaza Innovations LLP)</div>
              </p>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <p className="d-inline-block ml-2">
                Copyright © <span>2024 | Omaza</span>
                <p> All rights reserved.</p>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
