import {
  AppstoreOutlined,
  CodeOutlined,
  EyeOutlined,
  MobileOutlined,
  SmileOutlined,
  SolutionOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

export const AboutUsConfig = {
  heroSection: {
    title: "About Us",
    description:
      "We are a digital engineering company dedicated to delivering top-notch user experiences, clean code, and unparalleled quality.",
  },
  whoWeAre: {
    title: "Who We Are",
    description:
      "We are a forward-thinking digital engineering firm committed to delivering unparalleled user experiences and top-tier solutions that empower businesses to implement innovative digital strategies.Our talented and dedicated team fosters a culture that prioritizes people, driven by engineering excellence, which has earned us recognition as a leading workplace in the industry.With a proven track record of delivering exceptional solutions to prominent global companies, we have consistently demonstrated our ability to meet and exceed client expectations. Our commitment to clean coding practices and creating seamless user experiences has resulted in impressive year-over-year growth, setting new benchmarks in the software services sector.Our approach is characterized by meticulous attention to detail, ensuring that every project is executed with the highest standards of quality. We pride ourselves on our ability to deliver efficient solutions within budget and on schedule, maintaining the utmost integrity in our relationships with both team members and clients. By continuously pushing the boundaries of what's possible, we strive to reshape industry standards and drive the future of digital innovation.",
    highlights: [
      {
        title: "Intuitive",
        description: "Seamless User Experiences",
        icon: <UserOutlined />,
      },
      {
        title: "Scalable",
        description: "Robust Web and Mobile Applications",
        icon: <MobileOutlined />,
      },
      {
        title: "Innovative",
        description: "Young Leadership",
        icon: <TeamOutlined />,
      },
      {
        title: "Focused",
        description: "Product Development",
        icon: <AppstoreOutlined />,
      },
    ],
  },
  ourMission: {
    title: "Our Mission",
    description:
      "We are dedicated to demonstrating the exceptional skills of our developers by delivering superior products with pristine code and outstanding user experiences. Our goal is to create memorable user interactions and set a high standard for programming excellence. Our engineering-centric culture ensures outstanding quality in every project, efficient delivery within budget and schedule, and the highest level of integrity in our relationships with both team members and clients.",
    cardHeaders: [
      {
        title: "People First",
        description:
          "We nurture the growth of our team members and celebrate their achievements.",
        icon: <SmileOutlined />,
      },
      {
        title: "Quality Coding",
        description:
          "We believe in the importance of using clean, understandable code in all our products.",
        icon: <CodeOutlined />,
      },
      {
        title: "Ownership",
        description: "We take individual responsibility for our deliverables.",
        icon: <SolutionOutlined />,
      },
      {
        title: "User Perspective",
        description:
          "We design and build products with a deep understanding of our users' needs.",
        icon: <EyeOutlined />,
      },
    ],
  },

  ourVision: {
    title: "Our Vision",
    description:
      "As advocates of clean code, we believe that the organization's commitment to high-quality code and the expertise of individual developers are crucial. We envision a future where clean code is the norm, resulting in superior software that benefits society. Our primary goal is to transform real-world problems into data and leverage computing power to solve them more effectively.",
  },
};
