import React, { useEffect, useState } from "react";
import { Avatar, Spin } from "antd";
import { CareerData } from "../../assets/data/Carrier";

const Careers = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServiceData = async () => {
      setLoading(true);
      // Simulate API call or async data fetching
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate delay
      setData(CareerData);
      setLoading(false);
    };

    fetchServiceData();
  }, [CareerData]);

  return (
    <>
      {loading ? (
        <div className="loader-container">
          <Spin size="large" className="custom-spin" />
        </div>
      ) : (
        <>
          <div className="_homePage_686e7_9" id="careerPage">
            <div
              className="_heroSection_10juh_9"
              style={{
                height:"77vh",
                backgroundImage: `url(${require("../../assets/img/cyberBionicVirtual-a46dc9a2.png")})`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              
              }}
            >
              <div className="_heroSectionGradient_686e7_9">
                <div className="content _heroSectionContentContainer_686e7_17">
                  <div
                    className="_leftContainer_10juh_316 "
                    style={{ marginTop: "200px" }}
                  >
                    <div className="_title_10juh_65 ">
                      {data?.heroSection?.title}
                    </div>
                    <p className="_description_10juh_109">
                      {data?.heroSection?.subtitle}
                    </p>
                    
                  </div>
                </div>
              </div>
            </div>
            <div custom-id="lightBgNav" className="_whoWeAreSection_50wyh_9">
              <div className="content _whyJoinonatoContainer_686e7_37 theme-page">
                <div className="_contentWrapper_50wyh_9">
                  <p className="_title_50wyh_29"> {data?.whyJoinUs?.title}</p>
                  <div className="_description_50wyh_66">
                    {data?.whyJoinUs?.description}
                  </div>
                </div>
                <div className="_whoWeAreCardsWrapper_50wyh_98">
                  <div className="_cardSection_50wyh_116">
                    {data?.whyJoinUs?.highlights?.map((item) => (
                      <div className="_whoWeAreCardsLayout_50wyh_123">
                        <Avatar icon={item?.icon} style={{fontSize:'3rem' ,width:'4rem',height:'4rem'}}/>
                        <div>
                          <p className="_cardTitle_50wyh_145">{item?.value}</p>
                          <p className="_cardDescription_50wyh_166">
                            {item?.label}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div
              custom-id="darkBgNav"
              className="_caseStudyContainer_15l3n_9 _darkCaseStudyContainer_15l3n_122 "
            >
              <div className="content">
                <div className="_title_15l3n_23 _themeBasedTitle_15l3n_115">
                  {data?.media?.title}
                </div>
                <div className="_description_15l3n_51 _themeBasedDescription_15l3n_118">
                  {data?.media?.description}
                </div>
                <div className="_cardContainer_15l3n_71">
                  {data?.media?.articles?.map((item) => (
                    <div className="_card_ogtky_9 _darkCard_ogtky_98">
                      <div
                        className="_cardImage_ogtky_14"
                        style={{
                          backgroundImage: `url(${item?.image})`,
                          height: "10rem",
                        }}
                      />
                      <div className="_cardDescription_ogtky_39 _themeBasedCardDescription_ogtky_90">
                        <div className="_lineClamped_ogtky_54">
                          {item?.title}{" "}
                        </div>
                      </div>
                      <a
                        href="#"
                        className="_cardLink_ogtky_67"
                        aria-label="Card routing to descriptive section"
                      >
                        {item?.linkText}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              custom-id="lightBgNav"
              className="_lifeAtonatoContainer_1i8wl_9"
            >
              <div className="content _lifeAtonatoSection_686e7_50">
                <div className="_lifeAtonatoLayout_1i8wl_9">
                  <div className="_contentSectionLayout_1i8wl_31">
                    <p className="_title_1i8wl_52">
                      {" "}
                      {data?.lifeAtonato?.title}
                    </p>
                    <div className="_descriptionLayout_1i8wl_79">
                      <p className="_description_1i8wl_79">
                        {data?.lifeAtonato?.description1}
                      </p>
                      <p className="_description_1i8wl_79">
                        {" "}
                        {data?.lifeAtonato?.description2}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div custom-id="darkBgNav" className="_wrapper_18zge_9">
              <div className="content _container_18zge_24">
                <div className="_heading_18zge_43">
                  {data?.testimonials?.title}
                </div>
                <div className="_testimonyContent_18zge_83 _active_18zge_298">
                  {data?.testimonials?.testimonials?.map((item) => (
                    <>
                      <div className="_description_18zge_201">
                        <div className="_testimony_18zge_83">{item?.text}</div>
                        <div className="_name_18zge_230">{item?.author}</div>
                        <div className="_designation_18zge_239">
                          {item?.position}
                        </div>
                      </div>
                      <div className="_emptyDiv_18zge_259" />
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Careers;
